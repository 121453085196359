<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="主叫姓名" prop="callerName" >
        <a-input v-model="form.callerName" placeholder="请输入主叫姓名" />
      </a-form-model-item>
      <a-form-model-item label="被叫姓名" prop="calledName" >
        <a-input v-model="form.calledName" placeholder="请输入被叫姓名" />
      </a-form-model-item>
      <a-form-model-item label="被叫号码" prop="calledNumber" >
        <a-input v-model="form.calledNumber" placeholder="请输入被叫号码" />
      </a-form-model-item>
      <a-form-model-item label="通话时长" prop="duration" >
        <a-input v-model="form.duration" placeholder="请输入通话时长" />
      </a-form-model-item>
      <a-form-model-item label="通话挂断时间" prop="hangingUpTime" >
        <a-date-picker style="width: 100%" v-model="form.hangingUpTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="录音oss地址" prop="ossUrl" >
        <a-input v-model="form.ossUrl" placeholder="请输入录音oss地址" />
      </a-form-model-item>
      <a-form-model-item label="录音路径地址" prop="routeUrl" >
        <a-input v-model="form.routeUrl" placeholder="请输入录音路径地址" />
      </a-form-model-item>
      <a-form-model-item label="录音创建时间" prop="recordingTime" >
        <a-date-picker style="width: 100%" v-model="form.recordingTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="原始录音对象" prop="originalObject" >
        <a-input v-model="form.originalObject" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="主叫所属部门id,外键{sys_dept.id}" prop="deptId" >
        <a-input v-model="form.deptId" placeholder="请输入主叫所属部门id,外键{sys_dept.id}" />
      </a-form-model-item>
      <a-form-model-item label="备用字段1" prop="remark1" >
        <a-input v-model="form.remark1" placeholder="请输入备用字段1" />
      </a-form-model-item>
      <a-form-model-item label="备用字段2" prop="remark2" >
        <a-input v-model="form.remark2" placeholder="请输入备用字段2" />
      </a-form-model-item>
      <a-form-model-item label="备用字段3" prop="remark3" >
        <a-input v-model="form.remark3" placeholder="请输入备用字段3" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRecording, addRecording, updateRecording } from '@/api/recording/recording'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        callerName: null,

        calledName: null,

        calledNumber: null,

        duration: null,

        hangingUpTime: null,

        ossUrl: null,

        routeUrl: null,

        recordingTime: null,

        originalObject: null,

        deptId: null,

        remark1: null,

        remark2: null,

        remark3: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        callerName: [
          { required: true, message: '主叫姓名不能为空', trigger: 'blur' }
        ],
        calledName: [
          { required: true, message: '被叫姓名不能为空', trigger: 'blur' }
        ],
        calledNumber: [
          { required: true, message: '被叫号码不能为空', trigger: 'blur' }
        ],
        duration: [
          { required: true, message: '通话时长不能为空', trigger: 'blur' }
        ],
        hangingUpTime: [
          { required: true, message: '通话挂断时间不能为空', trigger: 'blur' }
        ],
        ossUrl: [
          { required: true, message: '录音oss地址不能为空', trigger: 'blur' }
        ],
        routeUrl: [
          { required: true, message: '录音路径地址不能为空', trigger: 'blur' }
        ],
        recordingTime: [
          { required: true, message: '录音创建时间不能为空', trigger: 'blur' }
        ],
        originalObject: [
          { required: true, message: '原始录音对象不能为空', trigger: 'blur' }
        ],
        deptId: [
          { required: true, message: '主叫所属部门id,外键{sys_dept.id}不能为空', trigger: 'blur' }
        ],
        remark1: [
          { required: true, message: '备用字段1不能为空', trigger: 'blur' }
        ],
        remark2: [
          { required: true, message: '备用字段2不能为空', trigger: 'blur' }
        ],
        remark3: [
          { required: true, message: '备用字段3不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        callerName: null,
        calledName: null,
        calledNumber: null,
        duration: null,
        hangingUpTime: null,
        ossUrl: null,
        routeUrl: null,
        recordingTime: null,
        originalObject: null,
        deptId: null,
        remark1: null,
        remark2: null,
        remark3: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRecording({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRecording(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRecording(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
