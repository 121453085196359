<template>
	<a-drawer :title="formTitle" width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
		<a-form-model ref="form" :model="form" :rules="rules">
			<a-form-model-item label="客户全名" prop="name">
				<a-input v-model="form.name" placeholder="请输入客户名称" />
			</a-form-model-item>
			<a-form-model-item label="手机号" prop="phone">
				<a-input v-model="form.phone" placeholder="请输入手机号" />
			</a-form-model-item>
			<a-form-model-item label="工作单位" prop="work">
				<a-input v-model="form.work" placeholder="请输入工作单位" />
			</a-form-model-item>
			<a-form-model-item label="省份" prop="province">
				<a-input v-model="form.province" placeholder="请输入省份" />
			</a-form-model-item>
			<a-form-model-item label="市区" prop="area">
				<a-input v-model="form.area" placeholder="请输入市区" />
			</a-form-model-item>
			<a-form-model-item v-if="formType==2" label="创建人" prop="notes">
				<a-input v-model="form.userName" placeholder="创建人" />
			</a-form-model-item>
			<a-form-model-item v-if="formType==2" label="创建时间" prop="notes">
				<a-input v-model="form.createTime" placeholder="创建时间" />
			</a-form-model-item>
			<div class="bottom-control">
				<a-space>
					<a-button type="primary" :loading="submitLoading" @click="submitForm">
						保存
					</a-button>
					<a-button type="dashed" @click="cancel">
						取消
					</a-button>
				</a-space>
			</div>
		</a-form-model>
	</a-drawer>
</template>

<script>
	import {
		getRoster,
		addRoster,
		updateRoster,
		addIntroduction
	} from '@/api/roster/roster'

	export default {
		name: 'CreateName',
		props: {},
		components: {},
		data() {
			return {
				submitLoading: false,
				formTitle: '',
				// 表单参数
				form: {
					type: 8,
					name: null,
					phone: null,
					work: null,
					area: null,
					province: null,
					notes: null,
				},
				// 1增加,2修改
				formType: 1,
				open: false,
				rules: {
					name: [{
						required: true,
						message: '客户名称不能为空',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '手机号不能为空',
						trigger: 'blur'
					}],
					work: [{
						required: true,
						message: '工作单位不能为空',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '市区不能为空',
						trigger: 'blur'
					}],
					province: [{
						required: true,
						message: '省份不能为空',
						trigger: 'blur'
					}],
				}
			}
		},
		filters: {},
		created() {},
		computed: {},
		watch: {},
		mounted() {},
		methods: {
			onClose() {
				this.open = false
			},
			// 取消按钮
			cancel() {
				this.open = false
				this.reset()
			},
			// 表单重置
			reset() {
				this.formType = 1
				this.form = {
					type: 8,
					name: null,
					phone: null,
					work: null,
					area: null,
					province: null,
					notes: null,
				}
				this.$nextTick(() => {
					if (this.$refs.form) {
						this.$refs.form.resetFields()
					}
				})
			},
			/** 新增按钮操作 */
			handleAdd(row) {
				this.reset()
				this.form.region = row;
				this.formType = 1
				this.open = true
				this.formTitle = '新增转介绍非千万客户'
			},
			/** 修改按钮操作 */
			handleUpdate(row, ids) {
				this.reset()
				this.formType = 2
				const id = row ? row.id : ids
				getRoster({
					"id": id
				}).then(response => {
					console.log('用户详情', response)
					this.form = response.data
					this.open = true
					this.formTitle = '修改'
				})
			},
			/** 提交按钮 */
			submitForm: function() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.submitLoading = true
						if (this.form.id !== undefined && this.form.id !== null) {
							addIntroduction(this.form).then(response => {
								this.$message.success(
									'修改成功',
									3
								)
								this.open = false
								this.$emit('ok')
							}).finally(() => {
								this.submitLoading = false
							})
						} else {
							addIntroduction(this.form).then(response => {
								this.$message.success(
									'新增成功',
									3
								)
								this.open = false
								this.$emit('ok')
							}).finally(() => {
								this.submitLoading = false
							})
						}
					} else {
						return false
					}
				})
			}
		}
	}
</script>