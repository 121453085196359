import request from '@/utils/request'


// 查询新名单库列表
export function listRoster(query) {
	return request({
		url: '/roster/roster/list',
		method: 'get',
		params: query
	})
}

// 查询新名单库分页
export function pageRoster(query) {
	return request({
		url: '/roster/roster/page',
		method: 'get',
		params: query
	})
}

// 查询新名单库详细
export function getRoster(data) {
	return request({
		url: '/roster/roster/detail',
		method: 'get',
		params: data
	})
}

// 新增新名单库
export function addRoster(data) {
	return request({
		url: '/roster/roster/add',
		method: 'post',
		data: data
	})
}

// 修改新名单库
export function updateRoster(data) {
	return request({
		url: '/roster/roster/edit',
		method: 'post',
		data: data
	})
}

// 删除新名单库
export function delRoster(data) {
	return request({
		url: '/roster/rosterMy/batchDelete',
		method: 'post',
		data: data
	})
}

// 领取客户
export function receiveRoster() {
	return request({
		url: '/roster/roster/receive',
		method: 'post',
	})
}

// 区域列表
export function getRegionList() {
	return request({
		url: '/system/dept/getRegionList',
		method: 'get',
	})
}

// 区域列表
export function getRegionLists() {
	return request({
		url: '/system/dept/getRosterRegionList',
		method: 'get',
	})
}


// 获取今日可领取数量
export function getTodayNum() {
	return request({
		url: '/roster/roster/getNum',
		method: 'get',
	})
}

// 我的新名单客户
export function getrosterMy(data) {
	return request({
		url: '/roster/rosterMy/page',
		method: 'get',
		params: data
	})
}

// 我的新名单客户详情
export function rosterMy(data) {
	return request({
		url: '/roster/rosterMy/detail',
		method: 'get',
		params: data
	})
}

// 批量转入新名单公海 3-销售公海,4-客服公海,9-意向库公海
export function batchHighSeas(data) {
	return request({
		url: '/roster/rosterMy/batchHighSeas',
		method: 'post',
		data: data
	})
}

// 新增跟进记录
export function followAdd(data) {
	return request({
		url: '/follow/follow-up/add',
		method: 'post',
		data: data
	})
}

// 跟进记录
export function followHistory(data) {
	return request({
		url: '/follow/follow-up/list',
		method: 'get',
		params: data
	})
}


// 提交意向库
export function submitIntentLibrary(data) {
	return request({
		url: '/roster/roster/submitIntentLibrary',
		method: 'post',
		data: data
	})
}

// 删除新名单客户
export function batchDelete(data) {
	return request({
		url: '/roster/rosterMy/batchDelete',
		method: 'post',
		data: data
	})
}

// 删除新名单客户
export function callMobile(data) {
	return request({
		url: '/roster/rosterMy/callByAPP',
		method: 'post',
		data: data
	})
}


// 获取全部团队列表
export function getAreaTeam() {
	return request({
		url: '/system/dept/getAllTeam',
		method: 'get',
	})
}

// 获取同区域团队列表
export function callList(data) {
	return request({
		url: '/call/call-record/list',
		method: 'get',
		params: data
	})
}

// 查询核额记录列表
export function auditamount(data) {
	return request({
		url: '/audit/audit-amount/list',
		method: 'get',
		params: data
	})
}

// 查询核额记录列表
export function auditamountpage(data) {
	return request({
		url: '/audit/audit-amount/page',
		method: 'get',
		params: data
	})
}

// 银行列表
export function bankList(data) {
	return request({
		url: '/bankdata/bankdata/list',
		method: 'get',
		params: data
	})
}

// 批量更新核额记录
export function batchUpdata(data) {
	return request({
		url: '/audit/audit-amount/batchUpdata',
		method: 'post',
		data: data
	})
}


// 批量查询部门下用户列表
export function getByDeptIds(data) {
	return request({
		url: '/system/user/getByDeptId',
		method: 'get',
		params: data
	})
}

// 新增转介绍意向库
export function addIntroduction(data) {
	return request({
		url: '/roster/roster/addIntroduction',
		method: 'post',
		data: data
	})
}

// 意向库公海批量转派
export function intentionLibraryBatchTransfer(data) {
	return request({
		url: '/roster/rosterMy/intentionLibraryBatchTransfer',
		method: 'post',
		data: data
	})
}

// 线索新名单批量转派
export function batchTransfer(data) {
	return request({
		url: '/roster/rosterMy/batchTransfer',
		method: 'post',
		data: data
	})
}

// 意向库公海批量转派
export function callByPlatform(data) {
	return request({
		url: '/roster/rosterMy/callByPlatform',
		method: 'post',
		data: data
	})
}

// 查询审批分页
export function signature(data) {
	return request({
		url: '/common/get-oss-signature',
		method: 'get',
		params: data
	})
}

// 查询审批分页
export function exportsFile(data) {
	return request({
		url: '/roster/roster/export',
		method: 'post',
		data: data
	})
}

// 查询审批分页
export function roterDelete(data) {
	return request({
		url: '/roster/roster/delete',
		method: 'post',
		data: data
	})
}


// 一键删除新名单库
export function deleteAll(data) {
	return request({
		url: '/roster/roster/deleteAll',
		method: 'post',
		data: data
	})
}

// 一键删除公海客户
export function rosterMydeleteAll(data) {
	return request({
		url: '/roster/rosterMy/deleteAll',
		method: 'post',
		data: data
	})
}


export function calllisthistory(data) {
	return request({
		url: '/roster/roster-phone/list',
		method: 'get',
		params: data
	})
}