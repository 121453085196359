<template>
	<div class="loginbox">
		<div class="flex">
			<div class="flex-sub">
				<a-carousel autoplay class="carousexbox">
					<div>
						<img class="img"
							src="https://oss.tiantianhuoke.com/uploads/images/20230414/20b9b250ff206a38da1fdf0ed2ae0087.jpg" alt="">
					</div>
					<div>
						<img class="img"
							src="https://oss.tiantianhuoke.com/uploads/images/20230414/697d3827ad7b6014baf9ec4641ef86e3.jpg" alt="">
					</div>
				</a-carousel>
			</div>
			<div class="flex-sub">
				<div class="main" style="min-width: 260px;width: 500px;margin: 50px auto;">
					<div class="top">
						<div class="header text-left">
							<a href="/">
								<span class="title">融企客户管理系统</span>
							</a>
						</div>
						<div class="desc">
						</div>
					</div>
					<!-- <div class="padding-tb text-xl text-bold title2">欢迎登录</div> -->
					<div class="text-center">
						<a-tabs v-model='activeKey' @change="changeTab" size="large" :tabBarGutter="80">
							<a-tab-pane key="1" tab="账号登录">
							</a-tab-pane>
							<!-- <a-tab-pane key="2" tab="手机验证码登录" v-if="smsLoginEnabled">
							</a-tab-pane> -->
						</a-tabs>
					</div>
					<a-form-model id="formLogin" ref="form" class="user-layout-login" :model="form" :rules="rules">
						<div v-if="activeKey === '1'">
							<a-alert v-if="isLoginError" type="error" showIcon style="margin-bottom: 24px;" :message="loginErrorInfo"
								closable :after-close="handleCloseLoginError" />
							<a-form-model-item prop="accountNo">
								<a-input v-model="form.accountNo" size="large" placeholder="账户: admin">
									<a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
								</a-input>
							</a-form-model-item>
							<a-form-model-item prop="password">
								<a-input-password v-model="form.password" size="large" placeholder="密码: admin123">
									<a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
								</a-input-password>
							</a-form-model-item>
							<a-form-model-item prop="rememberMe" class="text-left">
								<div>
									<a-checkbox :checked="form.rememberMe" @change="rememberMe">记住密码</a-checkbox>
								</div>
							</a-form-model-item>
							<a-form-model-item style="margin-top:24px">
								<!--开启图形验证码-->
								<a-button size="large" type="primary" htmlType="submit" class="login-button" :loading="logining"
									:disabled="logining" @click="userLoginVerify" v-if="captchaEnabled && activeKey == 1">确定</a-button>
								<!--未开启图形验证码-->
								<a-button size="large" type="primary" htmlType="submit" class="login-button" :loading="logining"
									:disabled="logining" @click="handleSubmit" v-if="!captchaEnabled">确定</a-button>
							</a-form-model-item>
						</div>
						<a-row v-if="activeKey === '2'">
							<a-form-model-item prop="mobile">
								<a-input v-model="form.mobile" size="large" placeholder="手机号" :maxLength="11">
									<a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }" />
								</a-input>
							</a-form-model-item>
							<div v-if="smsLoginEnabled">
								<a-form-model-item prop="code">
									<div style="position: relative;">
										<a-input v-model="form.code" size="large" type="text" autocomplete="off" placeholder="验证码"
											:maxLength="6">
											<a-icon slot="prefix" type="security-scan" :style="{ color: 'rgba(0,0,0,.25)' }" />
										</a-input>
										<span class="codeText">
											<span v-if="mobileCodeTimer <= 0" @click="smsCodeVerify">
												获取验证码
											</span>
											<a-spin v-if="codeLoading" size="small">
												<a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
											</a-spin>
											<span v-if="mobileCodeTimer > 0">
												{{ mobileCodeTimer }}秒后可重新获取
											</span>
										</span>
									</div>
								</a-form-model-item>
								<a-form-model-item style="margin-top:24px">
									<a-button size="large" type="primary" htmlType="submit" class="login-button" :loading="logining"
										:disabled="logining" @click="handleSubmit">登录</a-button>
								</a-form-model-item>
							</div>
						</a-row>

						<div class="user-login-other">
							<!--
          ruoyi后台不支持获取是否开启账户.
          故此处不做隐藏处理. 在ruoyi原前端中是在注册页面定义一个属性手动修改处理.
          <router-link class="register" :to="{ name: 'register' }">注册账户</router-link>
        -->

							<div style="position: absolute;left: 0;top: 10px;" v-if="captchaEnabled || smsLoginEnabled">
								<Verify ref="verify" mode="pop" @success="success" :captchaType="'blockPuzzle'"
									:imgSize="{ width: '330px', height: '155px' }"></Verify>
							</div>
						</div>
					</a-form-model>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapActions
	} from 'vuex'
	import {
		timeFix
	} from '@/utils/util'
	import {
		getSmsCode,
		getCodeImg,
		getLoginConfig
	} from '@/api/login'
	import {
		LOGIN_USERNAME,
		LOGIN_PASSWORD,
		LOGIN_REMEMBERME
	} from '@/store/mutation-types'
	import Verify from '@/components/verifition/Verify.vue'
	import storage from 'store'

	export default {
		components: {
			Verify
		},
		...mapActions(['Login', 'SmsLogin', 'Logout']),
		data() {
			return {
				activeKey: '1',
				codeUrl: '',
				isLoginError: false,
				loginErrorInfo: '',
				mobileCodeTimer: 0,
				codeText: '获取验证码',
				codeLoading: false,
				form: {
					accountNo: 'admin',
					password: 'admin123',
					mobile: '',
					code: undefined,
					validCodeReqNo: '',
					uuid: '',
					rememberMe: false
				},
				rules: {},
				rule1: {
					username: [{
						required: true,
						message: '请输入帐户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
				},
				rule3: {
					code: [{
						required: true,
						message: '请输入图形验证码',
						trigger: 'blur'
					}],
				},
				rule2: {
					mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
						message: '请正确填写手机号',
						trigger: 'blur'
					}],
				},
				logining: false,
				captchaEnabled: false,
				smsLoginEnabled: false,
				redirect: undefined
			}
		},
		watch: {
			$route: {
				handler: function(route) {
					this.redirect = route.query && route.query.redirect
				},
				immediate: true
			},
			// activeKey: {
			//   handler: function (newVal) {
			//     if (newVal) {
			//       this.$refs.form.resetFields()
			//       this.logining = false
			//       if (newVal === '1') {
			//         this.rules = this.rule1
			//         this.form.username = "";
			//         this.form.password = "";
			//       } else if (newVal === '2') {
			//         this.form.code = "";
			//         this.form.mobile = "";
			//         this.rules = this.rule2
			//       }
			//     }
			//   },
			//   immediate: true
			// }
		},
		created() {
			this.getStorage()
			this.rules = this.rule1
			this.getConfig()
		},
		methods: {
			/**
			 * 滑块验证插件
			 */
			success(params) {
				this.form.captchaVerification = params.captchaVerification
				if (this.activeKey == 1) {
					this.handleSubmit()
				} else if (this.activeKey == 2) {
					this.getSmsCode()
				}
			},
			// 点击显示滑块验证弹窗
			userLoginVerify() {
				this.$refs.verify.show()
			},
			// 点击显示滑块验证弹窗
			smsCodeVerify() {
				//校验手机号
				this.$refs.form.validate(valid => {
					if (valid) {
						this.$refs.verify.show()
					}
				});
			},
			changeTab() {
				if (this.activeKey === '1') {
					this.rules = this.rule1
				} else if (this.activeKey === '2') {
					this.rules = this.rule2
				}
			},
			/**—————————————————————————— 获取配置项，验证码和图形验证码是否开启 start ——————————————————————————*/
			getConfig() {
				getLoginConfig().then(res => {
					this.captchaEnabled = res.data.captchaEnabled
					this.smsLoginEnabled = res.data.smsLoginEnabled
				})
			},
			/**—————————————————————————— 获取配置项，验证码和图形验证码是否开启 end   ——————————————————————————*/


			/** —————————————————————————— 发送短信验证码 start —————————————————————————— **/
			getSmsCode() {
				this.$refs.form.validate(valid => {
					if (valid) {
						if (this.mobileCodeTimer > 0) return;
						this.codeLoading = true
						getSmsCode({
							mobile: this.form.mobile,
							templateCode: 'MOBILE_LOGIN',
							captchaVerification: this.form.captchaVerification
						}).then(res => {
							this.codeLoading = false
							if (res.success) {
								this.mobileCodeTimer = 60;
								this.form.validCodeReqNo = res.data
								let msgTimer = setInterval(() => {
									this.mobileCodeTimer = this.mobileCodeTimer - 1;
									if (this.mobileCodeTimer <= 0) {
										clearInterval(msgTimer);
									}
								}, 1000);
							} else {
								this.$message.error(res.message)
							}
						}).catch(() => {
							setTimeout(() => {
								this.codeLoading = false
							}, 5000)
						})
					}
				});
			},
			getStorage() {
				const accountNo = storage.get(LOGIN_USERNAME)
				const password = storage.get(LOGIN_PASSWORD)
				const rememberMe = storage.get(LOGIN_REMEMBERME)
				if (accountNo) {
					this.form = {
						accountNo: accountNo,
						password: password,
						rememberMe: rememberMe
					}
				}
			},
			handleSubmit() {
				this.logining = true
				this.$refs.form.validate(valid => {
					if (valid) {
						if (this.form.rememberMe) {
							storage.set(LOGIN_USERNAME, this.form.accountNo)
							storage.set(LOGIN_PASSWORD, this.form.password)
							storage.set(LOGIN_REMEMBERME, this.form.rememberMe)
						} else {
							storage.remove(LOGIN_USERNAME)
							storage.remove(LOGIN_PASSWORD)
							storage.remove(LOGIN_REMEMBERME)
						}
						let loginApi = ''
						if (this.activeKey === '1') {
							loginApi = 'Login'
						} else if (this.activeKey === '2') {
							if (this.smsLoginEnabled) {
								loginApi = 'SmsLogin'
							}
						}
						console.log(this.form)
						this.$store.dispatch(loginApi, this.form).then((res) => {
							this.loading = false
							this.$router.push({
								path: this.redirect || '/'
							}).catch(() => {})
						}).catch((err) => {
							this.loading = false
							setTimeout(() => {
								this.logining = false
							}, 1000)
						})
					} else {
						setTimeout(() => {
							this.logining = false
						}, 600)
					}
				})
			},
			loginSuccess(res) {
				this.$router.push({
					path: '/'
				})
				// 延迟 1 秒显示欢迎信息
				setTimeout(() => {
					this.$notification.success({
						message: '欢迎',
						description: `${timeFix()}，欢迎回来`
					})
				}, 1000)
				this.handleCloseLoginError()
			},
			requestFailed(err) {
				this.isLoginError = true
				this.loginErrorInfo = err
				this.form.code = undefined
				if (this.captchaEnabled) {
					this.getCode()
				}
			},
			handleCloseLoginError() {
				this.isLoginError = false
				this.loginErrorInfo = ''
			},
			onChange() {},
			rememberMe(e) {
				this.form.rememberMe = e.target.checked
			},
		}
	}
</script>

<style lang="less" scoped>
	.top {
		text-align: center;

		.header {
			height: 44px;
			line-height: 44px;

			.badge {
				position: absolute;
				display: inline-block;
				line-height: 1;
				vertical-align: middle;
				margin-left: -12px;
				margin-top: -10px;
				opacity: 0.8;
			}

			.logo {
				height: 44px;
				vertical-align: top;
				margin-right: 16px;
				border-style: none;
			}

			.title {
				font-size: 28px;
				color: rgba(0, 0, 0, .85);
				font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
				font-weight: 600;
				position: relative;
				top: 2px;
			}
		}

		.desc {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.45);
			margin-top: 12px;
			margin-bottom: 40px;
		}
	}

	.user-layout-login {
		label {
			font-size: 14px;
		}

		.getCaptcha {
			display: block;
			width: 100%;
			height: 40px;
			cursor: pointer;
		}

		button.login-button {
			padding: 0 15px;
			font-size: 16px;
			height: 40px;
			width: 100%;
		}

		.user-login-other {
			text-align: left;
			margin-top: 24px;
			line-height: 22px;

			.register {
				float: right;
			}
		}
	}

	.ant-carousel .slick-slide {
		text-align: center;
		height: 160px;
		line-height: 160px;
		background: #364d79;
		overflow: hidden;
	}

	.ant-carousel .slick-slide h3 {
		color: #fff;
	}

	.loginbox {
		width: 1500px;
		margin: 0 auto;
		background-color: #fff;
		border-radius: 16px;

		.title2 {
			font-size: 20px;
		}

		.img {
			width: 750px;
			height: 605px;
			display: inline-block;
			border-top-left-radius: 16px;
			border-bottom-left-radius: 16px;
			display: block;
		}
	}

	/deep/.ant-carousel .slick-dots-bottom {
		bottom: 20px;
	}

	/deep/.ant-carousel .slick-dots li button {
		height: 6px;
		width: 10px;
		border-radius: 5px;
		background: rgba(0, 82, 199, .7);
	}

	/deep/.ant-carousel .slick-dots li.slick-active button {
		background: #0052c7 !important;
		border-radius: 5px;
		width: 24px;
	}

	.carousexbox {
		width: 750px;
		height: 605px;
		position: relative;
		z-index: 10;
	}

	.codeText {
		color: #1890ff;
		position: absolute;
		padding: 0 10px;
		right: 10px;
		cursor: pointer;
	}
</style>