<template>
	<a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
		<a-divider orientation="left">
			<b>{{ formTitle }}</b>
		</a-divider>
		<a-form-model ref="form" :model="form" :rules="rules">
			<a-row :gutter="24">
				<a-col :md="8" :sm="24">
					<a-form-item label="所属团队" prop="regionName">
						<a-input v-model="form.teamName" disabled placeholder="所属团队" allow-clear />
					</a-form-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-item label="所属坐席" prop="userName">
						<a-input v-model="form.userName" disabled placeholder="所属坐席" allow-clear />
					</a-form-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-item label="客户名称" prop="name">
						<a-input v-model="form.name" disabled placeholder="客户名称" allow-clear />
					</a-form-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-item label="客户来源" prop="source">
						<a-input v-model="form.source==1?'新名单库':'线索新名单'" disabled placeholder="客户来源" allow-clear />
					</a-form-item>
				</a-col>
				<!-- <a-col :md="8" :sm="24">
					<a-form-item label="所属客服" prop="customerServiceName">
						<a-input v-model="form.customerServiceName" disabled placeholder="所属客服" allow-clear />
					</a-form-item>
				</a-col> -->
				<a-col :md="8" :sm="24">
					<a-form-model-item label="手机号" prop="phone">
						<a-input v-model="form.phone" disabled placeholder="手机号" />
					</a-form-model-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-model-item label="工作单位" prop="work">
						<a-input v-model="form.work" disabled placeholder="工作单位" />
					</a-form-model-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-model-item label="地区" prop="area">
						<a-input v-model="form.area" disabled placeholder="地区" />
					</a-form-model-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-model-item label="领取/分配时间" prop="time">
						<a-input v-model="form.time" disabled placeholder="领取/分配时间" />
					</a-form-model-item>
				</a-col>
			</a-row>

			<a-radio-group v-model="tab" button-style="solid" size="large">
				<a-radio-button :value="1">跟进记录</a-radio-button>
				<a-radio-button :value="2">客户情况</a-radio-button>
				<a-radio-button :value="3">呼叫记录</a-radio-button>
			</a-radio-group>

			<div class="contents">
				<div class="record-history" v-if="tab==1">
					<div class="record-history-list" v-for="(item,index) in followHistoryList" :key="index">
						<div class="record-history-user">
							<span>{{item.userName}}</span>
							<a-divider type="vertical" />
							<span>更新客户信息时间</span>
							<a-divider type="vertical" />
							<span>{{item.updateTime}}</span>
						</div>
						<div class="record-history-content">
							<div class="history-content-title">
								<div>跟进内容</div>
								<div>{{item.followUpRecords}}</div>
							</div>
							<div class="history-content-title">
								<div>下次跟进时间</div>
								<div>{{item.followUpTime}}</div>
							</div>
						</div>
					</div>
					<a-empty v-if="followHistoryList.length==0" />
				</div>
				<div class="customer-situation" v-if="tab==2">
					<div class="customer-situation-item">
						<span class="item-title">近一年开票金额(元)</span>
						<a-input style="margin-top: 10px;" placeholder="开票金额" disabled v-model="form.invoiceAmount"></a-input>
					</div>
					<div class="customer-situation-item">
						<span class="item-title">近一年纳税金额(元)</span>
						<a-input style="margin-top: 10px;" placeholder="纳税金额" disabled v-model="form.taxAmount"></a-input>
					</div>
					<div class="customer-situation-item">
						<span class="customer-situation-item-title">是否加微信成功</span>
						<a-radio-group name="radioGroup" v-model="form.addWechat">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</div>
					<div class="customer-situation-item">
						<span class="customer-situation-item-title">是否继续跟进</span>
						<a-radio-group name="radioGroup" v-model="form.fundingRequirements">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</div>
					<div class="customer-situation-item">
						<span class="customer-situation-item-title">是否可提供维转介绍线索</span>
						<a-radio-group name="radioGroup" v-model="form.clue">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</div>
				</div>
				<div class="call-records" v-if="tab==3">
					<a-table :columns="columns1" :data-source="callList"></a-table>
				</div>
			</div>
			<!-- <div class="bottom-control">
				<a-space>
					<a-button type="primary" :loading="submitLoading" @click="submitForm">
						保存
					</a-button>
					<a-button type="dashed" @click="cancel">
						取消
					</a-button>
				</a-space>
			</div> -->
		</a-form-model>
	</a-drawer>
</template>

<script>
	import {
		rosterMy,
		addRoster,
		updateRoster,
		followHistory,
		callList
	} from '@/api/roster/roster'

	export default {
		name: 'CreateForm',
		props: {},
		components: {},
		data() {
			return {
				submitLoading: false,
				formTitle: '',
				tab: 1,
				// 表单参数
				form: {
					id: null,
					name: null,
					phone: null,
					work: null,
					area: null,
					notes: null,
					region: null,
					receive: null,
					callNum: null,
					lastCallTime: null,
					followUpRecords: null,
					followUpTime: null,
					invoiceAmount: null,
					taxAmount: null,
					addWechat: null,
					regionName: null,
					fundingRequirements: null,
					clue: null
				},
				// 1增加,2修改
				formType: 1,
				open: false,
				rules: {
					name: [{
						required: true,
						message: '客户名称不能为空',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '手机号不能为空',
						trigger: 'blur'
					}],
					work: [{
						required: true,
						message: '工作单位不能为空',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '地区不能为空',
						trigger: 'blur'
					}],
					notes: [{
						required: true,
						message: '备注不能为空',
						trigger: 'blur'
					}],
					region: [{
						required: true,
						message: '所属区域，外键{sys_dept.id}不能为空',
						trigger: 'blur'
					}],
					receive: [{
						required: true,
						message: '是否被领取,0-否,1-是不能为空',
						trigger: 'blur'
					}],
					callNum: [{
						required: true,
						message: '通话总次数不能为空',
						trigger: 'blur'
					}],
					followUpRecords: [{
						required: true,
						message: '最新跟进记录不能为空',
						trigger: 'blur'
					}],
					invoiceAmount: [{
						required: true,
						message: '开票金额(万)不能为空',
						trigger: 'blur'
					}],
					taxAmount: [{
						required: true,
						message: '纳税金额(万)不能为空',
						trigger: 'blur'
					}],
					addWechat: [{
						required: true,
						message: '是否加微信成功,0-否,1-是不能为空',
						trigger: 'blur'
					}],
					fundingRequirements: [{
						required: true,
						message: '是否有资金需求,0-否,1-是不能为空',
						trigger: 'blur'
					}],
					clue: [{
						required: true,
						message: '是否可提供为转介绍线索,0-否,1-是不能为空',
						trigger: 'blur'
					}]
				},
				columns1: [{
						title: '序号',
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '呼叫录音',
						align: 'center',
						dataIndex: 'tapeFile',
						key: 'tapeFile',
					},
					{
						title: '时长',
						align: 'center',
						dataIndex: 'callTime',
						key: 'callTime',
					},
					{
						title: '操作人',
						align: 'center',
						dataIndex: 'userName',
						key: 'userName',
					},
					{
						title: '操作时间',
						align: 'center',
						dataIndex: 'createTime',
						key: 'createTime',
					}
				],
				followHistoryList: [],
				callList: []
			}
		},
		filters: {},
		created() {},
		computed: {},
		watch: {},
		mounted() {},
		methods: {
			onClose() {
				this.open = false
			},
			// 取消按钮
			cancel() {
				this.open = false
				this.reset()
			},
			// 表单重置
			reset() {
				this.formType = 1
				this.form = {
					id: null,
					name: null,
					phone: null,
					work: null,
					area: null,
					notes: null,
					region: null,
					receive: null,
					callNum: null,
					lastCallTime: null,
					followUpRecords: null,
					followUpTime: null,
					invoiceAmount: null,
					taxAmount: null,
					addWechat: null,
					fundingRequirements: null,
					clue: null
				}
				this.$nextTick(() => {
					if (this.$refs.form) {
						this.$refs.form.resetFields()
					}
				})
			},
			/** 新增按钮操作 */
			handleAdd(row) {
				this.reset()
				this.formType = 1
				this.open = true
				this.formTitle = '添加'
			},
			/** 新增按钮操作 */
			handleLook(record) {
				this.reset()
				this.formType = 1
				this.open = true
				this.formTitle = '查看'
				this.getFollowHistory(record.rosterId)
				this.getCallList(record.rosterId)
				rosterMy({
					"id": record.id
				}).then(response => {
					console.log('查看', response)
					this.form = response.data
				})
			},
			getFollowHistory(id) {
				followHistory({
					rosterId: id
				}).then(res => {
					console.log('跟进记录', res);
					if (res.code == '20001') {
						this.followHistoryList = res.data;
					}
				})
			},
			// 通话记录
			getCallList(id) {
				callList({
					rosterId: id
				}).then(res => {
					console.log('通话记录', res)
					this.callList = res.data;
				})
			},
			/** 修改按钮操作 */
			handleUpdate(row, ids) {
				this.reset()
				this.formType = 2
				const id = row ? row.id : ids
				rosterMy({
					"id": id
				}).then(response => {
					this.form = response.data
					this.open = true
					this.formTitle = '修改'
				})
			},
			/** 提交按钮 */
			submitForm: function() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.submitLoading = true
						if (this.form.id !== undefined && this.form.id !== null) {
							updateRoster(this.form).then(response => {
								this.$message.success(
									'修改成功',
									3
								)
								this.open = false
								this.$emit('ok')
							}).finally(() => {
								this.submitLoading = false
							})
						} else {
							addRoster(this.form).then(response => {
								this.$message.success(
									'新增成功',
									3
								)
								this.open = false
								this.$emit('ok')
							}).finally(() => {
								this.submitLoading = false
							})
						}
					} else {
						return false
					}
				})
			}
		}
	}
</script>

<style type="text/css">
	.contents {
		margin-top: 32px;
	}

	.record-history {}

	.record-history-list {
		padding: 20px;
		border: 1px solid #eee;
		border-radius: 16px;
	}

	.record-history-user {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 20px;
	}

	.record-history-user span:first-child {
		font-weight: bold;
	}

	.record-history-content {
		background: #eee;
		padding: 20px;
		margin-bottom: 20px;
	}

	.history-content-title {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.history-content-title:first-child {
		margin-bottom: 20px;
	}

	.history-content-title div:nth-child(1) {
		width: 150px;
	}

	.history-content-title div:nth-child(2) {
		width: 600px;
	}

	.customer-situation {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		flex-wrap: wrap;
	}

	.customer-situation-item {
		margin-bottom: 20px;
		width: 32%;
	}

	.customer-situation-item-title {
		margin-right: 10px;
	}

	.customer-situation-item:nth-child(3n-1) {
		margin-left: 2%;
		margin-right: 2%;
	}
</style>