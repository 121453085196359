<template>
	<a-drawer title="转派" width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
		<a-form-model ref="form" :model="form">
			<a-form-model-item label="所属团队" prop="name">
				<a-select placeholder="请选择转派团队" v-model="teamId" @change="handleChange">
					<a-select-option :value="item.id" v-for="(item,index) in teamList"
						:key="index">{{item.deptName}}</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="销售名称" prop="phone">
				<a-select placeholder="请选择转派销售" v-model="userId">
					<a-select-option :value="item.id" v-for="(item,index) in salesList"
						:key="index">{{item.nickName}}</a-select-option>
				</a-select>
			</a-form-model-item>
			<div class="bottom-control">
				<a-space>
					<a-button type="primary" :loading="submitLoading" @click="submitForm">
						保存
					</a-button>
					<a-button type="dashed" @click="cancel">
						取消
					</a-button>
				</a-space>
			</div>
		</a-form-model>
	</a-drawer>
</template>

<script>
	import {
		getAreaTeam,
		getByDeptIds,
		intentionLibraryBatchTransfer
	} from '@/api/roster/roster'

	export default {
		name: 'TransferForm',
		props: {},
		components: {},
		data() {
			return {
				teamList: [], //团队列表
				salesList: [],
				submitLoading: false,
				formTitle: '',
				// 表单参数
				form: {
					id: null,
					name: null,
					phone: null,
					work: null,
					area: null,
					notes: null,
					region: null,
					receive: null,
					callNum: null,
					lastCallTime: null,
					followUpRecords: null,
					followUpTime: null,
					invoiceAmount: null,
					taxAmount: null,
					addWechat: null,
					fundingRequirements: null,
					clue: null
				},
				// 1增加,2修改
				formType: 1,
				open: false,
				saleList: [],
				ids: [], //主键
				userId: undefined, //销售id
				teamId: undefined, //团队id
			}
		},
		filters: {},
		created() {},
		computed: {},
		watch: {},
		mounted() {},
		methods: {
			// 所属团队
			handleChange(e) {
				console.log(e);
				this.getSaleList(e);
			},
			onClose() {
				this.open = false
			},
			// 取消按钮
			cancel() {
				this.open = false
				this.reset()
			},
			// 表单重置
			reset() {
				this.formType = 1
				this.form = {
					id: null,
					name: null,
					phone: null,
					work: null,
					area: null,
					notes: null,
					region: null,
					receive: null,
					callNum: null,
					lastCallTime: null,
					followUpRecords: null,
					followUpTime: null,
					invoiceAmount: null,
					taxAmount: null,
					addWechat: null,
					fundingRequirements: null,
					clue: null
				}
				this.$nextTick(() => {
					if (this.$refs.form) {
						this.$refs.form.resetFields()
					}
				})
			},
			/** 新增按钮操作 */
			handleAdd(row) {
				if (typeof row == 'object') {
					this.ids = row
				} else {
					this.ids = [row];
				}
				this.formType = 1
				this.open = true
				this.formTitle = '转派';
				this.getTeamList()
			},
			// 获取团队列表
			getTeamList() {
				getAreaTeam().then(res => {
					console.log('区域团队列表', res);
					if (res.success) {
						this.teamList = res.data;
					}
				})
			},
			// 销售列表
			getSaleList(id) {
				getByDeptIds({
					deptId: id,
					roleCode: 'xs'
				}).then(res => {
					if (res.success) {
						console.log('人员列表', res);
						this.salesList = res.data;
					}
				})
			},
			// 确认转派
			submitForm() {
				if (!this.teamId) {
					this.$message.error('请选择所属团队');
					return false;
				}
				if (!this.userId) {
					this.$message.error('请选择转派销售');
					return false;
				}
				let arr = []
				this.ids.map(item => {
					arr.push({
						id: item
					})
				})
				let data = {
					idList: arr,
					userId: this.userId
				}
				intentionLibraryBatchTransfer(data).then(res => {
					if (res.success) {
						console.log('确认转派', res)
						this.$message.success('转派成功');
						this.userId = null;
						this.teamId = null;
						this.$emit('ok');
						this.open = false;
					} else {
						this.$message.error(res.message);
					}
				})
			},
			/** 修改按钮操作 */
			handleUpdate(row, ids) {
				this.reset()
				this.formType = 2
				const id = row ? row.id : ids
				getRoster({
					"id": id
				}).then(response => {
					this.form = response.data
					this.open = true
					this.formTitle = '修改'
				})
			},
		}
	}
</script>