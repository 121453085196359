import request from '@/utils/request'


// 查询审批管理列表
export function listManage(query) {
  return request({
    url: '/approval/approval-manage/list',
    method: 'get',
    params: query
  })
}

// 查询审批管理分页
export function pageManage(query) {
  return request({
    url: '/approval/approval-manage/page',
    method: 'get',
    params: query
  })
}

// 查询审批管理详细
export function getManage(data) {
  return request({
    url: '/approval/approval-manage/detail',
    method: 'get',
    params: data
  })
}

// 新增审批管理
export function addManage(data) {
  return request({
    url: '/approval/approval-manage/add',
    method: 'post',
    data: data
  })
}

// 修改审批管理
export function updateManage(data) {
  return request({
    url: '/approval/approval-manage/edit',
    method: 'post',
    data: data
  })
}

// 删除审批管理
export function delManage(data) {
  return request({
    url: '/approval/approval-manage/delete',
    method: 'post',
    data: data
  })
}
