<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="员工id,外键{sys_user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入员工id,外键{sys_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="名单库id,外键{zb_roster.id}" prop="rosterId" >
        <a-input v-model="form.rosterId" placeholder="请输入名单库id,外键{zb_roster.id}" />
      </a-form-model-item>
      <a-form-model-item label="客户来源,1-新名单库,2-线索新名单" prop="source" >
        <a-input v-model="form.source" placeholder="请输入客户来源,1-新名单库,2-线索新名单" />
      </a-form-model-item>
      <a-form-model-item label="客服id,外键{sys_user.id}" prop="customerServiceId" >
        <a-input v-model="form.customerServiceId" placeholder="请输入客服id,外键{sys_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="领取/分配时间" prop="time" >
        <a-date-picker style="width: 100%" v-model="form.time" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="类型,1-销售,2-客服,3-销售公海,4-客服公海,5-新开千万意向库,6-转介绍千万意向库,7-新开非千万意向库,8-转介绍非千万意向库,9-意向库公海,10-提款客户" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="转公海原因" prop="reason" >
        <a-input v-model="form.reason" placeholder="请输入转公海原因" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMy, addMy, updateMy } from '@/api/roster/my'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        rosterId: null,

        source: null,

        customerServiceId: null,

        time: null,

        type: null,

        reason: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '员工id,外键{sys_user.id}不能为空', trigger: 'blur' }
        ],
        rosterId: [
          { required: true, message: '名单库id,外键{zb_roster.id}不能为空', trigger: 'blur' }
        ],
        source: [
          { required: true, message: '客户来源,1-新名单库,2-线索新名单不能为空', trigger: 'blur' }
        ],
        customerServiceId: [
          { required: true, message: '客服id,外键{sys_user.id}不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '类型,1-销售,2-客服,3-销售公海,4-客服公海,5-新开千万意向库,6-转介绍千万意向库,7-新开非千万意向库,8-转介绍非千万意向库,9-意向库公海,10-提款客户不能为空', trigger: 'change' }
        ],
        reason: [
          { required: true, message: '转公海原因不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        rosterId: null,
        source: null,
        customerServiceId: null,
        time: null,
        type: null,
        reason: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMy({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMy(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMy(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
