<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="所属区域" prop="region">
								<a-select v-model="queryParam.region" @change="handleChange" placeholder="请选择所属区域" allow-clear>
									<a-select-option :value="region.id" v-for="(region,index) in regionLists" :key="index">
										{{region.deptName}}
									</a-select-option>
								</a-select>
								<!-- <a-input v-model="queryParam.region" placeholder="请输入所属区域" allow-clear /> -->
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="所属团队" prop="teamId">
								<a-select v-model="queryParam.teamId" placeholder="请选择团队" allow-clear>
									<a-select-option :value="team.id" v-for="(team,index) in teamList" :key="index">
										{{team.label}}
									</a-select-option>
								</a-select>
								<!-- <a-input v-model="queryParam.rosterId" placeholder="请选择所属团队" allow-clear /> -->
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="客户名称" prop="name">
								<a-input v-model="queryParam.name" placeholder="请输入客户名称" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="手机号" prop="phone">
								<a-input v-model="queryParam.phone" placeholder="请输入手机号" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="银行" prop="bankdataId">
								<a-select placeholder="请选择银行" v-model="queryParam.bankdataId" allow-clear>
									<a-select-option :value="item.id" v-for="(item,index) in bankList"
										:key="index">{{item.name}}</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="创建时间" prop="createBeginTime">
								<a-date-picker placeholder="请选择创建时间" @change="getSelectTime" style="width: 100%;" />
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 8 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<a-button type="danger" :disabled="multiple" @click="handleDelete"
					v-hasPermi="['audit:amount:remove']">批量删除</a-button>
				<!-- <a-divider type="vertical" v-hasPermi="['audit:amount:remove']" /> -->
				<a-button type="primary" @click="handleExport" v-hasPermi="['audit:amount:remove']">导出</a-button>
			</div>
			<!-- 增加修改 -->
			<create-form ref="createForm" @ok="getList" />
			<!-- 数据展示 -->
			<a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
				:bordered="tableBordered">
				<span slot="createTime" slot-scope="text, record">
					{{ parseTime(record.createTime) }}
				</span>
				<span slot="operation" slot-scope="text, record">
					<a-divider type="vertical" v-hasPermi="['audit:amount:remove']" />
					<a @click="handleDelete(record)" v-hasPermi="['audit:amount:remove']">删除</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		pageAmount,
		listAmount,
		delAmount,
		bankdata,
		regionList,
		areaTeam
	} from '@/api/audit/amount'
	import CreateForm from './modules/CreateForm'
	import {
		tableMixin
	} from '@/store/table-mixin'

	export default {
		name: 'Amount',
		components: {
			CreateForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					region: undefined,
					name: null,
					phone: null,
					bankdataId: undefined,
					teamId: undefined,
					createBeginTime: null,
					pageNum: 1,
					pageSize: 10
				},
				columns: [{
						title: '序号',
						ellipsis: true,
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '所属团队',
						dataIndex: 'regionName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '所属坐席',
						dataIndex: 'userName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '客户名称',
						dataIndex: 'name',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '手机号',
						dataIndex: 'phone',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '银行',
						dataIndex: 'bankName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '核额金额(万)',
						dataIndex: 'money',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '创建时间',
						dataIndex: 'createTime',
						scopedSlots: {
							customRender: 'createTime'
						},
						ellipsis: true,
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'operation',
						width: '18%',
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				bankList: [], //银行列表
				regionLists: [], //区域列表
				teamList: [],
			}
		},
		filters: {},
		created() {
			this.getList();
			this.getbankdata();
			this.getRegionList()
		},
		computed: {},
		watch: {},
		methods: {
			// 选择时间
			getSelectTime(e) {
				this.queryParam.createBeginTime = e.format('YYYY-MM-DD hh:mm:ss');
			},
			/** 查询核额记录列表 */
			getList() {
				this.loading = true
				pageAmount(this.queryParam).then(response => {
					console.log('核额列表', response)
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},
			// 银行列表
			getbankdata() {
				bankdata().then(response => {
					console.log('银行列表', response)
					this.bankList = response.data;
				})
			},
			// 区域列表
			getRegionList() {
				regionList().then(res => {
					if (res.success) {
						console.log('区域列表', res);
						this.regionLists = res.data;
					}
				})
			},
			getAreaTeam() {
				areaTeam({
					parentId: this.queryParam.areaDetpId
				}).then(res => {
					if (res.success) {
						console.log('团队列表', res);
						this.teamList = res.data;
					}
				})
			},
			handleChange(e) {
				this.queryParam.areaDetpId = e;
				this.queryParam.teamDetpId = '';
				this.getAreaTeam();
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					region: undefined,
					name: null,
					phone: null,
					bankdataId: undefined,
					teamId: undefined,
					createBeginTime: null,
					pageNum: 1,
					pageSize: 10
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return delAmount(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('audit/audit-amount/export', {
							...that.queryParam
						}, `核额记录_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			}
		}
	}
</script>