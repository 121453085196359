import request from '@/utils/request'


// 查询新名单与手机号关联列表
export function listPhone(query) {
  return request({
    url: '/roster/roster-phone/list',
    method: 'get',
    params: query
  })
}

// 查询新名单与手机号关联分页
export function pagePhone(query) {
  return request({
    url: '/roster/roster-phone/page',
    method: 'get',
    params: query
  })
}

// 查询新名单与手机号关联详细
export function getPhone(data) {
  return request({
    url: '/roster/roster-phone/detail',
    method: 'get',
    params: data
  })
}

// 新增新名单与手机号关联
export function addPhone(data) {
  return request({
    url: '/roster/roster-phone/add',
    method: 'post',
    data: data
  })
}

// 修改新名单与手机号关联
export function updatePhone(data) {
  return request({
    url: '/roster/roster-phone/edit',
    method: 'post',
    data: data
  })
}

// 删除新名单与手机号关联
export function delPhone(data) {
  return request({
    url: '/roster/roster-phone/delete',
    method: 'post',
    data: data
  })
}
