import request from '@/utils/request'

// 查询公告列表分页
export function pageNotice(query) {
	return request({
		url: '/system/notice/page',
		method: 'get',
		params: query
	})
}
// 查询公告列表
export function listNotice(query) {
	return request({
		url: '/system/notice/list',
		method: 'get',
		params: query
	})
}
// 查询公告详细
export function getNotice(noticeId) {
	return request({
		url: '/system/notice/' + noticeId,
		method: 'get'
	})
}

// 新增公告
export function addNotice(data) {
	return request({
		url: '/system/notice',
		method: 'post',
		data: data
	})
}

// 修改公告
export function updateNotice(data) {
	return request({
		url: '/system/notice',
		method: 'put',
		data: data
	})
}

// 删除公告
export function delNotice(noticeId) {
	return request({
		url: '/system/notice/' + noticeId,
		method: 'delete'
	})
}

// 消息列表
export function newPage(query) {
	return request({
		url: '/inform/inform/page',
		method: 'get',
		params: query
	})
}

// 获取未读消息数量
export function unreadNum(query) {
	return request({
		url: '/inform/inform/getUnreadNum',
		method: 'get',
		params: query
	})
}