<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="用户全名" prop="name">
								<a-input v-model="queryParam.name" placeholder="请输入客户名称" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="手机号" prop="phone">
								<a-input v-model="queryParam.phone" placeholder="请输入手机号" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="工作单位" prop="work">
								<a-input v-model="queryParam.work" placeholder="请输入工作单位" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="省份" prop="province">
								<a-input v-model="queryParam.province" placeholder="请输入省份" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="市区" prop="area">
								<a-input v-model="queryParam.area" placeholder="请输入市区" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="创建时间" prop="beginTime">
								<a-date-picker @change="onChange" v-model="queryParam.createTime" style="width: 100%;" />
								<!-- <a-input v-model="queryParam.rosterId" placeholder="请输入地区名称" allow-clear /> -->
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 8 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<a-button type="danger" @click="handleDelete(selectedRowKeys)" :disabled="multiple"
					v-hasPermi="['drawing:drawing:remove']">批量删除</a-button>
				<!-- <table-setting :style="{float: 'right'}" :table-size.sync="tableSize" v-model="columns"
					:refresh-loading="loading" @refresh="getList" /> -->
			</div>
			<!-- 增加修改 -->
			<create-form ref="createForm" @ok="getList" />
			<!-- 批量删除 -->
			<a-modal title="删除订单" :visible="deleteVisible" :confirm-loading="confirmLoadings" @ok="handleOk"
				@cancel="deleteVisible=false">
				<div>是否确认当前操作？</div>
			</a-modal>
			<!-- 数据展示 -->
			<a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
				:bordered="tableBordered">
				<span slot="createTime" slot-scope="text, record">
					{{ parseTime(record.createTime) }}
				</span>
				<span slot="operation" slot-scope="text, record">
					<a-divider type="vertical" v-hasPermi="['drawing:drawing:drawMoney']" />
					<a @click="$refs.createForm.opencard(record, undefined)" v-hasPermi="['drawing:drawing:drawMoney']">提款</a>
					<a-divider type="vertical" v-hasPermi="['drawing:drawing:remove']" />
					<a @click="handleDelete(record)" v-hasPermi="['drawing:drawing:remove']">删除</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		getrosterMy,
		listCustomer,
		delCustomer
	} from '@/api/drawing/customer'
	import CreateForm from './modules/CreateForm'
	import {
		tableMixin
	} from '@/store/table-mixin'

	export default {
		name: 'Customer',
		components: {
			CreateForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [{
					name: '客户名称',
					phone: '19237130942',
					work: '这是地址',
					area: '真州市',
					updateTime: '2023-23-23'
				}],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					name: null,
					phone: null,
					work: null,
					area: null,
					province: null,
					createTime: null,
					userId: null,
					rosterId: null,
					pageNum: 1,
					pageSize: 10
				},
				columns: [{
						title: '序号',
						ellipsis: true,
						width: 50,
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '所属团队',
						dataIndex: 'regionName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '所属坐席',
						dataIndex: 'userName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '客户名称',
						dataIndex: 'name',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '手机号',
						dataIndex: 'phone',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '工作单位',
						dataIndex: 'work',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '市区',
						dataIndex: 'area',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '省份',
						dataIndex: 'province',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '创建时间',
						dataIndex: 'createTime',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'operation',
						width: '18%',
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				deleteVisible: false,
				confirmLoadings: false,
			}
		},
		filters: {},
		created() {
			this.getList()
		},
		computed: {},
		watch: {},
		methods: {
			// 选择时间
			onChange(e) {
				this.queryParam.createTime = e.format('YYYY-MM-DD hh:mm:ss');
				console.log(this.queryParam.beginTime)
			},
			// 确认提交新名单公海
			handleOk() {
				if (!this.reason) {
					this.$message.error('请输入转入原因');
					return false;
				}
				let data = {
					idList: this.selectList,
					reason: this.reason,
					type: 3
				}
				this.confirmLoadings = true;
				batchHighSeas(data).then(res => {
					console.log('转入新名单公海', res);
					this.confirmLoadings = false;
				})
			},
			/** 查询提款客户列表 */
			getList() {
				this.loading = true
				getrosterMy(this.queryParam).then(response => {
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},

			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					name: null,
					phone: null,
					work: null,
					area: null,
					province: null,
					createTime: null,
					userId: null,
					rosterId: null,
					pageNum: 1,
					pageSize: 10
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中' + this.ids.length + '条数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return delCustomer(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('drawing/drawing-customer/export', {
							...that.queryParam
						}, `提款客户_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			}
		}
	}
</script>