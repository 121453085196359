<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="客户名称" prop="name">
								<a-input v-model="queryParam.name" placeholder="请输入客户名称" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="手机号" prop="phone">
								<a-input v-model="queryParam.phone" placeholder="请输入手机号" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="工作单位" prop="work">
								<a-input v-model="queryParam.work" placeholder="请输入工作单位" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="省份" prop="province">
								<a-input v-model="queryParam.province" placeholder="请输入省份" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="市区" prop="area">
								<a-input v-model="queryParam.area" placeholder="请输入市区" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="创建时间" prop="beginTime">
								<a-date-picker @change="onChange" style="width: 100%;" placeholder="请选择创建时间" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 6 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<a-button type="danger" :disabled="multiple" @click="handleDelete(selectedRowKeys)"
					v-hasPermi="['roster:roster:add']">批量删除</a-button>
				<a-button type="primary" :disabled="multiple" @click="$refs.TransferForm.handleAdd(selectedRowKeys)"
					v-hasPermi="['roster:roster:add']">批量转派</a-button>
				<a-button type="danger" @click="handleDeleteAll"
					v-hasPermi="['roster:IntentionLibraryHighSeas:removeAll']">一键删除</a-button>
				<!-- <table-setting :style="{float: 'right'}" :table-size.sync="tableSize" v-model="columns"
					:refresh-loading="loading" @refresh="getList" /> -->
			</div>
			<!-- 增加修改 -->
			<create-form ref="createForm" @ok="getList" />
			<!-- 转派 -->
			<transfer-form ref="TransferForm" @ok="getList"></transfer-form>
			<!-- 批量转派 -->
			<a-modal title="转入新名单公海" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
				@cancel="visible=false">
				<a-textarea v-model="reason" placeholder="请输入转入原因" :auto-size="{ minRows: 6, maxRows: 6 }" />
			</a-modal>
			<a-modal title="删除订单" :visible="deleteVisible" :confirm-loading="confirmLoadings" @ok="handleOk"
				@cancel="deleteVisible=false">
				<div>是否确认当前操作？</div>
			</a-modal>
			<!-- 数据展示 -->
			<a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
				:bordered="tableBordered">
				<span slot="lastCallTime" slot-scope="text, record">
					{{ parseTime(record.lastCallTime) }}
				</span>
				<span slot="followUpTime" slot-scope="text, record">
					{{ parseTime(record.followUpTime) }}
				</span>
				<span slot="operation" slot-scope="text, record">
					<a-divider type="vertical" v-hasPermi="['roster:IntentionLibraryHighSeas:edit']" />
					<a @click="$refs.createForm.handleLook(record, undefined)"
						v-hasPermi="['roster:IntentionLibraryHighSeas:edit']">编辑</a>
					<a-divider type="vertical" v-hasPermi="['roster:IntentionLibraryHighSeas:remove']" />
					<a @click="handleDelete(record)" v-hasPermi="['roster:IntentionLibraryHighSeas:remove']">删除</a>
					<a-divider type="vertical" v-hasPermi="['roster:IntentionLibraryHighSeas:remove']" />
					<a @click="$refs.TransferForm.handleAdd(record.id, undefined)"
						v-hasPermi="['roster:IntentionLibraryHighSeas:transfer']">转派</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" :pageSizeOptions="pageSizeOptions" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		getrosterMy,
		listRoster,
		delRoster,
		receiveRoster,
		rosterMydeleteAll
	} from '@/api/roster/roster'
	import CreateForm from './modules/CreateForm'
	import TransferForm from './modules/TransferForm'
	import {
		tableMixin
	} from '@/store/table-mixin'

	export default {
		name: 'Roster',
		components: {
			CreateForm,
			TransferForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					type: 9,
					name: null,
					phone: null,
					work: null,
					area: null,
					province: null,
					beginTime: null,
					pageNum: 1,
					pageSize: 10
				},
				columns: [{
						title: '序号',
						ellipsis: true,
						width: 60,
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '转入前团队',
						dataIndex: 'regionName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '转入前坐席',
						dataIndex: 'userName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '客户名称',
						dataIndex: 'name',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '手机号',
						dataIndex: 'phone',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '工作单位',
						dataIndex: 'work',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '市区',
						width: 200,
						dataIndex: 'area',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '省份',
						width: 200,
						dataIndex: 'province',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '近一年开票金额(万)',
						dataIndex: 'invoiceAmount',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '近一年纳税金额(万)',
						width: 200,
						dataIndex: 'taxAmount',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '转入原因',
						dataIndex: 'reason',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '转入时间',
						dataIndex: 'transferTime',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'operation',
						width: 200,
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				selectList: {}, //选择元素
				confirmLoading: false,
				visible: false,
				deleteVisible: false,
				confirmLoadings: false,
				reason: '',
				pageSizeOptions: ['10', '50', '80', '100', '200', '400', '600']
			}
		},
		filters: {},
		created() {
			this.getList()
		},
		computed: {},
		watch: {},
		methods: {
			// 创建时间
			onChange(e) {
				this.queryParam.beginTime = e.format('YYYY-MM-DD hh:mm:ss')
			},
			// 转入公海弹窗
			handleToChange(ids) {
				let idList = [];
				if (typeof ids == 'string') {
					// 字符串
					idList = [{
						id: ids
					}]
				} else if (Array.isArray(ids)) {
					// 数组
					ids.map(item => {
						idList.push({
							id: item
						})
					})
				}
				this.visible = true;
				this.selectList = idList;
			},
			// 确认提交新名单公海
			handleOk() {
				if (!this.reason) {
					this.$message.error('请输入转入原因');
					return false;
				}
				let data = {
					idList: this.selectList,
					reason: this.reason,
					type: 3
				}
				this.confirmLoading = true;
				batchHighSeas(data).then(res => {
					console.log('转入新名单公海', res);
					this.confirmLoading = false;
				})
			},
			/** 查询新名单库列表 */
			getList() {
				this.loading = true
				getrosterMy(this.queryParam).then(response => {
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},

			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					type: 9,
					name: null,
					phone: null,
					work: null,
					area: null,
					province: null,
					beginTime: null,
					pageNum: 1,
					pageSize: 10
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return delRoster(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
					}
				})
			},
			// 一键删除所有
			handleDeleteAll() {
				var that = this
				this.$confirm({
					title: '提示',
					content: '是否删除当前所有数据',
					onOk() {
						return rosterMydeleteAll({
								type: 9
							})
							.then(() => {
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('roster/roster/export', {
							...that.queryParam
						}, `新名单库_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			},
			/** 领取按钮操作 */
			handleReceive() {
				var that = this
				this.$confirm({
					title: '是否确认领取?',
					//content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						return receiveRoster()
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'领取成功',
									3
								)
							})
					},
					onCancel() {}
				})
			}
		}
	}
</script>