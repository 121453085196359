import request from '@/utils/request'


// 查询报销类型列表
export function listType(query) {
  return request({
    url: '/reimbursement/reimbursement-type/list',
    method: 'get',
    params: query
  })
}

// 查询报销类型分页
export function pageType(query) {
  return request({
    url: '/reimbursement/reimbursement-type/page',
    method: 'get',
    params: query
  })
}

// 查询报销类型详细
export function getType(data) {
  return request({
    url: '/reimbursement/reimbursement-type/detail',
    method: 'get',
    params: data
  })
}

// 新增报销类型
export function addType(data) {
  return request({
    url: '/reimbursement/reimbursement-type/add',
    method: 'post',
    data: data
  })
}

// 修改报销类型
export function updateType(data) {
  return request({
    url: '/reimbursement/reimbursement-type/edit',
    method: 'post',
    data: data
  })
}

// 删除报销类型
export function delType(data) {
  return request({
    url: '/reimbursement/reimbursement-type/delete',
    method: 'post',
    data: data
  })
}
