import request from '@/utils/request'


// 查询审批列表
export function listApproval(query) {
	return request({
		url: '/approval/approval/list',
		method: 'get',
		params: query
	})
}

// 查询审批分页
export function pageApproval(query) {
	return request({
		url: '/approval/approval/page',
		method: 'get',
		params: query
	})
}

// 查询审批详细
export function getApproval(data) {
	return request({
		url: '/approval/approval/detail',
		method: 'get',
		params: data
	})
}

// 新增审批
export function addApproval(data) {
	return request({
		url: '/approval/approval/add',
		method: 'post',
		data: data
	})
}

// 修改审批
export function updateApproval(data) {
	return request({
		url: '/approval/approval/edit',
		method: 'post',
		data: data
	})
}

// 删除审批
export function delApproval(data) {
	return request({
		url: '/approval/approval/delete',
		method: 'post',
		data: data
	})
}

// 编辑审批管理
export function editApproval(data) {
	return request({
		url: '/approval/approval-manage/edit',
		method: 'post',
		data: data
	})
}


// 编辑审批管理
export function batchPass(data) {
	return request({
		url: '/approval/approval-manage/batchPass',
		method: 'post',
		data: data
	})
}

// 编辑审批管理
export function approvalDetail(data) {
	return request({
		url: '/approval/approval-manage/detail',
		method: 'get',
		params: data
	})
}

// 编辑审批管理
export function approvalPage(data) {
	return request({
		url: '/approval/approval-manage/page',
		method: 'get',
		params: data
	})
}

// 获取审批列表
export function managelist(data) {
	return request({
		url: '/approval/approval-manage/list',
		method: 'get',
		params: data
	})
}