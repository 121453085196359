<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="所属区域" prop="areaDetpId">
								<a-select v-model="queryParam.areaDetpId" @change="handleChange" placeholder="请选择所属区域" allow-clear>
									<a-select-option :value="region.id" v-for="(region,index) in regionLists" :key="index">
										{{region.deptName}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="所属团队" prop="teamDetpId">
								<a-select v-model="queryParam.teamDetpId" @change="handleChangeTeam" placeholder="请选择团队" allow-clear>
									<a-select-option :value="team.id" v-for="(team,index) in teamList" :key="index">
										{{team.deptName}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="所属坐席" prop="userId">
								<a-select v-model="queryParam.userId" placeholder="所属坐席" allow-clear>
									<a-select-option :value="service.id" v-for="(service,index) in serviceList" :key="index">
										{{service.nickName}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="统计时间" prop="time">
								<a-date-picker @change="getSelectTime" v-model="queryParam.time" placeholder="统计时间"
									allow-clear></a-date-picker>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="银行" prop="userId">
								<a-select v-model="queryParam.bankdataId" placeholder="请选择银行" allow-clear>
									<a-select-option :value="bank.id" v-for="(bank,index) in bankList" :key="index">
										{{bank.name}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 6 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<a-button type="primary" @click="handleExport" v-hasPermi="['report:sales:export']">导出</a-button>
				<!-- <table-setting :style="{float: 'right'}" :table-size.sync="tableSize" v-model="columns"
					:refresh-loading="loading" @refresh="getList" /> -->
			</div>
			<!-- 增加修改 -->
			<create-form ref="createForm" @ok="getList" />
			<!-- 数据展示 -->
			<!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
			<a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:pagination="false" :bordered="tableBordered" :scroll="{ x: '1000px' }">
				<span slot="createTime" slot-scope="text, record">
					{{ parseTime(record.createTime) }}
				</span>
				<span slot="operation" slot-scope="text, record">
					<a-divider type="vertical" v-hasPermi="['report:sales:edit']" />
					<a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['report:sales:edit']">
						<a-icon type="edit" />修改
					</a>
					<a-divider type="vertical" v-hasPermi="['report:sales:remove']" />
					<a @click="handleDelete(record)" v-hasPermi="['report:sales:remove']">
						<a-icon type="delete" />删除
					</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		pageSales,
		listSales,
		delSales,
		bankdata,
		regionList,
		areaTeam,
		ByDeptIds,
		getTeamList
	} from '@/api/report/sales'
	import CreateForm from './modules/CreateForm'
	import {
		tableMixin
	} from '@/store/table-mixin'

	export default {
		name: 'Sales',
		components: {
			CreateForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					areaDetpId: undefined,
					teamDetpId: undefined,
					userId: undefined,
					time: null,
					bankdataId: undefined,
					pageNum: 1,
					pageSize: 10
				},
				columns: [{
						title: '所属团队',
						dataIndex: 'deptName',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '所属坐席',
						width: 200,
						dataIndex: 'userName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '当日千万意向',
						width: 150,
						dataIndex: 'millionsOfIntentions',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '当日千万转介绍',
						width: 150,
						dataIndex: 'millionsOfIntroduction',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '当日非千万意向',
						width: 150,
						dataIndex: 'notMillionsOfIntentions',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '当日非千万转介绍',
						width: 150,
						dataIndex: 'notMillionsOfIntroduction',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '当日千万申请',
						width: 150,
						dataIndex: 'millionsOfApplication',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '当日非千万申请',
						width: 150,
						dataIndex: 'notMillionsOfApplication',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '当日核额个数',
						width: 150,
						dataIndex: 'auditNum',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '当日核额量(万)',
						width: 150,
						dataIndex: 'auditMoney',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '日转化率',
						width: 100,
						dataIndex: 'conversionRate',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '月千万意向',
						width: 150,
						dataIndex: 'monthMillionsOfIntentions',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '月千万转介绍',
						width: 150,
						dataIndex: 'monthMillionsOfIntroduction',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '月非千万意向',
						width: 150,
						dataIndex: 'monthNotMillionsOfIntentions',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '月非千万转介绍',
						width: 150,
						dataIndex: 'monthNotMillionsOfIntroduction',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '月千万申请',
						width: 150,
						dataIndex: 'monthMillionsOfApplication',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '月非千万申请',
						width: 150,
						dataIndex: 'monthNotMillionsOfApplication',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '月核额个数',
						width: 150,
						dataIndex: 'monthAuditNum',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '月核额量',
						width: 150,
						dataIndex: 'monthAuditMoney',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '月转化率',
						width: 150,
						dataIndex: 'monthConversionRate',
						ellipsis: true,
						align: 'center'
					},
				],
				bankList: [], //银行列表
				regionLists: [], //区域列表
				teamList: [],
				serviceList: [], //
			}
		},
		filters: {},
		created() {
			this.getList();
			this.getbankdata()
			this.getRegionList()
		},
		computed: {},
		watch: {},
		methods: {
			// 选择统计时间
			getSelectTime(e) {
				this.queryParam.time = e.format('YYYY-MM-DD')
			},
			// 区域列表
			getRegionList() {
				regionList().then(res => {
					if (res.success) {
						console.log('区域列表', res);
						this.regionLists = res.data;
					}
				})
			},
			// 银行列表
			getbankdata() {
				bankdata().then(res => {
					if (res.success) {
						console.log('银行列表', res);
						this.bankList = res.data;
					}
				})
			},
			getAreaTeam(e) {
				getTeamList({
					pid: e
				}).then(res => {
					if (res.success) {
						console.log('团队列表', res);
						this.teamList = res.data;
					}
				})
			},
			getByDeptIds(e) {
				ByDeptIds({
					deptId: e,
					roleCode: 'kf'
				}).then(res => {
					console.log('人员列表', res);
					this.serviceList = res.data;
				})
			},
			handleChange(e) {
				this.queryParam.areaDetpId = e;
				this.getAreaTeam(e);
			},
			handleChangeTeam(e) {
				this.queryParam.teamDetpId = e;
				this.getByDeptIds(e)
			},
			/** 查询销售报列表 */
			getList() {
				this.loading = true
				pageSales(this.queryParam).then(response => {
					console.log('报表列表', response);
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},

			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					time: undefined,
					userId: undefined,
					millionsOfIntentions: undefined,
					millionsOfIntroduction: undefined,
					notMillionsOfIntentions: undefined,
					notMillionsOfIntroduction: undefined,
					millionsOfApplication: undefined,
					notMillionsOfApplication: undefined,
					auditNum: undefined,
					auditMoney: undefined,
					pageNum: 1,
					pageSize: 10
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return delSales(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
						that.ids = []
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('report/report-sales/export', {
							...that.queryParam
						}, `销售报_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			}
		}
	}
</script>