import request from '@/utils/request'


// 查询我的新名单库列表
export function listMy(query) {
  return request({
    url: '/roster/rosterMy/list',
    method: 'get',
    params: query
  })
}

// 查询我的新名单库分页
export function pageMy(query) {
  return request({
    url: '/roster/rosterMy/page',
    method: 'get',
    params: query
  })
}

// 查询我的新名单库详细
export function getMy(data) {
  return request({
    url: '/roster/rosterMy/detail',
    method: 'get',
    params: data
  })
}

// 新增我的新名单库
export function addMy(data) {
  return request({
    url: '/roster/rosterMy/add',
    method: 'post',
    data: data
  })
}

// 修改我的新名单库
export function updateMy(data) {
  return request({
    url: '/roster/rosterMy/edit',
    method: 'post',
    data: data
  })
}

// 删除我的新名单库
export function delMy(data) {
  return request({
    url: '/roster/rosterMy/delete',
    method: 'post',
    data: data
  })
}
