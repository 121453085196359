<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="员工id,外键{sys_user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入员工id,外键{sys_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="核额记录id,外键{zb_audit_amount.id}" prop="auditAmountId" >
        <a-input v-model="form.auditAmountId" placeholder="请输入核额记录id,外键{zb_audit_amount.id}" />
      </a-form-model-item>
      <a-form-model-item label="提款日期" prop="drawingTime" >
      </a-form-model-item>
      <a-form-model-item label="提款金额" prop="drawingMoney" >
        <a-input v-model="form.drawingMoney" placeholder="请输入提款金额" />
      </a-form-model-item>
      <a-form-model-item label="提款影像,多个使用逗号隔开" prop="drawingImg" >
        <a-input v-model="form.drawingImg" placeholder="请输入提款影像,多个使用逗号隔开" />
      </a-form-model-item>
      <a-form-model-item label="还款日期" prop="repaymentTime" >
      </a-form-model-item>
      <a-form-model-item label="还款金额" prop="repaymentMoney" >
        <a-input v-model="form.repaymentMoney" placeholder="请输入还款金额" />
      </a-form-model-item>
      <a-form-model-item label="还款影像,多个使用逗号隔开" prop="repaymentImg" >
        <a-input v-model="form.repaymentImg" placeholder="请输入还款影像,多个使用逗号隔开" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDrawing, addDrawing, updateDrawing } from '@/api/drawing/drawing'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        auditAmountId: null,

        drawingTime: null,

        drawingMoney: null,

        drawingImg: null,

        repaymentTime: null,

        repaymentMoney: null,

        repaymentImg: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '员工id,外键{sys_user.id}不能为空', trigger: 'blur' }
        ],
        auditAmountId: [
          { required: true, message: '核额记录id,外键{zb_audit_amount.id}不能为空', trigger: 'blur' }
        ],
        drawingMoney: [
          { required: true, message: '提款金额不能为空', trigger: 'blur' }
        ],
        drawingImg: [
          { required: true, message: '提款影像,多个使用逗号隔开不能为空', trigger: 'blur' }
        ],
        repaymentMoney: [
          { required: true, message: '还款金额不能为空', trigger: 'blur' }
        ],
        repaymentImg: [
          { required: true, message: '还款影像,多个使用逗号隔开不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        auditAmountId: null,
        drawingTime: null,
        drawingMoney: null,
        drawingImg: null,
        repaymentTime: null,
        repaymentMoney: null,
        repaymentImg: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDrawing({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDrawing(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDrawing(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
