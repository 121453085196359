import request from '@/utils/request'


// 查询提款明细列表
export function listDrawing(query) {
  return request({
    url: '/drawing/drawing/list',
    method: 'get',
    params: query
  })
}

// 查询提款明细分页
export function pageDrawing(query) {
  return request({
    url: '/drawing/drawing/page',
    method: 'get',
    params: query
  })
}

// 查询提款明细详细
export function getDrawing(data) {
  return request({
    url: '/drawing/drawing/detail',
    method: 'get',
    params: data
  })
}

// 新增提款明细
export function addDrawing(data) {
  return request({
    url: '/drawing/drawing/add',
    method: 'post',
    data: data
  })
}

// 修改提款明细
export function updateDrawing(data) {
  return request({
    url: '/drawing/drawing/edit',
    method: 'post',
    data: data
  })
}

// 删除提款明细
export function delDrawing(data) {
  return request({
    url: '/drawing/drawing/delete',
    method: 'post',
    data: data
  })
}
