<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="所属区域" prop="name">
								<a-select v-model="queryParam.areaId" @change="handleChange" placeholder="请选择所属区域" allow-clear>
									<a-select-option :value="region.id" v-for="(region,index) in regionLists" :key="index">
										{{region.deptName}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="所属团队" prop="name">
								<a-select v-model="queryParam.teamId" @change="handleChangeTeam" placeholder="请选择团队">
									<a-select-option :value="team.id" v-for="(team,index) in teamList" :key="index">
										{{team.deptName}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="所属坐席" prop="name">
								<a-select placeholder="请选择客户来源" v-model="queryParam.userId" allow-clear>
									<a-select-option :value="item.id" v-for="(item,index) in salesList"
										:key="index">{{item.nickName}}</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<!-- <a-col :md="6" :sm="24">
							<a-form-item label="客户名称" prop="name">
								<a-input v-model="queryParam.name" placeholder="请输入客户名称" allow-clear />
							</a-form-item>
						</a-col> -->
						<!-- <a-col :md="6" :sm="24">
							<a-form-item label="手机号" prop="phone">
								<a-input v-model="queryParam.phone" placeholder="请输入手机号" allow-clear />
							</a-form-item>
						</a-col> -->
						<!-- <a-col :md="6" :sm="24">
							<a-form-item label="操作时间" prop="createBeginTime">
								<a-range-picker @change="onChangeTimes" v-model="selectTimes" allow-clear />
							</a-form-item>
						</a-col> -->
						<!-- <a-col :md="6" :sm="24">
							<a-form-item label="是否接通" prop="callStatus">
								<a-select ref="select" v-model="queryParam.callStatus" placeholder="请选择" allow-clear>
									<a-select-option value="1">是</a-select-option>
									<a-select-option value="0">否</a-select-option>
								</a-select>
							</a-form-item>
						</a-col> -->
						<!-- <a-col :md="6" :sm="24">
							<a-form-item label="通话时长排序" prop="timeSort">
								<a-select ref="select" v-model="queryParam.timeSort" placeholder="请选择" allow-clear>
									<a-select-option value="0">升序</a-select-option>
									<a-select-option value="1">降序</a-select-option>
								</a-select>
							</a-form-item>
						</a-col> -->
						<!-- <a-col :md="6" :sm="24">
							<a-form-item label="拨打时长" prop="timeSort">
								<a-input v-model="queryParam.minCallTime" type="number" placeholder="请输入" style="width:30%"></a-input>
								<span style="margin: 0 20px;">----</span>
								<a-input v-model="queryParam.maxCallTime" type="number" placeholder="请输入" style="width:30%"></a-input>
							</a-form-item>
						</a-col> -->
						<a-col :md="!advanced && 6 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<a-button type="danger" :disabled="multiple" @click="handleDelete"
					v-hasPermi="['call:record:remove']">批量删除</a-button>
				<!-- <table-setting :style="{float: 'right'}" :table-size.sync="tableSize" v-model="columns"
					:refresh-loading="loading" @refresh="getList" /> -->
			</div>
			<!-- 增加修改 -->
			<create-form ref="createForm" @ok="getList" />
			<a-modal v-model="audioVisible" title="录音播放" cancelText="关闭" @cancel="closeAudio">
				<audio ref="audioPlayer" style="width: 100%;" :src="tapeFile" controls autoplay></audio>
				<template slot="footer">
					<a-button @click="closeAudio">关闭</a-button>
				</template>
			</a-modal>
			<!-- 数据展示 -->
			<a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
				:bordered="tableBordered">
				<span slot="recordingTime" slot-scope="text, record">
					{{ parseTime(record.recordingTime) }}
				</span>
				<span slot="ossUrl" slot-scope="text, record">
					<a v-if="record.ossUrl" @click="handlePlay(record.ossUrl,1)">播放</a>
					<a-divider type="vertical" v-if="record.ossUrl" />
					<a v-if="record.ossUrl" @click="handlePlay(record.ossUrl,2)">2倍速播放</a>
					<a-divider type="vertical" v-if="record.ossUrl" />
					<a :href="record.ossUrl" download="音频" v-if="record.ossUrl">下载</a>
				</span>
				<span slot="operation" slot-scope="text, record">
					<a-divider type="vertical" v-hasPermi="['call:record:edit']" />
					<!-- <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['call:record:edit']">
						<a-icon type="edit" />修改
					</a> -->
					<a-divider type="vertical" />
					<a @click="handleDelete(record)">删除</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" :pageSizeOptions="pageSizeOptions" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		pageRecord,
		listRecord,
		delRecord,
		delNewRecord,
		regionList,
		areaTeam,
		getByDeptIds,
		getTeamList,
		recordingRecord
	} from '@/api/call/record'
	import CreateForm from './modules/CreateForm'
	import {
		tableMixin
	} from '@/store/table-mixin'

	export default {
		name: 'Record',
		components: {
			CreateForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					name: null,
					phone: null,
					createBeginTime: null,
					createEndTime: null,
					areaId: undefined,
					teamId: undefined,
					userId: undefined,
					pageNum: 1,
					pageSize: 10,
					callStatus: undefined, //是否接通,0-否,1-是
					maxCallTime: null, //最大通话时长
					minCallTime: null, //最小通话时长
					timeSort: undefined, //通话时长排序, 0-升序, 1-降序 
				},
				columns: [{
						title: '序号',
						width: 70,
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '所属团队',
						dataIndex: 'teamName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '所属坐席',
						dataIndex: 'callerName',
						ellipsis: true,
						align: 'center'
					},
					// {
					// 	title: '客户名称',
					// 	dataIndex: 'name',
					// 	ellipsis: true,
					// 	align: 'center'
					// },
					{
						title: '手机号',
						dataIndex: 'calledNumber',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '录音文件地址',
						dataIndex: 'ossUrl',
						ellipsis: true,
						width: 200,
						align: 'center',
						scopedSlots: {
							customRender: 'ossUrl'
						},
					},
					// {
					// 	title: '时长(秒)',
					// 	dataIndex: 'duration',
					// 	ellipsis: true,
					// 	align: 'center'
					// },
					// {
					// 	title: '操作人',
					// 	dataIndex: 'callerName',
					// 	ellipsis: true,
					// 	align: 'center'
					// },
					{
						title: '插入时间',
						dataIndex: 'recordingTime',
						scopedSlots: {
							customRender: 'recordingTime'
						},
						ellipsis: false,
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'operation',
						width: '18%',
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				selectTimes: undefined,
				regionLists: [], //区域列表
				teamList: [],
				salesList: [],
				audioVisible: false,
				tapeFile: '',
				pageSizeOptions: ['10', '50', '80', '100', '200', '400', '600']
			}
		},
		filters: {},
		created() {
			this.getList()
			this.getRegionList()
		},
		computed: {},
		watch: {},
		methods: {
			// 区域列表
			getRegionList() {
				regionList().then(res => {
					if (res.success) {
						console.log('区域列表', res);
						this.regionLists = res.data;
						this.getAreaTeam()
					}
				})
			},
			getAreaTeam(id) {
				getTeamList({
					pId: id
				}).then(res => {
					if (res.success) {
						console.log('团队列表', res);
						this.teamList = res.data;
					}
				})
			},
			handleChange(e) {
				// this.queryParam.areaDetpId = e;
				this.queryParam.teamId = '';
				this.getAreaTeam(e);
			},
			handleChangeTeam(e) {
				this.queryParam.teamId = e;
				this.getSaleList(e)
			},
			// 销售列表
			getSaleList(id) {
				getByDeptIds({
					deptId: id,
					roleCode: ''
				}).then(res => {
					if (res.success) {
						console.log('人员列表', res);
						this.salesList = res.data;
					}
				})
			},
			// 音频播放
			handlePlay(url, num) {
				console.log(url);
				this.tapeFile = url;
				this.audioVisible = true;
				this.$nextTick(() => {
					this.$refs.audioPlayer.playbackRate = num;
				})
			},
			// 关闭播放弹窗
			closeAudio() {
				this.$refs.audioPlayer.pause();
				this.audioVisible = false;
			},
			// 选择统计时间
			onChangeTimes(e) {
				this.queryParam.createBeginTime = e[0].format('YYYY-MM-DD') + ' 00:00:00'
				this.queryParam.createEndTime = e[1].format('YYYY-MM-DD') + ' 23:59:59'
			},
			/** 查询呼叫记录列表 */
			getList() {
				this.loading = true
				recordingRecord(this.queryParam).then(response => {
					console.log('新外呼管理记录', response)
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},

			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					name: null,
					phone: null,
					createBeginTime: null,
					createEndTime: null,
					areaId: undefined,
					teamId: undefined,
					userId: undefined,
					pageNum: 1,
					pageSize: 10,
					callStatus: undefined, //是否接通,0-否,1-是
					maxCallTime: null, //最大通话时长
					minCallTime: null, //最小通话时长
					timeSort: undefined, //通话时长排序, 0-升序, 1-降序 
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return delNewRecord(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('call/call-record/export', {
							...that.queryParam
						}, `呼叫记录_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			}
		}
	}
</script>