<template>
	<a-drawer width="35%" title="审批详情" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
		<div class="approval">
			<div class="approval-user">
				<h2>{{record.userName}}提交的报销审批 <a-button type="primary"
						size="small">{{filterStatis(record.status,1)}}</a-button> </h2>
				<a-row type="flex" :gutter="20">
					<a-col style="margin-bottom: 15px;" :span="12" :order="3">审批编号：{{record.id}}</a-col>
					<a-col style="margin-bottom: 15px;" :span="12" :order="3">提交时间：{{record.createTime}}</a-col>
					<a-col style="margin-bottom: 15px;" :span="12" :order="3">提交人：{{record.userName}}</a-col>
					<a-col style="margin-bottom: 15px;" :span="12" :order="3">所属部门：{{record.deptName}}</a-col>
				</a-row>
				<h4>审批详情</h4>
				<a-row type="flex" :gutter="20">
					<a-col style="margin-bottom: 15px;" :span="12" :order="3">报销类别：{{record.reimbursementTypeName}}</a-col>
					<a-col style="margin-bottom: 15px;" :span="12" :order="3">上传影像：<img v-for="(item,index) in record.img"
							:src="item.url" alt="" class="imgs"> </a-col>
					<a-col :span="12" :order="3">备注：{{record.notes}}</a-col>
					<a-col :span="12" :order="3">审批状态：{{filterStatis(record.status,1)}}</a-col>
				</a-row>
			</div>
			<div class="approval-history">
				<h2>审批记录</h2>
				<div class="list">
					<div class="item" v-for="(item,index) in list">
						<div class="item-radio">
							<div :class="filterStatis(item.status,2)"></div>
							<div :class="filterStatis(item.status,2)"></div>
						</div>
						<div class="item-info">
							<div><span>{{index==0?'发起人':'审批节点'}}</span> {{item.userName}} <span
									v-if="index==0">{{record.createTime}}</span></div>
							<a-button size="small" :class="filterStatis(item.status,2)">{{filterStatis(item.status,3)}}</a-button>
						</div>
					</div>
					<div class="item">
						<div class="item-radio">
							<div :class="filterStatis(0,2)"></div>
						</div>
						<div class="item-info">
							<div>审批结束</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="bottom-control">
			<a-space>
				<a-button type="primary" :loading="submitLoading" @click="handleOk(2)">
					同意
				</a-button>
				<a-button type="dashed" @click="handleOk(3)">
					驳回
				</a-button>
			</a-space>
		</div> -->

		<a-modal :title="modelTitle" :visible="agreeModel" :confirm-loading="confirmLoading" @ok="handleModel"
			@cancel="handleCancelModel">
			<a-textarea v-model="reason" placeholder="请输入(选填)" :auto-size="{ minRows: 3, maxRows: 5 }" />
		</a-modal>

	</a-drawer>
</template>

<script>
	import {
		getApproval,
		addApproval,
		updateApproval,
		editApproval,
		approvalDetail,
		managelist
	} from '@/api/approval/approval'

	export default {
		name: 'LookModel',
		props: {},
		components: {},
		data() {
			return {
				submitLoading: false,
				formTitle: '',
				// 表单参数
				form: {
					id: null,
					userId: null,
					reimbursementTypeId: null,
					reimbursementTime: null,
					notes: null,
					img: null,
					status: 0,
					reason: null
				},
				// 1增加,2修改
				record: {},
				formType: 1,
				open: false,
				agreeModel: false,
				confirmLoading: false,
				reason: '',
				modelTitle: '',
				status: '',
				list: {}
			}
		},
		filters: {},
		created() {},
		computed: {},
		watch: {},
		mounted() {},
		methods: {
			// 确定审批弹窗
			handleModel() {
				this.confirmLoading = true;
				editApproval({
					id: this.record.id,
					reason: this.reason,
					status: this.status
				}).then(res => {
					console.log('审核结果', res);
					if (res.success) {
						this.confirmLoading = false;
						this.reason = '';
						this.agreeModel = false;
						this.getapprovalDetail(this.record.id)
					}
				})
			},
			// 取消审批弹窗
			handleCancelModel() {
				this.reason = '';
				this.confirmLoading = false;
				this.agreeModel = false;
			},
			// 取消
			handleCancel() {
				this.agreeModel = false;
			},
			// 同意
			handleOk(status) {
				this.status = status;
				status == 2 ? this.modelTitle = '确定通过该申请吗？' : this.modelTitle = '确定拒绝该申请吗？';
				this.agreeModel = true;
			},

			onClose() {
				this.open = false;
				this.record = {};
				this.list = [];
			},
			// 取消按钮
			cancel() {
				this.open = false
				this.reset()
			},
			// 表单重置
			reset() {
				this.formType = 1
				this.form = {
					id: null,
					userId: null,
					reimbursementTypeId: null,
					reimbursementTime: null,
					notes: null,
					img: null,
					status: 0,
					reason: null
				}
				this.$nextTick(() => {
					if (this.$refs.form) {
						this.$refs.form.resetFields()
					}
				})
			},
			/** 新增按钮操作 */
			handleAdd(row) {
				console.log(row.img)
				if (row.img == '[]' || !row.img || row.img.length == 0) {
					row.img = [];
				} else {
					row.img = JSON.parse(row.img);
				}
				this.record = row;
				this.reset()
				this.formType = 1
				this.open = true
				this.formTitle = '添加'
				this.getapprovalDetail(row.id)
			},
			// 审核详情
			getapprovalDetail(id) {
				managelist({
					approvalId: id
				}).then(res => {
					if (res.success) {
						console.log('审核详情', res);
						res.data.unshift({
							userName: this.record.userName,
							createTime: this.record.createTime,
							status: 0,
						})
						this.list = res.data;
					}
				})
			},
			/** 修改按钮操作 */
			handleUpdate(row, ids) {
				this.reset()
				this.formType = 2
				const id = row ? row.id : ids
				getApproval({
					"id": id
				}).then(response => {
					this.form = response.data
					this.open = true
					this.formTitle = '修改'
				})
			},
			// 审批状态
			filterStatis(status, type) {
				// 0-待开始,1-待审批,2-已同意,3-已拒绝
				if (type == 1) {
					let arr = ['待开始', '待审批', '已同意', '已拒绝']
					return arr[status]
				}
				if (type == 2) {
					let classArr = ['grey', 'blue', 'green', 'red']
					return classArr[status]
				}
				if (type == 3) {
					let classArr = ['发起审批', '等待处理', '审核通过', '审核驳回']
					return classArr[status]
				}
			},
		}
	}
</script>

<style scoped>
	.approval {}

	h2 {
		font-weight: bold;
	}

	h4 {
		margin: 15px 0;
		font-weight: bold;
	}

	.approval-user {
		padding: 15px;
		border: 1px solid #eee;
		border-radius: 15px;
	}


	.approval-history {
		margin-top: 20px;
		padding: 15px;
		border: 1px solid #eee;
		border-radius: 15px;
	}

	.list {}

	.item {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.item-radio {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		padding-top: 5px;
	}

	.item-radio div:nth-child(1) {
		width: 10px;
		height: 10px;
		border-radius: 50%;
	}

	.item-radio div:nth-child(2) {
		height: 30px;
		width: 2px;
		margin: 10px 0;
	}

	.item-info {
		margin-left: 10px;
	}

	.item-info button {
		font-size: 12px;
		margin-top: 5px;
	}

	.imgs {
		width: 40px;
		height: 40px;
	}

	/* 0-待开始,1-待审批,2-已同意,3-已拒绝 */
	.grey {
		background: #eee;
		color: #000;
	}

	.blue {
		background: blue;
		color: #fff;
	}

	.green {
		background: seagreen;
		color: #fff;
	}

	.red {
		background: red;
		color: #fff;
	}
</style>