import storage from 'store'
import {
	login,
	loginByMobile,
	getInfo,
	logout
} from '@/api/login'
import {
	ACCESS_TOKEN
} from '@/store/mutation-types'
import {
	setToken,
	removeToken
} from '@/utils/auth'
import notification from 'ant-design-vue/es/notification'
import * as logistics from '@/api/logistics'

const user = {
	state: {
		token: '',
		name: '',
		welcome: '',
		avatar: '',
		roles: [],
		info: {},
		cityList: [],
		cityTotalNum: 0
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_NAME: (state, name) => {
			state.name = name
		},
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		SET_INFO: (state, info) => {
			state.info = info
		},
		SET_PERMISSIONS: (state, permissions) => {
			state.permissions = permissions
		},
		SET_CITYS: (state, info) => {
			state.cityList = info
		},
		SET_CITYSNUM: (state, info) => {
			state.cityTotalNum = info
		}
	},

	actions: {
		// 登录
		Login({
			commit
		}, userInfo) {
			const accountNo = userInfo.accountNo.trim()
			const password = userInfo.password
			const userType = 'ADMIN'
			const loginType = 'PASSWORD'
			const device = 'PC'
			const captchaVerification = userInfo.captchaVerification
			// const uuid = userInfo.uuid
			// device	设备:pc端：PC，app端：APP，小程序端：MINI
			return new Promise((resolve, reject) => {
				login(accountNo, password, captchaVerification, userType, loginType, device).then(res => {
					window.console.log('登录成功', res)
					if (res.success) {
						setToken(res.data.token)
						commit('SET_TOKEN', res)
						storage.set(ACCESS_TOKEN, res.data.token, 7 * 24 * 60 * 60 * 1000)
						// commit('SET_TOKEN', res.token)
						localStorage.setItem('SEATSNUM', res.data.userInfo.seatsNum)
						localStorage.setItem('roleCodes', res.data.userInfo.roleCodes)
						resolve()
					} else {
						notification.error({
							message: res.message,
							duration: 5 * 1000
						})
						reject(res.message)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 短信登录
		SmsLogin({
			commit
		}, userInfo) {
			console.log('userInfo:', userInfo)
			const mobile = userInfo.mobile.trim()
			const mobileCode = userInfo.code
			const validCodeReqNo = userInfo.validCodeReqNo
			const device = 'PC'
			return new Promise((resolve, reject) => {
				loginByMobile({
					phone: mobile,
					validCode: mobileCode,
					validCodeReqNo: validCodeReqNo,
					device
				}).then(res => {
					console.log('res0', res)
					if (res.success) {
						setToken(res.data.token)
						commit('SET_TOKEN', res)
						storage.set(ACCESS_TOKEN, res.data.token, 7 * 24 * 60 * 60 * 1000)
						resolve()
					} else {
						notification.error({
							message: res.message,
							duration: 5 * 1000
						})
						reject(res.message)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 获取用户信息
		GetInfo({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				getInfo(state.token).then(res => {
					const user = res.data
					const avatar = user.avatar === '' ? require('@/assets/images/profile.jpg') : user.avatar
					if (user.roleCodes && user.roleCodes.length > 0) { // 验证返回的roles是否是一个非空数组
						commit('SET_ROLES', user.roleCodes)
						commit('SET_PERMISSIONS', user.permissions)
					} else {
						commit('SET_ROLES', ['ROLE_DEFAULT'])
					}
					commit('SET_NAME', user.nickName)
					commit('SET_AVATAR', avatar)
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 登出
		Logout({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				logout(state.token).then(() => {
					resolve()
				}).catch(error => {
					reject(error)
				}).finally(() => {
					console.log('退出登录')
					commit('SET_TOKEN', '')
					commit('SET_ROLES', [])
					commit('SET_PERMISSIONS', [])
					storage.remove(ACCESS_TOKEN)
					removeToken()
				})
			})
		},
		// 获取运费模板 使用的省市区
		getCity({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				logistics.cityListTree().then((res) => {
					if (res.success) {
						/**为城市数组增加前端需要的isShow */
						let totalNum = 0
						if (res.data) {
							res.data.forEach((item, index) => {
								item.isShow = true;
								item.disabled = false
								item.cityNum = 0
								item.children.forEach((item1, index1) => {
									totalNum++
									item1.isShow = true;
									item1.disabled = false;
									item.cityNum += 1
								});
							});

							commit('SET_CITYS', res.data)
							commit('SET_CITYSNUM', totalNum)
						}
					}
				});
			})
		}
	}
}

export default user