import request from '@/utils/request'


// 查询提款客户列表
export function listCustomer(query) {
	return request({
		url: '/drawing/drawing-customer/list',
		method: 'get',
		params: query
	})
}

// 查询提款客户分页
export function pageCustomer(query) {
	return request({
		url: '/drawing/drawing-customer/page',
		method: 'get',
		params: query
	})
}

// 查询提款客户详细
export function getCustomer(data) {
	return request({
		url: '/drawing/drawing-customer/detail',
		method: 'get',
		params: data
	})
}

// 新增提款客户
export function addCustomer(data) {
	return request({
		url: '/drawing/drawing-customer/add',
		method: 'post',
		data: data
	})
}

// 修改提款客户
export function updateCustomer(data) {
	return request({
		url: '/drawing/drawing-customer/edit',
		method: 'post',
		data: data
	})
}

// 删除提款客户
export function delCustomer(data) {
	return request({
		url: '/drawing/drawing-customer/delete',
		method: 'post',
		data: data
	})
}

// 我的新名单客户
export function getrosterMy(data) {
	return request({
		url: '/drawing/drawing-customer/page',
		method: 'get',
		params: data
	})
}

// 查询核额记录分页
export function auditpage(data) {
	return request({
		url: '/audit/audit-amount/page',
		method: 'get',
		params: data
	})
}

// 查询核额记录分页
export function auditlist(data) {
	return request({
		url: '/audit/audit-amount/list',
		method: 'get',
		params: data
	})
}



// 查询核额记录分页
export function batchEdit(data) {
	return request({
		url: '/drawing/drawing/batchEdit',
		method: 'post',
		data: data
	})
}

// 查询核额记录分页
export function list(data) {
	return request({
		url: '/drawing/drawing/list',
		method: 'get',
		params: data
	})
}

// 查询审批分页
export function signature(data) {
	return request({
		url: '/common/get-oss-signature',
		method: 'get',
		params: data
	})
}