import request from '@/utils/request'


// 查询核额记录列表
export function listAmount(query) {
	return request({
		url: '/audit/audit-amount/list',
		method: 'get',
		params: query
	})
}

// 查询核额记录分页
export function pageAmount(query) {
	return request({
		url: '/audit/audit-amount/page',
		method: 'get',
		params: query
	})
}

// 查询核额记录详细
export function getAmount(data) {
	return request({
		url: '/audit/audit-amount/detail',
		method: 'get',
		params: data
	})
}

// 新增核额记录
export function addAmount(data) {
	return request({
		url: '/audit/audit-amount/add',
		method: 'post',
		data: data
	})
}

// 修改核额记录
export function updateAmount(data) {
	return request({
		url: '/audit/audit-amount/edit',
		method: 'post',
		data: data
	})
}

// 删除核额记录
export function delAmount(data) {
	return request({
		url: '/audit/audit-amount/delete',
		method: 'post',
		data: data
	})
}

// 银行列表 
export function bankdata(data) {
	return request({
		url: '/bankdata/bankdata/list',
		method: 'get',
		data: data
	})
}

// 区域列表
export function regionList(data) {
	return request({
		url: '/system/dept/getRegionList',
		method: 'get',
		params: data
	})
}


// 区域团队列表
export function areaTeam(data) {
	return request({
		url: '/system/user/deptTree',
		method: 'get',
		params: data
	})
}