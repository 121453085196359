<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="员工id,外键{sys_user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入员工id,外键{sys_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="新名单库id,外键{zb_roster.id}" prop="rosterId" >
        <a-input v-model="form.rosterId" placeholder="请输入新名单库id,外键{zb_roster.id}" />
      </a-form-model-item>
      <a-form-model-item label="录音文件地址" prop="tapeFile" >
        <file-upload v-model="form.tapeFile" type="file"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="通话时长(秒)" prop="callTime" >
        <a-input v-model="form.callTime" placeholder="请输入通话时长(秒)" />
      </a-form-model-item>
      <a-form-model-item label="通话状态,0-未接,1-已接" prop="callStatus" >
      </a-form-model-item>
      <a-form-model-item label="拨出方号码" prop="dialingOutNumber" >
        <a-input v-model="form.dialingOutNumber" placeholder="请输入拨出方号码" />
      </a-form-model-item>
      <a-form-model-item label="接听方号码" prop="calledNumber" >
        <a-input v-model="form.calledNumber" placeholder="请输入接听方号码" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRecord, addRecord, updateRecord } from '@/api/call/record'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        rosterId: null,

        tapeFile: null,

        callTime: null,

        callStatus: 0,

        dialingOutNumber: null,

        calledNumber: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '员工id,外键{sys_user.id}不能为空', trigger: 'blur' }
        ],
        rosterId: [
          { required: true, message: '新名单库id,外键{zb_roster.id}不能为空', trigger: 'blur' }
        ],
        tapeFile: [
          { required: true, message: '录音文件地址不能为空', trigger: 'blur' }
        ],
        callTime: [
          { required: true, message: '通话时长(秒)不能为空', trigger: 'blur' }
        ],
        callStatus: [
          { required: true, message: '通话状态,0-未接,1-已接不能为空', trigger: 'blur' }
        ],
        dialingOutNumber: [
          { required: true, message: '拨出方号码不能为空', trigger: 'blur' }
        ],
        calledNumber: [
          { required: true, message: '接听方号码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        rosterId: null,
        tapeFile: null,
        callTime: null,
        callStatus: 0,
        dialingOutNumber: null,
        calledNumber: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRecord({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
