<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="申请人员" prop="userName">
								<a-input v-model="queryParam.userName" placeholder="请输入申请人员" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="申请部门" prop="userId">
								<a-input v-model="queryParam.userId" placeholder="请选择申请部门" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="创建时间" prop="userId">
								<a-range-picker v-model="creatTime" @change="onChangeCreate" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="完成时间" prop="userId">
								<a-range-picker v-model="successTime" @change="onChangeSuccess" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 6 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<div class="">
					<a-radio-group v-model="queryParam.type" @change="onChangeTab" size="large">
						<a-radio-button :value="1">待我处理</a-radio-button>
						<a-radio-button :value="2">我发起的</a-radio-button>
						<a-radio-button :value="3">我已处理</a-radio-button>
					</a-radio-group>
					<a-button type="primary" :style="{float: 'right'}" @click="handleExport"
						v-hasPermi="['approval:approval:export']">
						<a-icon type="download" />导出
					</a-button>
				</div>
			</div>
			<div class="table-operations">
				<a-button type="primary" v-if="queryParam.type==1" :disabled="multiple"
					@click="handleOk(selectedRowKeys)">批量通过</a-button>
			</div>
			<!-- 增加修改 -->
			<create-form ref="createForm" @ok="getList" />
			<!-- 数据展示 -->
			<a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
				:bordered="tableBordered">
				<span slot="userName" slot-scope="text, record">
					{{record.userName + '提交的报销'}}
				</span>
				<span slot="notes" slot-scope="text, record">
					{{'报销类型：'+ record.reimbursementType + '  报销金额：' + record.money + '  备注：' +record.notes + '  创建人：'+record.userName + '  创建时间：' +record.createTime }}
				</span>
				<span slot="operation" slot-scope="text, record">
					<a-divider type="vertical" v-hasPermi="['approval:approval:edit']" />
					<a v-if="queryParam.type==1" @click="$refs.createForm.handleAdd(record, undefined)">审核操作</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		listApproval,
		delApproval,
		batchPass,
		approvalPage
	} from '@/api/approval/approval'
	import CreateForm from './modules/CreateForm'
	import {
		tableMixin
	} from '@/store/table-mixin'

	export default {
		name: 'Approval',
		components: {
			CreateForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					userName: null,
					deptId: null,
					createBeginTime: null,
					createEndTime: null,
					completeEndTime: null,
					completeBeginTime: null,
					type: 1,
					pageNum: 1,
					pageSize: 10
				},
				columns: [{
						title: '数据来源',
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'userName'
						},
					},
					{
						title: '申请人',
						dataIndex: 'userName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '简介',
						dataIndex: 'notes',
						align: 'center',
						width: 600,
						scopedSlots: {
							customRender: 'notes'
						},
					},
					{
						title: '创建时间',
						dataIndex: 'createTime',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'operation',
						width: '18%',
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				successTime: null,
				creatTime: null,
			}
		},
		filters: {},
		created() {
			this.getList()
		},
		computed: {},
		watch: {},
		methods: {
			// 切换tab
			onChangeTab(e) {
				console.log('选择tab', e)
				this.getList()
			},
			// 创建时间
			onChangeCreate(e) {
				console.log('选择创建时间');
				this.queryParam.createBeginTime = e.format('YYYY-MM-DD');
				this.queryParam.createEndTime = e.format('YYYY-MM-DD');
			},
			// 完成时间
			onChangeSuccess(e) {
				console.log('选择完成时间');
				this.queryParam.completeBeginTime = e.format('YYYY-MM-DD');
				this.queryParam.completeEndTime = e.format('YYYY-MM-DD');
			},
			/** 查询审批列表 */
			getList() {
				this.loading = true
				approvalPage(this.queryParam).then(response => {
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},
			// 批量通过弹窗
			handleOk(ids) {
				let that = this;
				let arr = []
				ids.map(item => {
					arr.push({
						id: item
					})
				})
				this.$confirm({
					title: '提示',
					content: '确认审批通过所选中数据?',
					onOk() {
						return batchPass({
							idParamList: arr
						}).then(res => {
							if (res.success) {
								console.log('批量审批', res);
							}
						})
					},
					onCancel() {
						//取消清空删除ids列表
						that.ids = []
					}
				})

			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					userName: null,
					deptId: null,
					createBeginTime: null,
					createEndTime: null,
					completeEndTime: null,
					completeBeginTime: null,
					type: 1,
					pageNum: 1,
					pageSize: 10
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return delApproval(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
						that.ids = []
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('approval/approval/export', {
							...that.queryParam
						}, `审批_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			}
		}
	}
</script>