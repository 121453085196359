<template>
	<a-drawer title="核额" width="45%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
		<div class="budget-list">
			<div class="budget-item" v-for="(item,index) in list" :key="index">
				<div class="bank width45">
					<span>银行：</span>
					<a-select style="width: 70%;" v-model="item.bankdataId" placeholder="请选择客户来源" allow-clear>
						<a-select-option :value="bank.id" v-for="(bank,index1) in bankLists">{{bank.name}}</a-select-option>
					</a-select>
				</div>
				<div class="Budget-money width45">
					<span>核额金额：</span>
					<a-input placeholder="请输入(万)" type="number" style="width: 70%;" v-model="item.money" />
				</div>
				<a-button type="danger" @click="removeItem(index)">移除</a-button>
				<a-divider type="vertical" />
				<a-button type="primary" @click="addItem">新增</a-button>
			</div>
		</div>
		<div class="bottom-control">
			<a-space>
				<a-button type="primary" :loading="submitLoading" @click="submitForm">
					保存
				</a-button>
				<a-button type="dashed" @click="onClose">
					取消
				</a-button>
			</a-space>
		</div>
	</a-drawer>
</template>

<script>
	import {
		bankList,
		batchUpdata,
		auditamount,
	} from '@/api/roster/roster'
	export default {
		name: 'CreateBudget',
		data() {
			return {
				open: false,
				record: {},
				list: [{
					money: '',
					bankdataId: undefined,
				}],
				bankLists: [],
				submitLoading: false,
			}
		},
		methods: {
			// 提交核额
			submitForm() {
				console.log(this.list, this.record)
				for (let i = 0; i < this.list.length; i++) {
					if (!this.list[i].bankdataId || !this.list[i].money) {
						this.$message.error('请完善第' + (i + 1) + '行核额信息');
						return false;
					}
					if (this.list[i].money < 0) {
						this.$message.error('请完善第' + (i + 1) + '行核额金额，核额金额不能小于0');
						return false;
					}
				}
				this.submitLoading = true;
				batchUpdata({
					addList: this.list,
					rosterId: this.record.rosterId,
					rosterMyId: this.record.id
				}).then(res => {
					if (res.success) {
						this.submitLoading = false;
						this.$message.success(res.message);
						this.list = [{
							money: '',
							bankdataId: '',
						}]
						this.open = false;
					} else {
						this.submitLoading = false;
					}
				})
			},
			// 新增弹窗
			handleAdd(record) {
				this.record = record;
				this.getbankList();
				this.getauditamount(record.rosterId)
				this.open = true;
			},
			// 关闭
			onClose() {
				this.list = [{
					money: '',
					bankdataId: '',
				}]
				this.record = {};
				this.open = false;
			},
			// 添加
			addItem() {
				this.list.push({
					money: '',
					bankdataId: '',
				})
			},
			// 移除
			removeItem(index) {
				if (this.list.length == 1) {
					this.$message.error('请至少保留一个');
					return false;
				}
				this.list.splice(index, 1)
			},
			// 核额记录
			getauditamount(id) {
				auditamount({
					rosterId: id
				}).then(res => {
					console.log('核额记录', res);
					if (res.success) {
						this.list.push(...res.data);
					}
				})
			},
			// 银行列表
			getbankList() {
				bankList({}).then(res => {
					if (res.success) {
						console.log('银行列表', res);
						this.bankLists = res.data;
					}
				})
			}
		}
	}
</script>

<style scoped>
	.budget-list {}

	.budget-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 20px;
	}

	.width45 {
		width: 45%;
	}

	.add {
		margin-top: 20px;
		text-align: right;
	}
</style>