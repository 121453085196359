<template>
	<a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
		<a-divider orientation="left">
			<b>{{ formTitle }}</b>
		</a-divider>
		<a-form-model ref="form" :model="form" :rules="rules">
			<a-form-model-item label="APP类型" prop="appType">
				<a-select v-model="form.appType">
					<a-select-option :value="0">安卓</a-select-option>
					<a-select-option :value="1">苹果</a-select-option>
					<a-select-option :value="2">通用</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="更新说明" prop="upNotes">
				<a-input v-model="form.upNotes" placeholder="请输入内容" type="textarea" allow-clear />
			</a-form-model-item>
			<!--      <a-form-model-item label="更新包大小" prop="upHeSize" >
        <a-input v-model="form.upHeSize" placeholder="请输入更新包大小" />
      </a-form-model-item>-->
			<!--      <a-form-model-item label="更新地址类型: 0内部 1其它(应用市场)" prop="upUrlType" >
        <a-select v-model="form.upUrlType">
          <a-select-option :value="0">内部</a-select-option>
          <a-select-option :value="1">其它(应用市场)</a-select-option>
        </a-select>
      </a-form-model-item>-->
			<!--      <a-form-model-item label="其它(应用市场)应用 【内部使用文件库】" prop="upUrl" v-if="form.upUrlType === 1" >
        <a-input v-model="form.upUrl" placeholder="请输入其它(应用市场)应用 【内部使用文件库】" />
      </a-form-model-item>-->
			<a-form-model-item label="版本号名称" prop="versionName">
				<a-input v-model="form.versionName" placeholder="请输入版本号名称" />
			</a-form-model-item>
			<a-form-model-item label="版本号" prop="versionCode">
				<a-input v-model="form.versionCode" placeholder="请输入版本号" />
			</a-form-model-item>
			<a-form-model-item label="更新类型" prop="upType">
				<a-select v-model="form.upType">
					<a-select-option :value="0">热更新</a-select-option>
					<a-select-option :value="1">整包更新</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="强制升级" prop="compulsoryUp">
				<a-select v-model="form.compulsoryUp">
					<a-select-option :value="0">强制</a-select-option>
					<a-select-option :value="1">可跳过</a-select-option>
				</a-select>
			</a-form-model-item>
			<!--      <a-form-model-item label="计划更新时间" prop="planUpType" >
        <a-select v-model="form.planUpType">
          <a-select-option :value="0">及时更新</a-select-option>
          <a-select-option :value="1">计划更新</a-select-option>
        </a-select>
      </a-form-model-item>-->
			<!--      <a-form-model-item label="计划更新时间" prop="planUpTime" v-if="form.planUpType === 1" >
        <a-input v-model="form.planUpTime" placeholder="请输入计划更新时间" />
      </a-form-model-item>-->
			<a-form-model-item label="下载地址" prop="downloadUrl">
				<file-upload type="file" @input="getImg($event, 'downloadUrl')"></file-upload>
			</a-form-model-item>
			<a-form-model-item label="备注" prop="remark">
				<a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
			</a-form-model-item>
			<div class="bottom-control">
				<a-space>
					<a-button type="primary" :loading="submitLoading" @click="submitForm">
						保存
					</a-button>
					<a-button type="dashed" @click="cancel">
						取消
					</a-button>
				</a-space>
			</div>
		</a-form-model>
	</a-drawer>
</template>

<script>
	import {
		getVersionInfo,
		addVersionInfo,
		updateVersionInfo
	} from '@/api/app/versionInfo'

	export default {
		name: 'CreateForm',
		props: {},
		components: {},
		data() {
			return {
				submitLoading: false,
				formTitle: '',
				// 表单参数
				form: {
					id: null,

					appType: null,

					upNotes: null,

					upHeSize: null,

					upUrlType: null,

					upUrl: null,

					versionName: null,

					versionCode: null,

					upType: null,

					compulsoryUp: null,

					planUpType: null,

					planUpTime: null,

					downloadUrl: null,

					createTime: null,

					remark: null,

				},
				// 1增加,2修改
				formType: 1,
				open: false,
				rules: {
					appType: [{
						required: true,
						message: 'APP类型 : 0安卓 1苹果 2通用不能为空',
						trigger: 'change'
					}],
					upUrlType: [{
						required: true,
						message: '更新地址类型: 0内部 1其它(应用市场)不能为空',
						trigger: 'change'
					}],
					versionCode: [{
						required: true,
						message: '版本号不能为空',
						trigger: 'blur'
					}],
					upType: [{
						required: true,
						message: '更新类型: 0热更新 1整包更新不能为空',
						trigger: 'change'
					}],
					compulsoryUp: [{
						required: true,
						message: '强制升级否 0强制 1可跳过不能为空',
						trigger: 'blur'
					}],
					planUpType: [{
						required: true,
						message: '计划更新时间: 0及时更新 1计划更新不能为空',
						trigger: 'change'
					}],
					planUpTime: [{
						required: true,
						message: '计划更新时间不能为空',
						trigger: 'blur'
					}],
					downloadUrl: [{
						required: true,
						message: '下载地址不能为空',
						trigger: 'blur'
					}]
				}
			}
		},
		filters: {},
		created() {},
		computed: {},
		watch: {},
		mounted() {},
		methods: {
			/**上传图片成功回调 */
			getImg(info, fieldName) {
				console.log('info', info, fieldName)
				if (info.length === 0) {
					this.form[fieldName] = ''
					this.form[`${fieldName}Arr`] = []
				} else {
					if (this.form[`${fieldName}Count`] === 1) {
						this.form[fieldName] = info[0].url
						this.form[`${fieldName}Arr`] = info
					} else {
						this.form[fieldName] = info.map(ele => {
							return ele.url
						}).join(",")
						this.form[`${fieldName}Arr`] = info
					}
				}
			},
			onClose() {
				this.open = false
			},
			// 取消按钮
			cancel() {
				this.open = false
				this.reset()
			},
			// 表单重置
			reset() {
				this.formType = 1
				this.form = {
					id: null,
					appType: null,
					upNotes: null,
					upHeSize: null,
					upUrlType: null,
					upUrl: null,
					versionName: null,
					versionCode: null,
					upType: null,
					compulsoryUp: null,
					planUpType: null,
					planUpTime: null,
					downloadUrl: null,
					createTime: null,
					remark: null,
				}
				this.$nextTick(() => {
					if (this.$refs.form) {
						this.$refs.form.resetFields()
					}
				})
			},
			/** 新增按钮操作 */
			handleAdd(row) {
				this.reset()
				this.formType = 1
				this.open = true
				this.formTitle = '添加'
			},
			/** 修改按钮操作 */
			handleUpdate(row, ids) {
				this.reset()
				this.formType = 2
				const id = row ? row.id : ids
				getVersionInfo({
					"id": id
				}).then(response => {
					this.form = response.data
					this.open = true
					this.formTitle = '修改'
				})
			},
			/** 提交按钮 */
			submitForm: function() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.submitLoading = true
						if (this.form.id !== undefined && this.form.id !== null) {
							updateVersionInfo(this.form).then(response => {
								this.$message.success(
									'修改成功',
									3
								)
								this.open = false
								this.$emit('ok')
							}).finally(() => {
								this.submitLoading = false
							})
						} else {
							addVersionInfo(this.form).then(response => {
								this.$message.success(
									'新增成功',
									3
								)
								this.open = false
								this.$emit('ok')
							}).finally(() => {
								this.submitLoading = false
							})
						}
					} else {
						return false
					}
				})
			}
		}
	}
</script>