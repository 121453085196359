import request from '@/utils/request'


// 查询APP版本升级 - 记录信息列表
export function listVersionInfo(query) {
  return request({
    url: '/app/app-version-info/list',
    method: 'get',
    params: query
  })
}

// 查询APP版本升级 - 记录信息分页
export function pageVersionInfo(query) {
  return request({
    url: '/app/app-version-info/page',
    method: 'get',
    params: query
  })
}

// 查询APP版本升级 - 记录信息详细
export function getVersionInfo(data) {
  return request({
    url: '/app/app-version-info/detail',
    method: 'get',
    params: data
  })
}

// 新增APP版本升级 - 记录信息
export function addVersionInfo(data) {
  return request({
    url: '/app/app-version-info/add',
    method: 'post',
    data: data
  })
}

// 修改APP版本升级 - 记录信息
export function updateVersionInfo(data) {
  return request({
    url: '/app/app-version-info/edit',
    method: 'post',
    data: data
  })
}

// 删除APP版本升级 - 记录信息
export function delVersionInfo(data) {
  return request({
    url: '/app/app-version-info/delete',
    method: 'post',
    data: data
  })
}
