<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="标题" prop="title">
								<a-input v-model="queryParam.title" placeholder="请输入标题" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="内容" prop="content">
								<a-input v-model="queryParam.content" placeholder="请输入内容" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="状态" prop="read">
								<a-select v-model="queryParam.read" placeholder="请选择状态" allow-clear>
									<a-select-option :value="item.value" v-for="(item,index) in readList" :key="index">
										{{item.label}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="创建时间" prop="createBeginTime">
								<a-date-picker v-model="queryParam.createBeginTime" @change="getSelectTimes" allow-clear
									style="width: 100%;"></a-date-picker>
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 8 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<a-button type="primary" @click="handleAllRead" v-hasPermi="['inform:inform:add']">
					全部已读
				</a-button>
			</div>
			<!-- 增加修改 -->
			<create-form ref="createForm" @ok="getList" />
			<!-- 数据展示 -->
			<!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
			<a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:pagination="false" :bordered="tableBordered">
				<span slot="titles" slot-scope="text, record">
					<a @click="handlePath(record.type,record.id,record)">{{record.title}}</a>
				</span>
				<span slot="operation" slot-scope="text, record">
					<a-divider type="vertical" v-hasPermi="['inform:inform:edit']" />
					<a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['inform:inform:edit']">
						<a-icon type="edit" />修改
					</a>
					<a-divider type="vertical" v-hasPermi="['inform:inform:remove']" />
					<a @click="handleDelete(record)" v-hasPermi="['inform:inform:remove']">
						<a-icon type="delete" />删除
					</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		pageInform,
		listInform,
		delInform,
		readnew,
		allReadnew
	} from '@/api/inform/inform'
	import CreateForm from './modules/CreateForm'
	import {
		tableMixin
	} from '@/store/table-mixin'
	import {
		EventBus
	} from '@/assets/js/event-bus.js';

	export default {
		name: 'Inform',
		components: {
			CreateForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					title: null,
					content: null,
					userId: null,
					read: undefined,
					createBeginTime: null,
					pageNum: 1,
					pageSize: 10
				},
				columns: [{
						title: '标题',
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'titles'
						},
					},
					{
						title: '内容',
						dataIndex: 'content',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '状态', //是否已读,0-未读,1-已读
						dataIndex: 'read',
						ellipsis: true,
						align: 'center',
						customRender: function(text, record, index) {
							if (text == 0) {
								return '未读'
							} else {
								return '已读'
							}
						}
					},
					{
						title: '创建时间',
						dataIndex: 'createTime',
						ellipsis: true,
						align: 'center'
					},
				],
				readList: [{
						label: '未读',
						value: 0
					},
					{
						label: '已读',
						value: 1
					},
				]
			}
		},
		filters: {},
		created() {
			this.getList()
		},
		computed: {},
		watch: {},
		methods: {
			// 路由跳转
			handlePath(type, id, record) {
				readnew({
					id: id
				}).then(res => {
					if (res.success) {
						record.read = 1;
						EventBus.$emit('read');
						// 1:新增线索新名单公海 2：新增我的新名单库
						let url = type == 1 ? '/roster/clueRosterHighSeas' : '/roster/myRoster'
						this.$router.push({
							path: url
						});
					}
				})
			},
			// 全部已读
			handleAllRead() {
				this.$confirm({
					title: '提示',
					content: '确认已读所有通知消息？',
					onOk() {
						return allReadnew()
							.then(() => {
								that.getList()
								EventBus.$emit('read');
								that.$message.success('已读成功')
							})
					},
					onCancel() {
						//取消清空删除ids列表
						that.ids = []
					}
				})
			},
			/** 查询通知列表 */
			getList() {
				this.loading = true
				pageInform(this.queryParam).then(response => {
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},
			// 选择时间
			getSelectTimes(e) {
				this.queryParam.createBeginTime = e.format('YYYY-MM-DD hh:mm:ss');
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					userId: undefined,
					title: undefined,
					content: undefined,
					read: undefined,
					type: undefined,
					pageNum: 1,
					pageSize: 10
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return delInform(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
						that.ids = []
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('inform/inform/export', {
							...that.queryParam
						}, `通知_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			}
		}
	}
</script>