<template>
	<a-drawer :title='formTitle' width="35%" :label-col="4" :wrapper-col="14" :visible="visible" @close="onClose">
		<a-form-model ref="form" :model="form" :rules="rules">
			<a-form-model-item label="报销类型" prop="reimbursementTypeId">
				<a-select v-model="form.reimbursementTypeId" placeholder="请选择报销类型" :disabled="prohibit" allow-clear>
					<a-select-option :value="item.id" v-for="(item,index) in reimbursementList" :key="index">
						{{item.name}}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="报销时间" prop="reimbursementTime">
				<a-date-picker v-model="form.reimbursementTime" @change="getSelectTime" :disabled="prohibit"
					placeholder="请选择报销时间" style="width: 100%;"></a-date-picker>
			</a-form-model-item>
			<a-form-model-item label="报销金额" prop="money">
				<a-input v-model="form.money" type="number" :disabled="prohibit" placeholder="请输入报销金额" allow-clear />
			</a-form-model-item>
			<a-form-model-item label="备注" prop="notes">
				<a-textarea :disabled="prohibit" placeholder="请输入备注" allow-clear v-model="form.notes"
					:autosize="{minRows: 4, maxRows: 8}" />

				<!-- <a-input placeholder="请输入备注" allow-clear /> -->
			</a-form-model-item>
			<a-form-model-item label="上传影像" prop="img">
				<!-- {{fileList}} -->
				<!-- <img :src="img.url" v-for="(img,index) in fileList" alt=""> -->
				<a-upload name="file" :file-list="fileList" :customRequest="customOssRequest">
					<a-button> <a-icon type="upload" /> 上传附件 </a-button>
				</a-upload>
			</a-form-model-item>
			<a-form-model-item label="申请人" prop="notes" v-if="dataType==1">
				<a-input v-model="form.userName" :disabled="true" placeholder="申请人" />
			</a-form-model-item>
			<a-form-model-item label="审批状态" prop="notes" v-if="dataType==1">
				<a-input v-model="form.status==0?'待审批':form.status==1?'审批中':form.status==2?'审批成功':form.status==4?'审批失败':''"
					:disabled="true" placeholder="审批状态" />
			</a-form-model-item>
			<a-form-model-item label="创建时间" prop="notes" v-if="dataType==1">
				<a-input v-model="form.createTime" :disabled="true" placeholder="创建时间" />
			</a-form-model-item>
			<div class="bottom-control" v-if="!prohibit">
				<a-space>
					<a-button type="primary" :loading="submitLoading" @click="submitForm">
						保存
					</a-button>
					<a-button type="dashed" @click="onClose">
						取消
					</a-button>
				</a-space>
			</div>
		</a-form-model>
	</a-drawer>
</template>

<script>
	import {
		reimbursement,
		approval,
		signature,
		approvalAdd,
		approvalDetail,
		approvalEdit
	} from '@/api/call/record'
	import axios from 'axios'
	export default {
		name: 'CreateModel',
		data() {
			return {
				visible: false,
				formTitle: '新增',
				form: {
					reimbursementTypeId: undefined,
					reimbursementTime: null,
					notes: null,
					img: null,
					money: null,
				},
				rules: {
					reimbursementTypeId: [{
						required: true,
						message: '报销类型不能为空',
						trigger: 'blur'
					}],
					reimbursementTime: [{
						required: true,
						message: '报销时间不能为空',
						trigger: 'change'
					}],
					money: [{
						required: true,
						message: '报销金额不能为空',
						trigger: 'blur'
					}, {
						validator: this.validateNumber,
						trigger: 'blur'
					}, ],
				},
				reimbursementList: [],
				ossUploadUrl: 'http://mypkgz.natappfree.cc',
				uploadData: {
					policy: '',
					key: '',
					OSSAccessKeyId: '',
					success_action_status: 200,
					signature: '',
				},
				submitLoading: false,
				fileList: [],
				dataType: 1,
				record: {},
				prohibit: false,
			}
		},
		created() {
			this.getReimbursement();
			this.getSignature()
		},
		methods: {
			// 新增审批
			submitForm() {
				this.$refs.form.validate(valid => {
					if (valid) {
						// 表单验证通过后的操作
						if (this.dataType == 2) {
							this.submitLoading = true;
							this.form.img = JSON.stringify(this.fileList);
							approvalEdit(this.form).then(res => {
								console.log('审批编辑', res);
								if (res.success) {
									this.$message.success(res.message);
									this.form = {
										reimbursementTypeId: null,
										reimbursementTime: null,
										notes: null,
										img: null,
										money: null,
									}
									this.fileList = [];
									this.submitLoading = false;
									this.$emit('ok');
									this.visible = false;
								}
							})
						} else {
							this.submitLoading = true;
							this.form.img = JSON.stringify(this.fileList);
							approvalAdd(this.form).then(res => {
								console.log('新增审批', res);
								if (res.success) {
									this.$message.success(res.message);
									this.form = {
										reimbursementTypeId: null,
										reimbursementTime: null,
										notes: null,
										img: null,
									}
									this.fileList = [];
									this.submitLoading = false;
									this.$emit('ok');
									this.visible = false;
								}
							})
						}
					} else {
						console.log('表单验证失败');
					}
				});

				// if (this.fileList.length > 0) {
				// 	this.form.img = this.fileList.map(item => {
				// 		return item.path
				// 	}).join()
				// }

			},
			customOssRequest(info) {
				let that = this;
				let formData = new FormData();
				formData.append('policy', this.uploadData.policy);
				formData.append('OSSAccessKeyId', this.uploadData.OSSAccessKeyId);
				formData.append('signature', this.uploadData.signature);
				formData.append('key', info.file.name);
				formData.append('success_action_status', 200);
				formData.append('file', info.file);
				axios.post(this.ossUploadUrl, formData).then(res => {
					console.log(res)
					if (res.status == 200) {
						console.log(that.form)
						this.fileList.push({
							name: info.file.name,
							url: this.ossUploadUrl + '/' + info.file.name,
							status: 'done',
							uid: this.fileList.length
						})
					}
				})
			},
			getSelectTime(e) {
				this.form.reimbursementTime = e.format('YYYY-MM-DD');
			},
			onClose() {
				this.visible = false;
			},
			handleAdd(record, status) {
				this.dataType = status;
				if (status == 1) {
					this.prohibit = true;
				} else {
					this.prohibit = false;
				}
				if (record) {
					this.record = record;
					this.getapprovalDetail(record.id);
				}
				this.form = {
					reimbursementTypeId: undefined,
					reimbursementTime: null,
					notes: null,
					img: null,
					money: null,
				};
				this.fileList = [];
				this.visible = true;
			},
			// 获取审批详情
			getapprovalDetail(id) {
				approvalDetail({
					id: id
				}).then(res => {
					if (res.success) {
						console.log('审批详情', res);
						if (res.data.img) {
							this.fileList = JSON.parse(res.data.img)
							this.fileList.map((item, index) => {
								item.status = 'done';
								item.uid = index
							})
						}
						this.form = res.data;
						console.log('详情', this.form)
					}
				})
			},
			// 报销类型
			getReimbursement() {
				reimbursement().then(response => {
					console.log('报销类型', response)
					this.reimbursementList = response.data.records;
				})
			},
			// oss签名
			getSignature() {
				signature().then(res => {
					console.log('oss', res);
					let data = res.data;
					// this.uploadData.key = data
					this.ossUploadUrl = data.resultUrl;
					this.uploadData.policy = data.policy;
					this.uploadData.OSSAccessKeyId = data.accessid;
					this.uploadData.signature = data.signature;
				})
			},
			beforeUpload(info) {
				// console.log(info)
				this.uploadData.key = info.name;
			},
			removeImg(info) {
				// console.log('删除文件', info);
				this.fileList.map((item, index) => {
					if (info.name == item.name) {
						this.fileList.splice(index, 1);
					}
				})
			},
			handleChange(info) {
				if (info.file.status !== 'uploading') {
					this.fileList.push({
						name: info.file.name,
						url: this.ossUploadUrl + '/' + info.file.name
					})
				}
			},
			validateNumber(rule, value, callback) {
				if (value < 0) {
					callback(new Error('数字不能为负数'));
				} else {
					callback();
				}
			}
		}
	}
</script>

<style>
</style>