import request from '@/utils/request'


// 查询通知列表
export function listInform(query) {
	return request({
		url: '/inform/inform/list',
		method: 'get',
		params: query
	})
}

// 查询通知分页
export function pageInform(query) {
	return request({
		url: '/inform/inform/page',
		method: 'get',
		params: query
	})
}

// 查询通知详细
export function getInform(data) {
	return request({
		url: '/inform/inform/detail',
		method: 'get',
		params: data
	})
}

// 新增通知
export function addInform(data) {
	return request({
		url: '/inform/inform/add',
		method: 'post',
		data: data
	})
}

// 修改通知
export function updateInform(data) {
	return request({
		url: '/inform/inform/edit',
		method: 'post',
		data: data
	})
}

// 删除通知 
export function delInform(data) {
	return request({
		url: '/inform/inform/delete',
		method: 'post',
		data: data
	})
}

// 通知已读 
export function readnew(data) {
	return request({
		url: '/inform/inform/read',
		method: 'post',
		data: data
	})
}

// 当前用户所有消息已读 
export function allReadnew(data) {
	return request({
		url: '/inform/inform/allRead',
		method: 'post',
		data: data
	})
}