<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="时间" prop="userId">
								<a-range-picker @change="onChangeTimes" v-model="selecttime" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="客户名称" prop="name">
								<a-input v-model="queryParam.name" placeholder="请输入" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="地区" prop="area">
								<a-input v-model="queryParam.area" placeholder="请输入" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="手机号" prop="phone">
								<a-input v-model="queryParam.phone" placeholder="请输入" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="银行" prop="bankdataId">
								<a-select v-model="queryParam.bankdataId" placeholder="请选择银行" allow-clear>
									<a-select-option :value="bank.id" v-for="(bank,index) in bankList" :key="index">
										{{bank.name}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="所属区域" prop="areaDetpId">
								<a-select v-model="queryParam.areaDeptId" @change="handleChange" placeholder="请选择所属区域" allow-clear>
									<a-select-option :value="region.id" v-for="(region,index) in regionLists" :key="index">
										{{region.deptName}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="所属团队" prop="teamDetpId">
								<a-select v-model="queryParam.teamDetpId" placeholder="请选择团队" allow-clear>
									<a-select-option :value="team.id" v-for="(team,index) in teamList" :key="index">
										{{team.label}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 6 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>

				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<a-button type="primary" @click="excelExporrt('台账提款报表')" v-hasPermi="['report:sales:export']">导出</a-button>
				<!-- <table-setting :style="{float: 'right'}" :table-size.sync="tableSize" v-model="columns"
					:refresh-loading="loading" @refresh="getList" /> -->
			</div>

			<a-modal title="Title" :visible="lookVisible" @ok="lookVisible=false" @cancel="lookVisible=false">
				<img :src="selectUrl" alt="" style="width: 100%;">
			</a-modal>

			<!-- 增加修改 -->
			<!-- <create-form ref="createForm" @ok="getList" /> -->
			<!-- 数据展示 -->
			<!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
			<a-table :loading="loading" id="columns" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:pagination="false" :bordered="tableBordered" :scroll="{ x: '1000px' }">
				<span slot="createTime" slot-scope="text, record">
					{{ parseTime(record.createTime) }}
				</span>
				<span slot="firstDrawingImg" slot-scope="text, record">
					<img style="width: 40px;height: 40px;" :src="img.url" alt=""
						v-for="(img,index) in filterImgList(record.firstDrawingImg)" @click="lookPriveImg(img.url)" />
				</span>
				<span slot="firstDrawingImg1" slot-scope="text, record">
					<span>{{filterImgLists(record.firstDrawingImg)}}</span>
				</span>

				<span slot="firstRepaymentImg" slot-scope="text, record">
					<img style="width: 40px;height: 40px;" :src="img.url" alt=""
						v-for="(img,index) in filterImgList(record.firstRepaymentImg)" />
				</span>
				<span slot="firstRepaymentImg1" slot-scope="text, record">
					<span>{{filterImgLists(record.firstRepaymentImg)}}</span>
				</span>

				<span slot="secondDrawingImg" slot-scope="text, record">
					<img style="width: 40px;height: 40px;" :src="img.url" alt=""
						v-for="(img,index) in filterImgList(record.secondDrawingImg)" />
				</span>
				<span slot="secondDrawingImg1" slot-scope="text, record">
					<span>{{filterImgLists(record.secondDrawingImg)}}</span>
				</span>

				<span slot="secondRepaymentImg" slot-scope="text, record">
					<img style="width: 40px;height: 40px;" :src="img.url" alt=""
						v-for="(img,index) in filterImgList(record.secondRepaymentImg)" />
				</span>
				<span slot="secondRepaymentImg1" slot-scope="text, record">
					<span>{{filterImgLists(record.secondRepaymentImg)}}</span>
				</span>

				<span slot="thirdDrawingImg" slot-scope="text, record">
					<img style="width: 40px;height: 40px;" :src="img.url" alt=""
						v-for="(img,index) in filterImgList(record.thirdDrawingImg)" />
				</span>
				<span slot="thirdDrawingImg" slot-scope="text, record">
					<span>{{filterImgLists(record.thirdDrawingImg)}}</span>
				</span>

				<span slot="thirdRepaymentImg" slot-scope="text, record">
					<img style="width: 40px;height: 40px;" :src="img.url" alt=""
						v-for="(img,index) in filterImgList(record.thirdRepaymentImg)" />
				</span>
				<span slot="thirdRepaymentImg1" slot-scope="text, record">
					<span>{{filterImgLists(record.thirdRepaymentImg)}}</span>
				</span>

				<span slot="fourthDrawingImg" slot-scope="text, record">
					<img style="width: 40px;height: 40px;" :src="img.url" alt=""
						v-for="(img,index) in filterImgList(record.fourthDrawingImg)" />
				</span>
				<span slot="fourthDrawingImg" slot-scope="text, record">
					<span>{{filterImgLists(record.fourthDrawingImg)}}</span>
				</span>

				<span slot="fourthRepaymentImg" slot-scope="text, record">
					<img style="width: 40px;height: 40px;" :src="img.url" alt=""
						v-for="(img,index) in filterImgList(record.fourthRepaymentImg)" />
				</span>
				<span slot="fourthRepaymentImg" slot-scope="text, record">
					<span>{{filterImgLists(record.fourthRepaymentImg)}}</span>
				</span>

				<span slot="operation" slot-scope="text, record">
					<a-divider type="vertical" v-hasPermi="['report:sales:edit']" />
					<a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['report:sales:edit']">
						<a-icon type="edit" />修改
					</a>
					<a-divider type="vertical" v-hasPermi="['report:sales:remove']" />
					<a @click="handleDelete(record)" v-hasPermi="['report:sales:remove']">
						<a-icon type="delete" />删除
					</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		pageSales,
		listSales,
		delSales,
		bankdata,
		regionList,
		areaTeam,
		withdrawalReport
	} from '@/api/report/sales'
	// import CreateForm from './modules/CreateForm'
	import {
		tableMixin
	} from '@/store/table-mixin'
	import FileSaver from 'file-saver'

	import XLSX from 'xlsx'

	export default {
		name: 'Sales',
		components: {
			// CreateForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					createBeginTime: undefined,
					createEndTime: undefined,
					name: undefined,
					phone: undefined,
					area: undefined,
					bankdataId: undefined,
					areaDeptId: undefined,
					teamDetpId: undefined,
					pageNum: 1,
					pageSize: 10
				},
				columns: [{
						title: '时间',
						dataIndex: 'createTime',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '所属团队',
						dataIndex: 'deptName',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '所属坐席',
						dataIndex: 'userName',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '工作单位',
						dataIndex: 'work',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '市区',
						width: 100,
						dataIndex: 'area',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '省份',
						width: 100,
						dataIndex: 'province',
						ellipsis: true,
						align: 'center'
					}, {
						title: '客户名称',
						dataIndex: 'name',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '手机号',
						dataIndex: 'phone',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '核额业绩',
						dataIndex: 'money',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '银行',
						dataIndex: 'bankName',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '首次提交时间',
						dataIndex: 'firstDrawingTime',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '首次提款金额',
						dataIndex: 'firstDrawingMoney',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '上传影像',
						dataIndex: 'firstDrawingImg',
						width: 200,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'firstDrawingImg'
						},
					}, {
						title: '上传影像地址',
						width: 200,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'firstDrawingImg1'
						},
					}, {
						title: '首次还款时间',
						dataIndex: 'firstRepaymentTime',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '首次还款金额',
						dataIndex: 'firstRepaymentMoney',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '还款影像',
						dataIndex: 'firstRepaymentImg',
						width: 200,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'firstRepaymentImg'
						},
					}, {
						title: '还款影像地址',
						width: 0,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'firstRepaymentImg1'
						},
					}, {
						title: '二次提交时间',
						dataIndex: 'secondDrawingTime',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '二次提款金额',
						dataIndex: 'secondDrawingMoney',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '上传影像',
						dataIndex: 'secondDrawingImg',
						width: 200,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'secondDrawingImg'
						},
					}, {
						title: '上传影像地址',
						width: 0,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'secondDrawingImg1'
						},
					}, {
						title: '二次还款时间',
						dataIndex: 'secondRepaymentTime',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '二次还款金额',
						dataIndex: 'secondRepaymentMoney',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '还款影像',
						dataIndex: 'secondRepaymentImg',
						width: 200,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'secondRepaymentImg'
						},
					}, {
						title: '还款影像地址',
						width: 0,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'secondRepaymentImg1'
						},
					}, {
						title: '三次提交时间',
						dataIndex: 'thirdDrawingTime',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '三次提款金额',
						dataIndex: 'thirdDrawingMoney',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '上传影像',
						dataIndex: 'thirdDrawingImg',
						width: 200,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'thirdDrawingImg'
						},
					}, {
						title: '上传影像地址',
						width: 0,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'thirdDrawingImg1'
						},
					}, {
						title: '三次还款时间',
						dataIndex: 'thirdRepaymentTime',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '三次还款金额',
						dataIndex: 'thirdRepaymentMoney',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '还款影像',
						dataIndex: 'thirdRepaymentImg',
						width: 200,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'thirdRepaymentImg'
						},
					}, {
						title: '还款影像地址',
						width: 0,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'thirdRepaymentImg1'
						},
					}, {
						title: '四次提交时间',
						dataIndex: 'fourthDrawingTime',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '四次提款金额',
						dataIndex: 'fourthDrawingMoney',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '上传影像',
						dataIndex: 'fourthDrawingImg',
						width: 200,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'fourthDrawingImg'
						},
					}, {
						title: '上传影像地址',
						width: 0,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'fourthDrawingImg1'
						},
					}, {
						title: '四次还款时间',
						dataIndex: 'fourthRepaymentTime',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '四次还款金额',
						dataIndex: 'fourthRepaymentMoney',
						width: 200,
						ellipsis: true,
						align: 'center'
					}, {
						title: '还款影像',
						dataIndex: 'fourthRepaymentImg',
						width: 200,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'fourthRepaymentImg'
						},
					}, {
						title: '还款影像地址',
						width: 0,
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'fourthRepaymentImg1'
						},
					},
				],
				bankList: [], //银行列表
				regionLists: [], //区域列表
				teamList: [],
				selecttime: undefined,
				lookVisible: false,
				selectUrl: '',
			}
		},
		filters: {},
		created() {
			this.getList();
			this.getbankdata()
			this.getRegionList()
		},
		computed: {},
		watch: {},
		methods: {
			// 导出
			excelExporrt(name) {
				let wb = XLSX.utils.table_to_book(document.querySelector('#columns'));
				let weout = XLSX.write(wb, {
					bookType: 'xlsx',
					bookSST: true,
					type: 'array'
				});
				try {
					FileSaver.saveAs(new Blob([weout], {
						type: 'application/octet-stream'
					}), name + '.xlsx')
				} catch (e) {
					if (typeof console != 'undefined') {
						console.log(e, weout)
					}
				}
				return weout
			},
			onChangeTimes(e) {
				this.queryParam.createBeginTime = e[0].format('YYYY-MM-DD hh:mm:ss')
				this.queryParam.createEndTime = e[1].format('YYYY-MM-DD hh:mm:ss')
			},
			// 区域列表
			getRegionList() {
				regionList().then(res => {
					if (res.success) {
						console.log('区域列表', res);
						this.regionLists = res.data;
					}
				})
			},
			// 银行列表
			getbankdata() {
				bankdata().then(res => {
					if (res.success) {
						console.log('银行列表', res);
						this.bankList = res.data;
					}
				})
			},
			getAreaTeam() {
				areaTeam({
					parentId: this.queryParam.areaDetpId
				}).then(res => {
					if (res.success) {
						console.log('团队列表', res);
						this.teamList = res.data;
					}
				})
			},
			handleChange(e) {
				this.queryParam.areaDetpId = e;
				this.queryParam.teamDetpId = '';
				this.getAreaTeam();
			},
			/** 查询销售报列表 */
			getList() {
				this.loading = true
				withdrawalReport(this.queryParam).then(response => {
					console.log('台账列表', response);
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},
			// 图片过滤
			filterImgList(str) {
				if (str) {
					return JSON.parse(str)
				}
			},
			filterImgLists(str) {
				if (str) {
					let arr = JSON.parse(str);
					let arr2 = [];
					arr.map(async (item, index) => {
						console.log(await this.convertToBase64(item.url))
						return await this.convertToBase64(item.url)
					})
					// return arr2.join();
				}
			},
			convertToBase64(url) {
				const image = new Image()
				image.crossOrigin = 'anonymous'
				let dataUrl = ''
				return new Promise((resolve, reject) => {
					image.onload = () => {
						const canvas = document.createElement('canvas')
						const ctx = canvas.getContext('2d')
						canvas.height = image.naturalHeight
						canvas.width = image.naturalWidth
						ctx.drawImage(image, 0, 0)
						dataUrl = canvas.toDataURL()
						//通过resolve将返回值返回
						resolve(dataUrl)
					}
					image.src = url
				})
			},
			// 图片预览
			lookPriveImg(url) {
				this.selectUrl = url;
				this.lookVisible = true;
			},
			handleCancel() {

			},
			handleOk() {

			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					createBeginTime: undefined,
					createEndTime: undefined,
					name: undefined,
					phone: undefined,
					area: undefined,
					bankdataId: undefined,
					areaDetpId: undefined,
					teamDetpId: undefined,
					pageNum: 1,
					pageSize: 10
				}
				this.selecttime = undefined;
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return delSales(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
						that.ids = []
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('report/report-sales/export', {
							...that.queryParam
						}, `销售报_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			}
		}
	}
</script>