<template>
	<div class="data-content">
		<div class="title"></div>
		<div class="data-content-info">
			<div class="title-content">
				<div>{{weekDay}}</div>
				<h1>融企年度企业数据报告</h1>
				<div>{{year + '-' + month + '-' +day}}</div>
			</div>
			<div class="data-info">
				<div class="data-left">
					<div class="public-style">
						<h4 class="public-title">当日核额业绩体量</h4>
						<img class="line-img" src="./img/rongqi2.png" alt="" />
						<div class="public-table">
							<div class="table-title">
								<span>团队名称</span>
								<span>业绩金额(万)</span>
							</div>
							<div class="table-item" v-for="(item,index) in newTeamData" :key="index">
								<span>{{item.detpName}}</span>
								<span>3{{item.allMoney}}</span>
							</div>
						</div>
					</div>
					<div class="public-style four-seasons">
						<h4 class="public-title">当日锁期业绩体量</h4>
						<img class="line-img" src="./img/rongqi2.png" alt="" />
						<div class="public-table">
							<div class="table-title">
								<span>团队名称</span>
								<span>业绩金额(万)</span>
							</div>
							<div class="table-item" v-for="(item,index) in newTeamData1" :key="index">
								<span>{{item.deptName}}</span>
								<span>3{{item.allMoney}}</span>
							</div>
						</div>
					</div>
					<div class="four-seasons public-style">
						<h4 class="public-title">本年四季度业绩明细</h4>
						<img class="line-img" src="./img/rongqi2.png" alt="" />
						<div class="seasons-list">
							<div class="seasons-item">
								<div class="seasons-totle">一季度</div>
								<div class="ranking">
									<div class="ranking-item" v-for="(item,index) in oneQuarterlyData" :key="index">
										<img v-if="index==0" class="ranking-img" src="./img/rongqi4.png" alt="" />
										<img v-if="index==1" class="ranking-img" src="./img/rongqi7.png" alt="" />
										<img v-if="index==2" class="ranking-img" src="./img/rongqi6.png" alt="" />
										<span>{{item.userName}}</span>
										<span>{{item.deptName}}</span>
									</div>
								</div>
							</div>
							<div class="seasons-item">
								<div class="seasons-totle">二季度</div>
								<div class="ranking">
									<div class="ranking-item" v-for="(item,index) in twoQuarterlyData" :key="index">
										<img v-if="index==0" class="ranking-img" src="./img/rongqi4.png" alt="" />
										<img v-if="index==1" class="ranking-img" src="./img/rongqi7.png" alt="" />
										<img v-if="index==2" class="ranking-img" src="./img/rongqi6.png" alt="" />
										<span>{{item.userName}}</span>
										<span>{{item.deptName}}</span>
									</div>
								</div>
							</div>
							<div class="seasons-item">
								<div class="seasons-totle">三季度</div>
								<div class="ranking">
									<div class="ranking-item" v-for="(item,index) in threeQuarterlyData" :key="index">
										<img v-if="index==0" class="ranking-img" src="./img/rongqi4.png" alt="" />
										<img v-if="index==1" class="ranking-img" src="./img/rongqi7.png" alt="" />
										<img v-if="index==2" class="ranking-img" src="./img/rongqi6.png" alt="" />
										<span>{{item.userName}}</span>
										<span>{{item.deptName}}</span>
									</div>
								</div>
							</div>
							<div class="seasons-item">
								<div class="seasons-totle">四季度</div>
								<div class="ranking">
									<div class="ranking-item" v-for="(item,index) in fourQuarterlyData" :key="index">
										<img v-if="index==0" class="ranking-img" src="./img/rongqi4.png" alt="" />
										<img v-if="index==1" class="ranking-img" src="./img/rongqi7.png" alt="" />
										<img v-if="index==2" class="ranking-img" src="./img/rongqi6.png" alt="" />
										<span>{{item.userName}}</span>
										<span>{{item.deptName}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="data-center">
					<div class="number-data">
						<div class="number-both">
							<div class="total-data">
								<div class="total-item">
									<h4 class="public-title">当月总业绩数据</h4>
									<img class="line-img" src="./img/rongqi2.png" alt="" />
									<div class="nums-list">
										<div class="nums-item">1</div>
										<div class="nums-item">2</div>
										<div class="nums-item">3</div>
										<div class="nums-item">4</div>
										<div class="nums-item">5</div>
										<div class="nums-item">8</div>
									</div>
								</div>
							</div>
							<div class="total-data">
								<div class="total-item">
									<h4 class="public-title">当月总锁期体量</h4>
									<img class="line-img" src="./img/rongqi2.png" alt="" />
									<div class="nums-list">
										<div class="nums-item" v-for="(item,index) in totalTiliang" :key="index">{{item}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="four-num">
							<h4 class="public-title">数据明细</h4>
							<img class="line-img" src="./img/rongqi2.png" alt="" />
							<div class="nums">
								<div class="nums-items">
									<div class="nums-items-title">当日核额</div>
									<div class="nums-items-data">
										<span class="nums-items-num">{{intention.millionsOfIntentions}}</span>
									</div>
								</div>
								<div class="nums-items">
									<div class="nums-items-title">当日锁期</div>
									<div class="nums-items-data">
										<span class="nums-items-num">{{intention.millionsOfIntroduction}}</span>
									</div>
								</div>
								<div class="nums-items">
									<div class="nums-items-title">当日新开千万意向量</div>
									<div class="nums-items-data">
										<span class="nums-items-num">{{intention.notMillionsOfIntentions}}</span>
									</div>
								</div>
								<div class="nums-items">
									<div class="nums-items-title">当日转介绍千万意向量</div>
									<div class="nums-items-data">
										<span class="nums-items-num">{{intention.notMillionsOfIntroduction}}</span>
									</div>
								</div>
								<div class="nums-items">
									<div class="nums-items-title">当日新开千万申请</div>
									<div class="nums-items-data">
										<span class="nums-items-num">{{uclear.auditAmountTenThousand}}</span>
									</div>
								</div>
								<div class="nums-items">
									<div class="nums-items-title">当日转介绍千万申请</div>
									<div class="nums-items-data">
										<span class="nums-items-num">{{uclear.auditAmountZero}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="month-data public-style">
						<h4 class="public-title">月度团队明细</h4>
						<img class="line-img" src="./img/rongqi2.png" alt="" />
						<table class="table">
							<tr>
								<th>团队</th>
								<th>破零人数</th>
								<th>绩优人数</th>
							</tr>
							<tr v-for="(item,index) in monthTeam" :key="index">
								<td>{{item.deptName}}</td>
								<td>{{item.breakingZero}}</td>
								<td>{{item.excellent}}</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="data-right">
					<div class="public-style">
						<h4 class="public-title">年度数据明细</h4>
						<img class="line-img" src="./img/rongqi2.png" alt="" />
						<div class="public-tables">
							<div class="seats-list">
								<div>坐席</div>
								<div v-for="(item,index) in yearSeatsData" :key="index">{{item.userName}}</div>
							</div>
							<div class="team-list">
								<div>团队</div>
								<div v-for="(item,index) in yearTeamData" :key="index">{{item.detpName}}</div>
							</div>
						</div>
					</div>
					<div class="public-style four-seasons">
						<h4 class="public-title">月度数据明细</h4>
						<img class="line-img" src="./img/rongqi2.png" alt="" />
						<div class="public-tables">
							<div class="seats-list">
								<div>坐席</div>
								<div v-for="(item,index) in monthSeatsData" :key="index">{{item.userName}}</div>
							</div>
							<div class="team-list">
								<div>团队</div>
								<div v-for="(item,index) in monthTeamData" :key="index">{{item.detpName}}</div>
							</div>
						</div>
					</div>
					<div class="public-style four-seasons">
						<h4 class="public-title">
							<span>月度锁期数据明细</span>
							<!-- <a-radio-group v-model="type" size="small">
								<a-radio-button value="1">坐席</a-radio-button>
								<a-radio-button value="2">团队</a-radio-button>
							</a-radio-group> -->
						</h4>
						<img class="line-img" src="./img/rongqi2.png" alt="" />
						<div class="public-tables">
							<div class="seats-list">
								<div>坐席</div>
								<div v-for="(item,index) in monthSeatsDatas" :key="index">{{item.userName}}</div>
							</div>
							<div class="team-list">
								<div>团队</div>
								<div v-for="(item,index) in monthTeamDatas" :key="index">{{item.detpName}}</div>
							</div>
						</div>
					</div>
					<!-- <div class="public-style">
						<h4 class="public-title">各年度数据</h4>
						<img class="line-img" src="./img/rongqi2.png" alt="" />
						<div class="public-table">
							<div class="table-title">
								<span>年份</span>
								<span>成员人数</span>
							</div>
							<div class="table-item other-color" v-for="(item,index) in otherYearData" :key="index">
								<span>{{item.year}}</span>
								<span>{{item.allMoney}}</span>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		TeamDataDetails,
		AllMoney,
		StaffRanking,
		AuditAmountDataDetails,
		DataDetails,
		AllLockPeriod,
		YearData,
		TeamRanking,
		LockPeriodRanking
	} from '@/api/datascreen/datascreen'
	export default {
		name: 'datascreen',
		data() {
			return {
				year: '',
				month: '',
				day: '',
				newMonthNum: '',
				uclear: {},
				intention: {},
				totalTiliang: [],
				yearSeatsData: [], //坐席年度数据明细
				monthSeatsData: [], //坐席年度数据明细
				yearTeamData: [], //年度团队数据明细
				monthTeamData: [], //月度团队数据明细
				otherYearData: [], //各年度数据统计及
				monthTeam: [], //阅读团队明细
				type: '1',
				newTeamData: [], //当日核额业绩体量
				newTeamData1: [], //当日锁期业绩体量
				monthSeatsDatas: [], //当月总锁期体量-坐席
				monthTeamDatas: [], //当月总锁期体量-团队
				oneQuarterlyData: [],
				twoQuarterlyData: [],
				threeQuarterlyData: [],
				fourQuarterlyData: [],
				weekDay: ''
			}
		},
		created() {
			this.gettims()
			this.getTeamDataDetails()
			this.getAllMoney()
			this.getStaffRanking()
			this.getAuditAmountDataDetails()
			this.getDataDetails()
			this.getAllLockPeriod()
			// this.getYearData()
			this.getTeamRanking()
			this.getLockPeriodRanking()
			this.getTeamRankingyear()
			this.getTeamRankingmonth()
			this.getStaffRankingymonth()
			this.getLockPeriodRankingTeam()
			this.getLockPeriodRankingSeats()
			this.getStaffRankingone()
			this.getStaffRankingtwo()
			this.getStaffRankingthree()
			this.getStaffRankingfour()
		},
		methods: {
			// 当日核额业绩体量
			getTeamRanking() {
				TeamRanking({
					createBeginTime: this.year + '-' + this.month + '-' + (this.day - 1) + ' 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + (this.day - 1) + ' 23:59:59',
					num: 6,
					type: 2
				}).then(res => {
					console.log('当日核额业绩体量-1', res);
					this.newTeamData = res.data;
				})
			},
			//  当日锁期业绩体量
			getLockPeriodRanking() {
				LockPeriodRanking({
					createBeginTime: this.year + '-' + this.month + '-' + (this.day - 2) + ' 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + (this.day - 2) + ' 23:59:59',
					num: 6,
					type: 2
				}).then(res => {
					console.log('当日锁期业绩体量', res);
					this.newTeamData1 = res.data;
				})
			},
			// 年度数据明细-团队业绩排名
			getTeamRankingyear() {
				TeamRanking({
					createBeginTime: this.year + '-01-01 00:00:00',
					createEndTime: this.year + '-12-31 23:59:59',
					num: 6,
					type: 2
				}).then(res => {
					console.log('年度数据明细-团队业绩排名', res);
					this.yearTeamData = res.data;
				})
			},
			// 年度数据明细-坐席业绩排名
			getStaffRanking() {
				StaffRanking({
					createBeginTime: this.year + '-01-01 00:00:00',
					createEndTime: this.year + '-12-31 23:59:59',
					num: 6,
					type: 1
				}).then(res => {
					console.log('年度数据明细-坐席业绩排名', res)
					this.yearSeatsData = res.data;
				})
			},
			// 四季度排名-1
			getStaffRankingone() {
				StaffRanking({
					createBeginTime: this.year + '-01-01 00:00:00',
					createEndTime: this.year + '-03-31 23:59:59',
					num: 3,
					type: 1
				}).then(res => {
					console.log('一季度-坐席业绩排名', res)
					this.oneQuarterlyData = res.data;
				})
			},
			// 四季度排名-2
			getStaffRankingtwo() {
				StaffRanking({
					createBeginTime: this.year + '-04-01 00:00:00',
					createEndTime: this.year + '-06-31 23:59:59',
					num: 3,
					type: 1
				}).then(res => {
					console.log('二季度-坐席业绩排名', res)
					this.twoQuarterlyData = res.data;
				})
			},
			// 四季度排名-3
			getStaffRankingthree() {
				StaffRanking({
					createBeginTime: this.year + '-07-01 00:00:00',
					createEndTime: this.year + '-09-31 23:59:59',
					num: 3,
					type: 1
				}).then(res => {
					console.log('三季度-坐席业绩排名', res)
					this.threeQuarterlyData = res.data;
				})
			},
			// 四季度排名-4
			getStaffRankingfour() {
				StaffRanking({
					createBeginTime: this.year + '-10-01 00:00:00',
					createEndTime: this.year + '-12-31 23:59:59',
					num: 3,
					type: 1
				}).then(res => {
					console.log('四季度-坐席业绩排名', res)
					this.fourQuarterlyData = res.data;
				})
			},
			getTeamRankingmonth() {
				TeamRanking({
					createBeginTime: this.year + '-' + this.month + '-01 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + this.newMonthNum + ' 23:59:59',
					num: 6,
					type: 2
				}).then(res => {
					console.log('月度数据明细-团队业绩排名', res);
					this.monthTeamData = res.data;
				})
			},

			//  当月总锁期体量
			getLockPeriodRankingSeats() {
				LockPeriodRanking({
					createBeginTime: this.year + '-' + this.month + '-01 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + this.newMonthNum + ' 23:59:59',
					num: 6,
					type: 1
				}).then(res => {
					console.log('当月总锁期体量-坐席', res);
					this.monthSeatsDatas = res.data;
				})
			},
			//  当月总锁期体量
			getLockPeriodRankingTeam() {
				LockPeriodRanking({
					createBeginTime: this.year + '-' + this.month + '-01 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + this.newMonthNum + ' 23:59:59',
					num: 6,
					type: 2
				}).then(res => {
					console.log('当月总锁期体量-团队', res);
					this.monthTeamDatas = res.data;
				})
			},
			// 
			getTeamDataDetails() {
				TeamDataDetails({
					createBeginTime: this.year + '-' + this.month + '-' + '01 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + this.newMonthNum + ' 23:59:59',
					num: 6,
					type: 2
				}).then(res => {
					console.log('月度团队明细-团队月度明细', res);
					this.monthTeam = res.data;
				})
			},
			// 当月总业绩-当日总业绩-统计总业绩
			getAllMoney() {
				AllMoney({
					createBeginTime: this.year + '-' + this.month + '-' + '01 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + this.newMonthNum + ' 23:59:59',
					num: 10,
					type: 2
				}).then(res => {
					console.log('当月总业绩-当日总业绩-统计总业绩', res)
				})
			},

			// 年度数据明细
			getStaffRankingymonth() {
				StaffRanking({
					createBeginTime: this.year + '-' + this.month + '-' + '01 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + this.newMonthNum + ' 23:59:59',
					num: 6,
					type: 1
				}).then(res => {
					console.log('月度度数据明细-坐席业绩排名-111', res)
					this.monthSeatsData = res.data;
				})
			},
			// 数据明细(核额数量) 
			getAuditAmountDataDetails() {
				AuditAmountDataDetails({
					createBeginTime: this.year + '-' + this.month + '-' + '01 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + this.newMonthNum + ' 23:59:59',
					num: 6,
					type: 2
				}).then(res => {
					console.log('数据明细(核额数量)', res)
					this.uclear = res.data;
				})
			},
			// 
			getDataDetails() {
				DataDetails({
					createBeginTime: this.year + '-' + this.month + '-' + '01 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + this.newMonthNum + ' 23:59:59',
					num: 6,
					type: 2
				}).then(res => {
					console.log('数据明细(意向-转介绍)', res)
					this.intention = res.data;
				})
			},
			// 当月总锁期体量-获取总锁期(提款)
			getAllLockPeriod() {
				AllLockPeriod({
					createBeginTime: this.year + '-' + this.month + '-' + '01 00:00:00',
					createEndTime: this.year + '-' + this.month + '-' + this.newMonthNum + ' 23:59:59',
					num: 6,
					type: 2
				}).then(res => {
					console.log('当月总锁期体量-获取总锁期(提款)', res);
					// this.totalTiliang = res.data;
					let num = 6 - String(res.data).length;
					for (let i = 1; i <= num; i++) {
						this.totalTiliang.push(0);
					}
					for (let i = 0; i < String(res.data).length; i++) {
						this.totalTiliang.push(String(res.data)[i]);
					}
				})
			},
			// 统计各年度数据
			getYearData() {
				YearData().then(res => {
					console.log('统计各年度数据', res)
					this.otherYearData = res.data;
				})
			},
			gettims() {
				var date = new Date();
				this.year = date.getFullYear();
				this.month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
				this.day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
				this.newMonthNum = new Date(this.year, this.month, 0).getDate()
				let arr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '周日']
				this.weekDay = arr[date.getDay() - 1]
				// console.log('当前时间', this.year, this.month, this.day, this.newMonthNum)
			}
		}
	}
</script>


<style>
	/* .ant-pro-basicLayout-content {
		margin: 24px 0;
	} */

	* {
		margin: 0;
		padding: 0;
	}

	h1 {
		font-size: 40px;
		color: #fff;
		font-weight: bold;
	}

	.data-content {
		width: 100%;
		/* height: 100vh; */
		background: url('./img/rongqi.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		padding-bottom: 30px;
	}

	.title {
		width: 100%;
		height: 100vh;
		background: url('./img/rongqititle.png') no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: 48px;
		left: 0;
	}

	.title-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		line-height: 120px;
	}

	.title-content div {
		width: 30%;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
	}

	.data-content-info {
		position: relative;
		z-index: 1;
	}

	.data-info {
		display: flex;
		justify-content: space-around;
		align-items: flex-start;
	}

	.data-left,
	.data-right {
		width: 28%;
		height: 100%;
		/* border: 1px solid red; */
		color: #Fff;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-around;
	}

	.data-center {
		width: 40%;
	}

	.public-style {
		background: rgba(38, 95, 255, 0.1);
		padding: 16px 16px 0;
		height: 370px;
	}

	.public-title {
		color: #fff;
		font-weight: bold;
		font-size: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.line-img {
		width: 100%;
		margin-bottom: 5px;
	}

	.public-tables {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.team-list,
	.seats-list {
		width: 50%;
	}

	.public-tables div {
		padding: 0 10px;
		line-height: 40px;
	}

	.seats-list div:nth-child(1) {
		padding-left: 10px;
		background: rgba(38, 95, 255, 0.2);
	}

	.team-list div:nth-child(1) {
		padding-left: 10px;
		background: rgba(38, 95, 255, 0.2);
	}

	.table-title,
	.table-titles {
		background: rgba(38, 95, 255, 0.2);
		line-height: 40px;
		padding: 0 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.table-titles span {
		width: 33%;
	}

	.table-title span {
		width: 50%;
	}

	.table-item,
	.table-items {
		line-height: 40px;
		padding: 0 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.table-items span {
		width: 33%;
	}

	.table-item span {
		width: 50%;
	}

	.table-item span:nth-child(2) {
		font-family: PangMenZhengDao-3, PangMenZhengDao-3;
		color: #EC4F30;
	}

	.four-seasons {
		margin: 20px 0;
		/* padding-bottom: 20px; */
	}

	.seasons-list {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.seasons-item {
		width: 48%;
		background: rgba(38, 95, 255, 0.2);
		padding: 10px;
		margin-bottom: 20px;
	}

	.seasons-totle {
		color: #11F6F2;
		text-align: center;
	}

	.ranking-item {
		display: flex;
		justify-content: space-around;
		align-items: center;
		line-height: 40px;
	}

	.ranking-item span {
		width: 45%;
		display: inline-block;
	}

	.ranking-img {
		width: 22px;
		height: 22px;
	}


	.other-color span:nth-child(2) {
		color: #fff;
	}

	.month-data {
		padding: 16px;
	}

	.table {
		width: 100%;
		color: #fff;
		margin-top: 10px;
	}

	.table tr th {
		line-height: 40px;
		text-align: center;
		font-size: 16px;
	}

	.table tr td {
		line-height: 40px;
		text-align: center;
		font-size: 16px;
	}

	.number-data {
		background: rgba(38, 95, 255, 0.1);
		padding: 32px 24px 0;
		margin-bottom: 24px;
	}

	.number-both {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.total-data {
		width: 48%;
	}

	.nums-list {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.nums-item {
		width: 50px;
		height: 80px;
		background: url('./img/rongqi5.png') no-repeat;
		background-size: 100% 100%;
		font-size: 40px;
		color: #fff;
		line-height: 80px;
		text-align: center;
	}

	.nums {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 20px;
	}

	.nums-items {
		width: 48%;
		background: rgba(38, 95, 255, 0.1);
		color: #fff;
		padding: 20px;
		margin-bottom: 20px;
	}

	.four-num {
		margin-top: 20px;
	}

	.nums-items-title {
		font-size: 16px;
		font-weight: bold;
	}

	.nums-items-data {}

	.nums-items-data img {
		width: 12px;
		height: 12px;
	}

	.nums-items-num {
		vertical-align: middle;
		font-family: PangMenZhengDao-3, PangMenZhengDao-3;
		font-size: 25px;
		color: #FF8C19;
		margin-right: 20px;
	}

	.percentage {
		vertical-align: middle;
		font-family: PangMenZhengDao-3, PangMenZhengDao-3;
		color: #0DFBE2;
	}
</style>