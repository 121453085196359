import request from '@/utils/request'


// 查询录音列表
export function listRecording(query) {
  return request({
    url: '/recording/recording/list',
    method: 'get',
    params: query
  })
}

// 查询录音分页
export function pageRecording(query) {
  return request({
    url: '/recording/recording/page',
    method: 'get',
    params: query
  })
}

// 查询录音详细
export function getRecording(data) {
  return request({
    url: '/recording/recording/detail',
    method: 'get',
    params: data
  })
}

// 新增录音
export function addRecording(data) {
  return request({
    url: '/recording/recording/add',
    method: 'post',
    data: data
  })
}

// 修改录音
export function updateRecording(data) {
  return request({
    url: '/recording/recording/edit',
    method: 'post',
    data: data
  })
}

// 删除录音
export function delRecording(data) {
  return request({
    url: '/recording/recording/delete',
    method: 'post',
    data: data
  })
}
