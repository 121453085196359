import request from '@/utils/request'


// 查询月度报列表
export function listForms(query) {
	return request({
		url: '/report/report-forms/list',
		method: 'get',
		params: query
	})
}

// 查询月度报分页
export function pageForms(query) {
	return request({
		url: '/report/report-forms/page',
		method: 'get',
		params: query
	})
}

// 查询月度报详细
export function getForms(data) {
	return request({
		url: '/report/report-forms/detail',
		method: 'get',
		params: data
	})
}

// 新增月度报
export function addForms(data) {
	return request({
		url: '/report/report-forms/add',
		method: 'post',
		data: data
	})
}

// 修改月度报
export function updateForms(data) {
	return request({
		url: '/report/report-forms/edit',
		method: 'post',
		data: data
	})
}

// 删除月度报
export function delForms(data) {
	return request({
		url: '/report/report-forms/delete',
		method: 'post',
		data: data
	})
}

// 团队核额数据
export function teamData(data) {
	return request({
		url: '/report/report-forms/getTeamData',
		method: 'get',
		params: data
	})
}

// 团队坐席核额数据
export function seatsDate(data) {
	return request({
		url: '/report/report-forms/getSeatsDate',
		method: 'get',
		params: data
	})
}

// 区域列表
export function regionList(data) {
	return request({
		url: '/system/dept/getRegionList',
		method: 'get',
		params: data
	})
}