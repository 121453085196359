<template>
	<a-drawer title="查看" width="45%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
		<a-form-model ref="form" :model="form" :rules="rules">
			<a-row :gutter="24">
				<a-col :md="8" :sm="24">
					<a-form-item label="所属团队" prop="regionName">
						<a-input v-model="form.regionName" disabled placeholder="所属团队" allow-clear />
					</a-form-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-item label="所属坐席" prop="userName">
						<a-input v-model="form.userName" disabled placeholder="所属坐席" allow-clear />
					</a-form-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-item label="客户名称" prop="name">
						<a-input v-model="form.name" disabled placeholder="客户名称" allow-clear />
					</a-form-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-model-item label="手机号" prop="phone">
						<a-input v-model="form.phone" disabled placeholder="手机号" />
					</a-form-model-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-model-item label="工作单位" prop="work">
						<a-input v-model="form.work" disabled placeholder="工作单位" />
					</a-form-model-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-model-item label="地区" prop="area">
						<a-input v-model="form.area" disabled placeholder="地区" />
					</a-form-model-item>
				</a-col>
				<a-col :md="8" :sm="24">
					<a-form-model-item label="创建时间" prop="updateTime">
						<a-input v-model="form.updateTime" disabled placeholder="创建时间" />
					</a-form-model-item>
				</a-col>
			</a-row>

			<a-radio-group v-model="tab" button-style="solid" size="large">
				<a-radio-button :value="1">跟进记录</a-radio-button>
				<a-radio-button :value="2">核额记录</a-radio-button>
				<a-radio-button :value="3">呼叫记录</a-radio-button>
			</a-radio-group>

			<div class="contents">
				<div class="record-history" v-if="tab==1">
					<div class="submint-follow">
						<div class="follow-content">
							<div>跟进内容：</div>
							<a-textarea v-model="followUpRecords" placeholder="请输入跟进记录" style="width: 80%;"
								:auto-size="{ minRows: 5, maxRows: 5 }" />
						</div>
						<div class="next-time">
							<span>下次跟进时间：</span>
							<a-date-picker @change="getSelectTime" v-model="followUpTime" :disabled-date="disabledDate"
								:disabled-time="disabledDateTime" placeholder="请选择下次跟进时间" style="width: 80%;"
								allow-clear></a-date-picker>
						</div>
						<div class="publish">
							<a-button type="primary" @click="submintFollowAdd" :loading="iconLoading">发表</a-button>
						</div>
					</div>
					<div class="follow-list">
						<div class="record-history-list" v-for="(item,index) in followHistoryList" :key="index">
							<div class="record-history-user">
								<span>{{item.userName}}</span>
								<a-divider type="vertical" />
								<span>更新客户信息时间</span>
								<a-divider type="vertical" />
								<span>{{item.updateTime}}</span>
							</div>
							<div class="record-history-content">
								<div class="history-content-title">
									<div>跟进内容</div>
									<div>{{item.followUpRecords}}</div>
								</div>
								<div class="history-content-title">
									<div>下次跟进时间</div>
									<div>{{item.followUpTime}}</div>
								</div>
							</div>
						</div>
						<a-empty v-if="followHistoryList.length==0 && tab==1" />
					</div>
					<!-- :model="form" :rules="rules" -->
					<div class="custom-info">
						<h4>客户情况</h4>
						<a-form-model ref="form" title="跟进记录">
							<a-row :gutter="24">
								<a-col :md="8" :sm="24">
									<a-form-item label="近一年开票金额(元)" prop="userName">
										<a-input v-model="form.invoiceAmount" placeholder="所属坐席" allow-clear />
									</a-form-item>
								</a-col>
								<a-col :md="8" :sm="24">
									<a-form-item label="近一年纳税金额(元)" prop="userName">
										<a-input v-model="form.taxAmount" placeholder="所属坐席" allow-clear />
									</a-form-item>
								</a-col>
								<a-col :md="8" :sm="24">
									<a-form-item label="是否加微信成功" prop="userName">
										<!-- <a-input v-model="form.addWechat==1?'是':'否'" disabled placeholder="所属坐席" allow-clear /> -->
										<a-radio-group name="radioGroup" v-model="form.addWechat">
											<a-radio :value="1">是</a-radio>
											<a-radio :value="0">否</a-radio>
										</a-radio-group>
									</a-form-item>
								</a-col>
								<a-col :md="8" :sm="24">
									<a-form-item label="近期是否有资金需求" prop="userName">
										<!-- <a-input v-model="form.fundingRequirements==1?'是':'否'" disabled placeholder="所属坐席" allow-clear /> -->
										<a-radio-group name="radioGroup" v-model="form.fundingRequirements">
											<a-radio :value="1">是</a-radio>
											<a-radio :value="0">否</a-radio>
										</a-radio-group>
									</a-form-item>
								</a-col>
								<a-col :md="8" :sm="24">
									<a-form-item label="是否可提供维转介绍线索" prop="userName">
										<!-- <a-input v-model="form.clue==1?'是':'否'" disabled placeholder="所属坐席" allow-clear /> -->
										<a-radio-group name="radioGroup" v-model="form.clue">
											<a-radio :value="1">是</a-radio>
											<a-radio :value="0">否</a-radio>
										</a-radio-group>
									</a-form-item>
								</a-col>
							</a-row>
						</a-form-model>
						<div class="publish1">
							<a-button type="primary" @click="submitForm" :loading="iconLoading">保存</a-button>
						</div>
					</div>
					<div class="intelligence-call custom-info">
						<h4>智能外呼</h4>
						<a-input-search placeholder="请输入" v-model="phone" type="number" style="width: 40%;" enter-button="拨打"
							size="large" @search="callPhone" />
					</div>
				</div>
				<div class="customer-situation" v-if="tab==2">
					<a-table style="width: 100%;" :columns="columns2" :data-source="recordList"></a-table>
				</div>
				<div class="call-records" v-if="tab==3">
					<a-table :columns="columns1" :data-source="callList"></a-table>
				</div>
			</div>
		</a-form-model>
	</a-drawer>
</template>

<script>
	import {
		rosterMy,
		addRoster,
		updateRoster,
		followHistory,
		callList,
		auditamount,
		followAdd,
		callMobile
	} from '@/api/roster/roster'
	import moment from 'moment';
	export default {
		name: 'CreateForm',
		props: {},
		components: {},
		data() {
			return {
				submitLoading: false,
				formTitle: '',
				tab: 1,
				// 表单参数
				form: {
					id: null,
					name: null,
					phone: null,
					work: null,
					area: null,
					notes: null,
					region: null,
					receive: null,
					callNum: null,
					lastCallTime: null,
					followUpRecords: null,
					followUpTime: null,
					invoiceAmount: null,
					taxAmount: null,
					addWechat: null,
					regionName: null,
					fundingRequirements: null,
					clue: null
				},
				// 1增加,2修改
				formType: 1,
				open: false,
				rules: {
					name: [{
						required: true,
						message: '客户名称不能为空',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '手机号不能为空',
						trigger: 'blur'
					}],
					work: [{
						required: true,
						message: '工作单位不能为空',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '地区不能为空',
						trigger: 'blur'
					}],
					notes: [{
						required: true,
						message: '备注不能为空',
						trigger: 'blur'
					}],
					region: [{
						required: true,
						message: '所属区域，外键{sys_dept.id}不能为空',
						trigger: 'blur'
					}],
					receive: [{
						required: true,
						message: '是否被领取,0-否,1-是不能为空',
						trigger: 'blur'
					}],
					callNum: [{
						required: true,
						message: '通话总次数不能为空',
						trigger: 'blur'
					}],
					followUpRecords: [{
						required: true,
						message: '最新跟进记录不能为空',
						trigger: 'blur'
					}],
					invoiceAmount: [{
						required: true,
						message: '开票金额(万)不能为空',
						trigger: 'blur'
					}],
					taxAmount: [{
						required: true,
						message: '纳税金额(万)不能为空',
						trigger: 'blur'
					}],
					addWechat: [{
						required: true,
						message: '是否加微信成功,0-否,1-是不能为空',
						trigger: 'blur'
					}],
					fundingRequirements: [{
						required: true,
						message: '是否有资金需求,0-否,1-是不能为空',
						trigger: 'blur'
					}],
					clue: [{
						required: true,
						message: '是否可提供为转介绍线索,0-否,1-是不能为空',
						trigger: 'blur'
					}]
				},
				columns1: [{
						title: '序号',
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '呼叫录音',
						align: 'center',
						dataIndex: 'tapeFile',
						key: 'tapeFile',
					},
					{
						title: '时长(s)',
						align: 'center',
						dataIndex: 'callTime',
						key: 'callTime',
					},
					{
						title: '操作人',
						align: 'center',
						dataIndex: 'userName',
						key: 'userName',
					},
					{
						title: '操作时间',
						align: 'center',
						dataIndex: 'createTime',
						key: 'createTime',
					}
				],
				columns2: [{
						title: '序号',
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '银行',
						align: 'center',
						dataIndex: 'bankName',
						key: 'bankName',
					},
					{
						title: '核额金额',
						align: 'center',
						dataIndex: 'money',
						key: 'money',
					},
					{
						title: '操作人',
						align: 'center',
						dataIndex: 'userName',
						key: 'userName',
					},
					{
						title: '操作时间',
						align: 'center',
						dataIndex: 'createTime',
						key: 'createTime',
					}
				],
				followHistoryList: [],
				callList: [],
				recordList: [],
				iconLoading: false,
				followUpRecords: '',
				followUpTime: '',
				record: {},
				phone: '',
			}
		},
		filters: {},
		created() {},
		computed: {},
		watch: {},
		mounted() {},
		methods: {
			onClose() {
				this.open = false
				this.followUpRecords = '';
				this.record = {};
				this.phone = '';
			},
			// 取消按钮
			cancel() {
				this.open = false
				this.reset()
			},
			// 表单重置
			reset() {
				this.formType = 1
				this.form = {
					id: null,
					name: null,
					phone: null,
					work: null,
					area: null,
					notes: null,
					region: null,
					receive: null,
					callNum: null,
					lastCallTime: null,
					followUpRecords: null,
					followUpTime: null,
					invoiceAmount: null,
					taxAmount: null,
					addWechat: null,
					fundingRequirements: null,
					clue: null
				}
				this.$nextTick(() => {
					if (this.$refs.form) {
						this.$refs.form.resetFields()
					}
				})
			},
			/** 新增按钮操作 */
			handleAdd(row) {
				this.reset()
				this.formType = 1
				this.open = true
				this.formTitle = '添加'
			},
			/** 新增按钮操作 */
			handleLook(record) {
				this.record = record;
				this.reset()
				this.formType = 1
				this.open = true
				this.formTitle = '查看'
				this.getFollowHistory(record.rosterId)
				this.getCallList(record.rosterId)
				this.getauditamount(record.rosterId)
				rosterMy({
					"id": record.id
				}).then(response => {
					console.log('查看', response)
					if (response.data) {
						this.form = response.data
					}
				})
			},
			getFollowHistory(id) {
				followHistory({
					rosterId: id
				}).then(res => {
					console.log('跟进记录', res);
					if (res.code == '20001') {
						this.followHistoryList = res.data;
					}
				})
			},
			// 通话记录
			getCallList(id) {
				callList({
					rosterId: id
				}).then(res => {
					console.log('通话记录', res)
					if (res.success) {
						this.callList = res.data;
					}
				})
			},
			// 核额记录
			getauditamount(id) {
				auditamount({
					rosterId: id
				}).then(res => {
					console.log('核额记录', res);
					if (res.success) {
						this.recordList = res.data;
					}
				})
			},
			// 提交跟进
			submintFollowAdd() {
				if (!this.followUpRecords) {
					this.$message.error('请输入跟进内容');
					return false;
				}
				if (!this.followUpTime) {
					this.$message.error('请选择下次跟进时间');
					return false;
				}
				this.iconLoading = true;
				followAdd({
					followUpRecords: this.followUpRecords,
					followUpTime: this.followUpTime,
					rosterId: this.record.rosterId,
				}).then(res => {
					console.log('提交跟进', res)
					this.iconLoading = false;
					if (res.success) {
						this.followUpRecords = '';
						this.followUpTime = '';
						this.getFollowHistory(this.record.rosterId)
						this.$emit('ok')
					}
				})
			},
			getSelectTime(e) {
				this.followUpTime = e.format('YYYY-MM-DD hh:mm:ss')
			},
			// 拨打电话
			callPhone() {
				callMobile({
					phone: this.phone,
					rosterId: this.record.rosterId
				}).then(res => {
					if (res.success) {
						console.log('拨打电话', res);
						this.phone = '';
						this.$message.success(res.message)
					}
				})
			},
			/** 修改按钮操作 */
			handleUpdate(row, ids) {
				this.reset()
				this.formType = 2
				const id = row ? row.id : ids
				rosterMy({
					"id": id
				}).then(response => {
					this.form = response.data
					this.open = true
					this.formTitle = '修改'
				})
			},
			/** 提交按钮 */
			submitForm: function() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.submitLoading = true
						if (this.form.id !== undefined && this.form.id !== null) {
							this.form.id = this.form.rosterId;
							this.form.rosterId = '';
							updateRoster(this.form).then(response => {
								this.$message.success(
									'修改成功',
									3
								)
								this.open = false
								this.$emit('ok')
							}).finally(() => {
								this.submitLoading = false
							})
						} else {
							addRoster(this.form).then(response => {
								this.$message.success(
									'新增成功',
									3
								)
								this.open = false
								this.$emit('ok')
							}).finally(() => {
								this.submitLoading = false
							})
						}
					} else {
						return false
					}
				})
			},
			disabledDate(current) {
				// Can not select days before today and today
				return current && current < moment().endOf('day');
			},
			disabledDateTime() {
				return {
					disabledHours: () => this.range(0, 24).splice(4, 20),
					disabledMinutes: () => this.range(30, 60),
					disabledSeconds: () => [55, 56],
				};
			},
		}
	}
</script>

<style type="text/css">
	.contents {
		margin-top: 32px;
	}

	.record-history {}

	.follow-content {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: 20px;
	}

	.follow-content :nth-child(1) {
		width: 140px;
	}

	.next-time span {
		display: inline-block;
		width: 140px;
	}

	.publish {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.publish1 {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-bottom: 20px;
	}

	.follow-list {
		padding: 32px 20px;
		border: 1px solid #eee;
		margin-top: 20px;
		border-radius: 16px;
	}

	.custom-info {
		padding: 32px 20px 0;
		border: 1px solid #eee;
		border-radius: 16px;
		margin-top: 20px;
	}

	.intelligence-call {
		padding: 32px 20px;
	}

	.record-history-list {
		padding: 20px;
		border: 1px solid #eee;
		border-radius: 16px;
	}

	.record-history-user {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 20px;
	}

	.record-history-user span:first-child {
		font-weight: bold;
	}

	.record-history-content {
		background: #eee;
		padding: 20px;
		margin-bottom: 20px;
	}

	.history-content-title {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.history-content-title:first-child {
		margin-bottom: 20px;
	}

	.history-content-title div:nth-child(1) {
		width: 150px;
	}

	.history-content-title div:nth-child(2) {
		width: 600px;
	}

	.customer-situation {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		flex-wrap: wrap;
	}

	.customer-situation-item {
		margin-bottom: 20px;
		width: 32%;
	}

	.customer-situation-item-title {
		margin-right: 10px;
	}

	.customer-situation-item:nth-child(3n-1) {
		margin-left: 2%;
		margin-right: 2%;
	}
</style>