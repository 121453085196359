<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="申请人" prop="userName">
								<a-input v-model="queryParam.userName" placeholder="请输入客户名称" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="报销类型" prop="reimbursementTypeId">
								<a-select v-model="queryParam.reimbursementTypeId" placeholder="请选择报销类型" allow-clear>
									<a-select-option :value="item.id" v-for="(item,index) in reimbursementList" :key="index">
										{{item.name}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="报销时间" prop="reimbursementTime">
								<a-date-picker @change="onChangeTimes($event,'reimbursementTime')"
									v-model="queryParam.reimbursementTime" placeholder="请选择报销时间" allow-clear
									style="width: 100%;"></a-date-picker>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="发起审批时间" prop="createTime">
								<a-date-picker @change="onChangeTimes($event,'createTime')" v-model="queryParam.createTime"
									placeholder="请选择审批时间" allow-clear style="width: 100%;"></a-date-picker>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="审批状态" prop="status">
								<a-select v-model="queryParam.status" placeholder="请选择审批状态" allow-clear>
									<a-select-option :value="item.value" v-for="(item,index) in statusList" :key="index">
										{{item.label}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 6 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<a-button type="primary" @click="$refs.CreateModel.handleAdd('',0)">新增</a-button>
				<a-divider type="vertical" />
				<a-button type="danger" :disabled="multiple" @click="handleDelete(selectedRowKeys)">批量删除</a-button>
				<!-- <table-setting :style="{float: 'right'}" :table-size.sync="tableSize" v-model="columns"
					:refresh-loading="loading" @refresh="getList" /> -->
			</div>
			<!-- 增加修改 -->
			<!-- <create-form ref="CreateModel" @ok="getList" /> -->
			<create-model ref="CreateModel" @ok="getList"></create-model>
			<look-model ref="LookModel"></look-model>
			<!-- 数据展示 -->
			<a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
				:bordered="tableBordered">
				<span slot="createTime" slot-scope="text, record">
					{{ parseTime(record.createTime) }}
				</span>
				<span slot="img" slot-scope="text, record">
					<img class="imgs" :src="item.url" v-for="(item,index) in filterImg(record.img)" :key="index" alt="" />
				</span>
				<span slot="operation" slot-scope="text, record">
					<a-divider type="vertical" />
					<a @click="$refs.CreateModel.handleAdd(record,1)">查看</a>
					<a-divider type="vertical" />
					<a @click="$refs.CreateModel.handleAdd(record,2)" v-if="record.status==0 || record.status==3">编辑</a>
					<a-divider type="vertical" />
					<a @click="handleDelete(record)">删除</a>
					<a-divider type="vertical" v-if="record.status==3" />
					<a v-if="record.status==3" @click="handleAgaininitiate(record)">重新发起审批</a>
					<a-divider type="vertical" />
					<a @click="$refs.LookModel.handleAdd(record)">查看进度</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		reimbursement,
		approval,
		approvalAdd,
		approvalDelete
	} from '@/api/call/record'
	import CreateModel from './modules/CreateModel'
	import LookModel from './modules/LookModel'
	import {
		tableMixin
	} from '@/store/table-mixin'

	export default {
		name: 'Record',
		components: {
			CreateModel,
			LookModel
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					userName: undefined,
					reimbursementTypeId: undefined,
					reimbursementTime: undefined,
					createTime: undefined,
					status: undefined,
					pageNum: 1,
					pageSize: 10
				},
				columns: [{
						title: '序号',
						dataIndex: 'id',
						ellipsis: true,
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '申请人',
						dataIndex: 'userName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '报销类型',
						dataIndex: 'reimbursementTypeName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '报销金额 (元)',
						dataIndex: 'money',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '报销时间',
						dataIndex: 'reimbursementTime',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '上传影像',
						dataIndex: 'img',
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'img'
						},
					},
					{
						title: '备注',
						dataIndex: 'notes',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '审批状态',
						dataIndex: 'status',
						ellipsis: true,
						align: 'center',
						customRender: function(text, record, index) {
							// 0-待审批,1-审批中,2-审批通过,3-审批拒绝
							if (text == 0) {
								return '待审批'
							} else if (text == 1) {
								return '审批中'
							} else if (text == 2) {
								return '审批通过'
							} else if (text == 3) {
								return '审批拒绝'
							}
						}
					},
					{
						title: '创建时间',
						dataIndex: 'createTime',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'operation',
						width: '18%',
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				selectTimes: undefined,
				reimbursementList: [],
				statusList: [{
						label: '待审批',
						value: 0
					},
					{
						label: '审批中',
						value: 1
					},
					{
						label: '审批通过',
						value: 2
					},
					{
						label: '审批拒绝',
						value: 3
					},
				], // 0-待审批,1-审批中,2-审批通过,3-审批拒绝
			}
		},
		filters: {},
		created() {
			this.getList()
			this.getReimbursement()
		},
		computed: {},
		watch: {},
		methods: {
			// 选择统计时间
			onChangeTimes(e, type) {
				this.queryParam[type] = e.format('YYYY-MM-DD')
			},
			/** 查询呼叫记录列表 */
			getList() {
				this.loading = true
				approval(this.queryParam).then(response => {
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},
			// 报销类型
			getReimbursement() {
				reimbursement().then(response => {
					console.log('报销类型', response)
					this.reimbursementList = response.data.records;
				})
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					userName: undefined,
					reimbursementTypeId: undefined,
					reimbursementTime: undefined,
					createTime: undefined,
					status: undefined,
					pageNum: 1,
					pageSize: 10
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中的' + this.ids.length + '条数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return approvalDelete(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
					}
				})
			},
			// 重新发起审批
			handleAgaininitiate(record) {
				let that = this;
				this.$confirm({
					title: '提示',
					content: '是否重新发起该审批？',
					onOk() {
						return approvalAdd(record)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success('创建成功')
							})
					},
					onCancel() {
						//取消清空删除ids列表
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('call/call-record/export', {
							...that.queryParam
						}, `呼叫记录_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			},
			// 图片过滤
			filterImg(arr) {
				if (arr) {
					return JSON.parse(arr);
				}
			}
		}
	}
</script>

<style scoped>
	.imgs {
		width: 40px;
		height: 40px;
	}
</style>