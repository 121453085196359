<template>
	<a-drawer :title="formTitle" width="50%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
		<a-table :columns="columns" :data-source="data">
			<span slot="operation" slot-scope="text, record">
				<a @click="$refs.WithdrawalDetails.openModel(record)">提款明细</a>
			</span>
		</a-table>
		<withdrawal-details ref="WithdrawalDetails" @ok="getList(record.rosterId)"></withdrawal-details>
		<div class="bottom-control">
			<a-space>
				<a-button type="primary" :loading="submitLoading" @click="submitForm">
					保存
				</a-button>
				<a-button type="dashed" @click="cancel">
					取消
				</a-button>
			</a-space>
		</div>
	</a-drawer>
</template>

<script>
	import {
		getCustomer,
		addCustomer,
		updateCustomer,
		auditlist,
		auditpage
	} from '@/api/drawing/customer'
	import WithdrawalDetails from './WithdrawalDetails'
	export default {
		name: 'CreateForm',
		props: {},
		components: {
			WithdrawalDetails
		},
		data() {
			return {
				submitLoading: false,
				formTitle: '',
				// 表单参数
				form: {
					userIds: null,
					pageNum: 1,
					pageSize: 15,
				},
				// 1增加,2修改
				formType: 1,
				open: false,
				columns: [{
						title: '序号',
						ellipsis: true,
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '银行',
						dataIndex: 'bankName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '核额业绩(万)',
						dataIndex: 'money',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '已提款金额(万)',
						dataIndex: 'drawing',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'operation',
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				data: [],
				total: null,
				record: {},
			}
		},
		filters: {},
		created() {},
		computed: {},
		watch: {},
		mounted() {},
		methods: {

			onClose() {
				this.open = false
			},
			// 取消按钮
			cancel() {
				this.open = false
				this.reset()
			},
			onShowSizeChange(current, pageSize) {
				this.form.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.form.pageNum = current
				this.form.pageSize = pageSize
				this.getList()
			},
			opencard(record) {
				this.record = record;
				this.form.userIds = record.id;
				this.open = true;
				this.formTitle = '提款'
				this.getList(record.rosterId)
			},
			getList(id) {
				auditlist({
					rosterId: id,
					type: 1
				}).then(res => {
					if (res.success) {
						console.log('核额记录', res);
						this.data = res.data;
						// this.total = res.data.total
					}
				})
			},
			// 表单重置
			reset() {
				this.formType = 1
				this.form = {
					id: null,
					userId: null,
					rosterId: null,
					createTime: null,
					remark: null,
				}
				this.$nextTick(() => {
					if (this.$refs.form) {
						this.$refs.form.resetFields()
					}
				})
			},
			/** 新增按钮操作 */
			handleAdd(row) {
				this.reset()
				this.formType = 1
				this.open = true
				this.formTitle = '提款'
			},
			/** 修改按钮操作 */
			handleUpdate(row, ids) {
				this.reset()
				this.formType = 2
				const id = row ? row.id : ids
				getCustomer({
					"id": id
				}).then(response => {
					this.form = response.data
					this.open = true
					this.formTitle = '修改'
				})
			},
			/** 提交按钮 */
			submitForm: function() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.submitLoading = true
						if (this.form.id !== undefined && this.form.id !== null) {
							updateCustomer(this.form).then(response => {
								this.$message.success(
									'修改成功',
									3
								)
								this.open = false
								this.$emit('ok')
							}).finally(() => {
								this.submitLoading = false
							})
						} else {
							addCustomer(this.form).then(response => {
								this.$message.success(
									'新增成功',
									3
								)
								this.open = false
								this.$emit('ok')
							}).finally(() => {
								this.submitLoading = false
							})
						}
					} else {
						return false
					}
				})
			}
		}
	}
</script>