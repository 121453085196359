import request from '@/utils/request'


// 查询销售报列表
export function listSales(query) {
	return request({
		url: '/report/report-sales/list',
		method: 'get',
		params: query
	})
}

// 查询销售报分页
export function pageSales(query) {
	return request({
		url: '/report/report-sales/page',
		method: 'get',
		params: query
	})
}

// 查询销售报详细
export function getSales(data) {
	return request({
		url: '/report/report-sales/detail',
		method: 'get',
		params: data
	})
}

// 新增销售报
export function addSales(data) {
	return request({
		url: '/report/report-sales/add',
		method: 'post',
		data: data
	})
}

// 修改销售报
export function updateSales(data) {
	return request({
		url: '/report/report-sales/edit',
		method: 'post',
		data: data
	})
}

// 删除销售报
export function delSales(data) {
	return request({
		url: '/report/report-sales/delete',
		method: 'post',
		data: data
	})
}

// 银行列表
export function bankdata(data) {
	return request({
		url: '/bankdata/bankdata/list',
		method: 'get',
		params: data
	})
}

// 区域列表
export function regionList(data) {
	return request({
		url: '/system/dept/getRegionList',
		method: 'get',
		params: data
	})
}


// 区域团队列表
export function areaTeam(data) {
	return request({
		url: '/system/user/deptTree',
		method: 'get',
		params: data
	})
}

export function getTeamList(data) {
	return request({
		url: '/system/dept/getTeamList',
		method: 'get',
		params: data
	})
}


// 批量查询部门下用户列表
export function ByDeptIds(data) {
	return request({
		url: '/system/user/getByDeptId',
		method: 'get',
		params: data
	})
}

// 台账提款报表
export function withdrawalReport(data) {
	return request({
		url: '/audit/audit-amount/withdrawalReport',
		method: 'get',
		params: data
	})
}

// 录音记录报表
export function reportForms(data) {
	return request({
		url: '/call/call-record/getReportForms',
		method: 'get',
		params: data
	})
}