<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="年度" prop="year">
								<a-date-picker mode="year" v-model="queryParam.year" :open="yearOpen" @openChange="openchange"
									@panelChange="panelChange" placeholder="选择年" allow-clear style="width: 100%;"></a-date-picker>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="所属区域" prop="deptId">
								<a-select v-model="queryParam.deptId" placeholder="请选择所属区域" allow-clear>
									<a-select-option :value="region.id" v-for="(region,index) in regionLists" :key="index">
										{{region.deptName}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 8 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<!-- 增加修改 -->
			<create-form ref="createForm" @ok="getList" />
			<!-- 数据展示 -->
			<div>
				<div class="export">
					<h4>团队核额数据</h4>
					<a-button type="primary" @click="excelExporrt('团队核额数据')" v-hasPermi="['report:sales:export']">导出</a-button>
				</div>
				<!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
				<a-table :loading="loading" id="columns" :size="tableSize" rowKey="id" :columns="columns" :data-source="data"
					:pagination="false" :bordered="tableBordered">
					<span slot="createTime" slot-scope="text, record">
						{{ parseTime(record.createTime) }}
					</span>
					<span slot="operation" slot-scope="text, record">
						<a-divider type="vertical" v-hasPermi="['report:forms:edit']" />
						<a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['report:forms:edit']">
							<a-icon type="edit" />修改
						</a>
						<a-divider type="vertical" v-hasPermi="['report:forms:remove']" />
						<a @click="handleDelete(record)" v-hasPermi="['report:forms:remove']">
							<a-icon type="delete" />删除
						</a>
					</span>
				</a-table>
				<!-- 分页 -->
			</div>

			<div style="margin-top: 30px;">
				<div class="export">
					<h4>团队坐席核额数据</h4>
					<a-button type="primary" @click="excelExporrt1('坐席核额数据')" v-hasPermi="['report:sales:export']">导出</a-button>
				</div>
				<!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
				<a-table :loading="loading" id="columns1" :size="tableSize" rowKey="id" :columns="columns1" :data-source="list"
					:pagination="false" :bordered="tableBordered">
					<span slot="createTime" slot-scope="text, record">
						{{ parseTime(record.createTime) }}
					</span>
					<span slot="operation" slot-scope="text, record">
						<a-divider type="vertical" v-hasPermi="['report:forms:edit']" />
						<a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['report:forms:edit']">
							<a-icon type="edit" />修改
						</a>
						<a-divider type="vertical" v-hasPermi="['report:forms:remove']" />
						<a @click="handleDelete(record)" v-hasPermi="['report:forms:remove']">
							<a-icon type="delete" />删除
						</a>
					</span>
				</a-table>
			</div>
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		pageForms,
		listForms,
		delForms,
		teamData,
		seatsDate,
		regionList
	} from '@/api/report/forms'
	import CreateForm from './modules/CreateForm'
	import {
		tableMixin
	} from '@/store/table-mixin'
  import FileSaver from 'file-saver'

  import XLSX from 'xlsx'

	export default {
		name: 'Forms',
		components: {
			CreateForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					deptId: undefined,
					year: null,
					pageNum: 1,
					pageSize: 10
				},
				columns: [{
						title: '团队',
						dataIndex: 'deptName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '1月',
						dataIndex: 'one',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '2月',
						dataIndex: 'two',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '3月',
						dataIndex: 'three',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '4月',
						dataIndex: 'four',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '5月',
						dataIndex: 'five',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '6月',
						dataIndex: 'six',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '7月',
						dataIndex: 'seven',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '8月',
						dataIndex: 'eight',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '9月',
						dataIndex: 'nine',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '10月',
						dataIndex: 'ten',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '11月',
						dataIndex: 'eleven',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '12月',
						dataIndex: 'twelve',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '年度',
						dataIndex: 'yearMoney',
						ellipsis: true,
						align: 'center'
					},
				],
				columns1: [{
						title: '团队',
						dataIndex: 'userName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '1月',
						dataIndex: 'one',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '2月',
						dataIndex: 'two',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '3月',
						dataIndex: 'three',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '4月',
						dataIndex: 'four',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '5月',
						dataIndex: 'five',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '6月',
						dataIndex: 'six',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '7月',
						dataIndex: 'seven',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '8月',
						dataIndex: 'eight',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '9月',
						dataIndex: 'nine',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '10月',
						dataIndex: 'ten',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '11月',
						dataIndex: 'eleven',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '12月',
						dataIndex: 'twelve',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '年度',
						dataIndex: 'yearMoney',
						ellipsis: true,
						align: 'center'
					},
				],
				data: [],
				yearOpen: false,
				regionLists: [],
			}
		},
		filters: {},
		created() {
			// this.getList()
			this.getTeamData()
			this.getseatsDate()
			this.getRegionList();
		},
		computed: {},
		watch: {},
		methods: {
      // 导出
      excelExporrt(name) {
        let wb = XLSX.utils.table_to_book(document.querySelector('#columns'));
        let weout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type:'array'});
        try {
          FileSaver.saveAs(new Blob([weout], {type:'application/octet-stream'}), name+'.xlsx')
        } catch (e) {
          if (typeof console != 'undefined') {
            console.log(e, weout)
          }
        }
        return weout
      },
      excelExporrt1(name) {
        let wb = XLSX.utils.table_to_book(document.querySelector('#columns1'));
        let weout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type:'array'});
        try {
          FileSaver.saveAs(new Blob([weout], {type:'application/octet-stream'}), name+'.xlsx')
        } catch (e) {
          if (typeof console != 'undefined') {
            console.log(e, weout)
          }
        }
        return weout
      },
			// 区域列表
			getRegionList() {
				regionList().then(res => {
					if (res.success) {
						console.log('区域列表', res);
						this.regionLists = res.data;
					}
				})
			},
			// 团队核额数据
			getTeamData() {
				teamData(this.queryParam).then(res => {
					console.log('团队核额数据', res);
					this.getMothFilter(res.data, 'data')
				})
			},
			// 团队坐席核额数据
			getseatsDate() {
				seatsDate(this.queryParam).then(res => {
					console.log('团队坐席核额数据', res);
					this.getMothFilter(res.data, 'list')
				})
			},
			// 月份过滤
			getMothFilter(arr, type) {
				// let week = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
				let weekText = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven',
					'twelve'
				]
				arr.map(item => {
					item.reportList.map(item1 => {
						item[weekText[item1.month - 1]] = item1.allMoney || 0;
					})
					this[type].push(item)
				})
				console.log(this.data);
			},
			openchange(e) {
				console.log(e);
				this.yearOpen = e;
			},
			panelChange(e) {
				console.log(e);
				this.queryParam.year = e.format('YYYY');
				console.log(this.queryParam.year)
				this.yearOpen = false;
			},
			/** 查询月度报列表 */
			getList() {
				this.loading = true
				pageForms(this.queryParam).then(response => {
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},

			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.list = [];
				this.data = [];
				this.getTeamData()
				this.getseatsDate()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					userId: undefined,
					year: undefined,
					month: undefined,
					money: undefined,
					pageNum: 1,
					pageSize: 10
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return delForms(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
						that.ids = []
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('report/report-forms/export', {
							...that.queryParam
						}, `月度报_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			}
		}
	}
</script>

<style scoped>
	.export {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}
</style>