import request from '@/utils/request'


// 查询跟进记录列表
export function listUp(query) {
  return request({
    url: '/follow/follow-up/list',
    method: 'get',
    params: query
  })
}

// 查询跟进记录分页
export function pageUp(query) {
  return request({
    url: '/follow/follow-up/page',
    method: 'get',
    params: query
  })
}

// 查询跟进记录详细
export function getUp(data) {
  return request({
    url: '/follow/follow-up/detail',
    method: 'get',
    params: data
  })
}

// 新增跟进记录
export function addUp(data) {
  return request({
    url: '/follow/follow-up/add',
    method: 'post',
    data: data
  })
}

// 修改跟进记录
export function updateUp(data) {
  return request({
    url: '/follow/follow-up/edit',
    method: 'post',
    data: data
  })
}

// 删除跟进记录
export function delUp(data) {
  return request({
    url: '/follow/follow-up/delete',
    method: 'post',
    data: data
  })
}
