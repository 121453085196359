import request from '@/utils/request'


// 查询银行数据列表
export function listBankdata(query) {
  return request({
    url: '/bankdata/bankdata/list',
    method: 'get',
    params: query
  })
}

// 查询银行数据分页
export function pageBankdata(query) {
  return request({
    url: '/bankdata/bankdata/page',
    method: 'get',
    params: query
  })
}

// 查询银行数据详细
export function getBankdata(data) {
  return request({
    url: '/bankdata/bankdata/detail',
    method: 'get',
    params: data
  })
}

// 新增银行数据
export function addBankdata(data) {
  return request({
    url: '/bankdata/bankdata/add',
    method: 'post',
    data: data
  })
}

// 修改银行数据
export function updateBankdata(data) {
  return request({
    url: '/bankdata/bankdata/edit',
    method: 'post',
    data: data
  })
}

// 删除银行数据
export function delBankdata(data) {
  return request({
    url: '/bankdata/bankdata/delete',
    method: 'post',
    data: data
  })
}
