import request from '@/utils/request'


// 团队月度明细
export function TeamDataDetails(query) {
	return request({
		url: '/data/data-screen/getTeamDataDetails',
		method: 'get',
		params: query
	})
}

// 当月总业绩-当日总业绩-统计总业绩
export function AllMoney(query) {
	return request({
		url: '/data/data-screen/getAllMoney',
		method: 'get',
		params: query
	})
}


// 本年度四季业绩名称-年度数据明细-月度数据明细-坐席业绩排名
export function StaffRanking(query) {
	return request({
		url: '/data/data-screen/getStaffRanking',
		method: 'get',
		params: query
	})
}


// 数据明细(意向-转介绍)
export function DataDetails(query) {
	return request({
		url: '/data/data-screen/getDataDetails',
		method: 'get',
		params: query
	})
}

// 当月总锁期体量-获取总锁期(提款)
export function AllLockPeriod(query) {
	return request({
		url: '/data/data-screen/getAllLockPeriod',
		method: 'get',
		params: query
	})
}

// 数据明细(核额数量)
export function AuditAmountDataDetails(query) {
	return request({
		url: '/data/data-screen/getAuditAmountDataDetails',
		method: 'get',
		params: query
	})
}

// 统计各年度数据
export function YearData(query) {
	return request({
		url: '/data/data-screen/getYearData',
		method: 'get',
		params: query
	})
}

// 统计各年度数据
export function TeamRanking(query) {
	return request({
		url: '/data/data-screen/getTeamRanking',
		method: 'get',
		params: query
	})
}

// 统计各年度数据
export function LockPeriodRanking(query) {
	return request({
		url: '/data/data-screen/getLockPeriodRanking',
		method: 'get',
		params: query
	})
}

// 新增新名单库
export function addRoster(data) {
	return request({
		url: '/roster/roster/add',
		method: 'post',
		data: data
	})
}